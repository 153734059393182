import React from 'react'
import BaseQuestion from './baseQuestion'
import PropTypes from 'prop-types'

function NumberQ ({ disabled, saveResponse, question, fullResponse, reponse, showComments, commentUser, comment, questionHelpText }) {
  const [questionDetails, setQuestionDetails] = React.useState()
  const [response, setResponse] = React.useState()

  React.useEffect(() => {
    if (reponse) {
      setResponse(reponse)
    }
  }, [reponse])

  React.useEffect(() => {
    if (question?.uuid) {
      setQuestionDetails(question)
    }
  }, [question])
  const saveResponseBlur = (e) => {
    const responseDetails = { ...fullResponse, value: e.target.value, question: question.uuid, state: e.target.value && 'draft' }
    saveResponse(responseDetails)
  }
  const sendData = (state) => {
    const response = {
      ...fullResponse,
      value: state === 'delete' ? '' : reponse,
      question: question.uuid,
      state
    }
    saveResponse(response)
  }

  return (
    <BaseQuestion disabled={disabled} reponse={fullResponse} showComments={showComments} questionHelpText={questionHelpText}
      commentUser={commentUser} comment={comment} question={question} sendData={(state) => sendData(state)}>
      <div className="py-1">
        <h5 className="text-md">{question.text}</h5>
      </div>
      <input className="ml-6 mt-2 form-number appearance-none block w-200 px-2 py-0.5 text-base font-normal text-gray-700
    bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
    focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1" value={response}
        onChange ={(e) => setResponse(e.target.value)}
        onBlur={(e) => saveResponseBlur(e) }
        disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault()
          }
        }}
        max="20" min="0" type="text" />
    </BaseQuestion>
  )
}
NumberQ.propTypes = {
  disabled: PropTypes.bool.isRequired,
  responses: PropTypes.array,
  commentUser: PropTypes.string,
  saveResponse: PropTypes.func,
  reponse: PropTypes.string,
  question: PropTypes.object.isRequired,
  comment: PropTypes.object,
  showComments: PropTypes.bool,
  fullResponse: PropTypes.object

}
export default NumberQ
