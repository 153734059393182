import React from 'react'
import DualListBoxComponent from './dualListBox'
import { selectAllSurveys, useGetAllSurveysQuery } from '../../surveys/surveysSlice'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

function AddSurveys ({ onChangeEntity, entity, onChangeStep }) {
  const { t } = useTranslation()
  const { data } = useGetAllSurveysQuery()
  const [surveys, setSurveys] = React.useState()
  const surveysTrees = useSelector(selectAllSurveys)
  React.useEffect(() => {
    if (surveysTrees) {
      setSurveys(surveysTrees)
    }
  }, [surveysTrees])

  return (
    <section>
      <div className="flex flex-col  flex-wrap gap-2 mt-4">
        <div>
          <DualListBoxComponent surveys={surveys} entity={entity} onChangeEntity={onChangeEntity}/>
        </div>

        <div className="w-4/5 mt-4">
          <button className="bg-teal-600 mx-2 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1">
            <h5 className="text-white  w-16" onClick={(e) => onChangeStep(5)}>{t('common.buttons.next')}</h5>
          </button>
          <button className="bg-gray-300 mx-2 px-2 py-0.5 rounded-md mb-1 text-md font-medium float-right p-1">
          <h5 className="text-[#444444]  w-max-fit" onClick={() => onChangeStep(3)}>{t('common.buttons.post')}</h5>
        </button>
        </div>
      </div>
    </section>
  )
}

export default AddSurveys
