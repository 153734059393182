// GroupTypeTable2.js
import React from 'react'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { GenerateBodyTemplate } from '../../../../appComponents/datatable/utils/helperMethods'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteResponseGrp from './deleteResponseGrp'
import { FilterMatchMode } from 'primereact/api'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'
import { useNavigate } from 'react-router-dom'

const GroupTypeTable = ({ data }) => {
  const navigate = useNavigate()
  const ActionTemplate = (e) => {
    return (
      <div className="flex gap-2 justify-content-center">
        <EditIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/admin/survey/questions/responses_model/${e.id}/edit`)}/>
        <DeleteResponseGrp idResponseGrp={e.id}>
          <DeleteIcon sx={{ color: 'red', cursor: 'pointer', fontSize: 25 }}/>
        </DeleteResponseGrp>
      </div>
    )
  }
  const { t } = useTranslation()
  const representativeBodyTemplate = GenerateBodyTemplate('/admin/survey/questions/responses_model', 'id', 'title')
  const [responses, setResponses] = React.useState()
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')

  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    title: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
  })

  React.useEffect(() => {
    if (data) {
      let responsesList = []
      data.map((type) => {
        type.options.map((op) => {
          responsesList = [...responsesList, { id: type.id, title: type.title, reponse: { id: op.id, text: op.text } }]
        })
      })
      setResponses(responsesList)
    }
  }, [data])

  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: responses,
    stateKey: 'dt-state-import-response-list',
    onFilter: (e) => setFilters(e.filters),
    showGridlines: true,
    rowGroupMode: 'rowspan',
    groupRowsBy: 'title',
    header,
    globalFilterFields: ['title', 'reponse.text'],
    filters,
    dataKey: 'title',
    emptyMessage: t('admin.survey.questions.model_list_notFound'),
    columns: [
      { field: 'title', sortable: true, header: t('common.table.type'), filter: true, filterPlaceholder: 'Rechercher par titre', style: { width: '35%' }, body: representativeBodyTemplate },
      { field: 'reponse.text', sortable: true, header: t('common.table.reponse'), style: { width: '45%' } },
      { body: ActionTemplate, header: t('common.table.action'), style: { width: '20%' } }
    ]
  }

  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div className="mt-12">
      {DataTableComponent}
    </div>
  )
}

export default GroupTypeTable
