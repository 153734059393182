import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import InputField from './inputField'
import { useGetChoicesListQuery } from '../../../../features/questions/questionSlice'
import UnitSelect from '../../../../features/questions/utils/unitSelect'
import Input from '../../../../features/questions/utils/input'
import PropTypes from 'prop-types'

const Formulas = ({ formulas, onChange, onBlur }) => {
  const { t } = useTranslation()
  const { data: choicesList } = useGetChoicesListQuery()
  const handleAddFormula = (eq) => {
    if ((formulas[eq].value && formulas[eq].output_unit) ||
      (formulas[eq].unit === 'Fraction' && formulas[eq].denominator) && (formulas[eq].min && formulas[eq].min)) {
      const FormulaUuid = uuidv4()
      const newFormula = {
        text: 'formula-' + FormulaUuid.split('-')[0],
        value: '',
        id: FormulaUuid,
        type: 'python'
      }
      const parameters = { ...formulas, [FormulaUuid]: newFormula }
      onBlur(parameters)
    } else {
      alert(t('transformQuestion.add_formula_alert'))
    }
  }
  const handleDeleteFormula = (eq) => {
    const formulasCopy = { ...formulas }
    delete formulasCopy[eq]
    onBlur(formulasCopy)
  }
  return (
    <>
      {formulas && Object.keys(formulas).map((eq, index) =>
        <div key={eq}>
          <input className="block w-200 px-2 py-1 text-base font-normal text-gray-800
                bg-no-repeat rounded bg-transparent focus:outline-none" value={formulas[eq].text || ''} max="20" min="0" type="text"
            onChange={(e) => onChange({ ...formulas, [eq]: { ...formulas[eq], text: e.target.value } })}
            onBlur={(e) => onBlur({ ...formulas, [eq]: { ...formulas[eq], text: e.target.value } })} />
          <div className='flex flex-col gap-1'>
            <div className='flex gap-2'>
              <div className='flex w-3/5 gap-2'>
                <InputField value={formulas[eq]?.value} onChange={(val) => onChange({ ...formulas, [eq]: { ...formulas[eq], type: 'global', value: val } })}
                  disabled={false} type='text' onBlur={(val) => onBlur({ ...formulas, [eq]: { ...formulas[eq], type: 'global', value: val } })} />
              </div>
              <div className='bg-red-700 w-15 h-15' style={{ cursor: 'pointer' }} onClick={() => handleDeleteFormula(eq)}>
                <DeleteForeverIcon style={{ color: 'white' }} />
              </div>
              {index === (Object.keys(formulas).length - 1) &&
                <div className='bg-teal-700' style={{ cursor: 'pointer' }} onClick={() => handleAddFormula(eq)}>
                  <AddIcon style={{ color: 'white' }} />
                </div>
              }
            </div>
            <div className='flex flex-col'>
              <div className='flex flex-col w-3/4 gap-3'>
                  <div className='flex'>
                  <UnitSelect text="admin.survey.transformation_model.create.fields.input_unit" value={formulas[eq]?.input_unit ? formulas[eq].input_unit : null} disabled={false}
                    onChange={(val) => onChange({ ...formulas, [eq]: { ...formulas[eq], input_unit: val, input_denominator: val !== 'Fraction' && null } })}
                    onBlur={(val) => onBlur({ ...formulas, [eq]: { ...formulas[eq], input_unit: val, input_denominator: val !== 'Fraction' && null } })}
                    data={choicesList || []} />
                    {formulas[eq]?.input_unit === 'Fraction' &&
                  <Input text="admin.survey.transformation_model.create.fields.additional_denominator" type="Number"
                    value={formulas[eq]?.input_denominator ? formulas[eq].input_denominator : null}
                    onChange={(val) => onChange({ ...formulas, [eq]: { ...formulas[eq], input_denominator: val } })}
                    onBlur={(val) => onBlur({ ...formulas, [eq]: { ...formulas[eq], input_denominator: val } })}
                  />
                }
                </div>
                <div className='flex'>
                <UnitSelect text="admin.survey.transformation_model.create.fields.output_unit" value={formulas[eq]?.output_unit ? formulas[eq].output_unit : null} disabled={false}
                  onChange={(val) => onChange({ ...formulas, [eq]: { ...formulas[eq], output_unit: val, output_denominator: val !== 'Fraction' && null } })}
                  onBlur={(val) => onBlur({
                    ...formulas,
                    [eq]: {
                      ...formulas[eq],
                      output_unit: val,
                      input_denominator: val !== 'Fraction' && null
                    }
                  })} data={choicesList || []} />
                  {formulas[eq]?.output_unit === 'Fraction' &&
                  <Input text="admin.survey.transformation_model.create.fields.additional_denominator" type="Number"
                    value={formulas[eq]?.output_denominator ? formulas[eq].output_denominator : null}
                    onChange={(val) => onChange({ ...formulas, [eq]: { ...formulas[eq], output_denominator: val } })}
                    onBlur={(val) => onBlur({ ...formulas, [eq]: { ...formulas[eq], output_denominator: val } })}
                  />
                }
                  </div>
              </div>

              <>

                <div className='flex pt-2 gap-2'>
                  <Input text="admin.survey.transformation_model.create.fields.number_min" type="Number"
                    value={formulas[eq]?.min ? formulas[eq].min : null}
                    onChange={(val) => onChange({ ...formulas, [eq]: { ...formulas[eq], min: val } })}
                    onBlur={(val) => onBlur({ ...formulas, [eq]: { ...formulas[eq], min: val } })} />
                  <Input text="admin.survey.transformation_model.create.fields.number_max" type="Number"
                    value={formulas[eq]?.max ? formulas[eq].max : null}
                    onChange={(val) => onChange({ ...formulas, [eq]: { ...formulas[eq], max: val } })}
                    onBlur={(val) => onBlur({ ...formulas, [eq]: { ...formulas[eq], max: val } })}
                  />
                </div>
              </>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
Formulas.propTypes = {
  formulas: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}
export default Formulas