import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const SurveyNodesAdapter = createEntityAdapter({
})
const initialState = SurveyNodesAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const exportedSurveyApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'ExportedSurveys',
  endpoints: builder => ({
    getExportedSurveys: builder.query({
      query: (id) => '/survey/surveyTree/exports',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['ExportedSurveys']
    })
  })
})

export const {
  useGetExportedSurveysQuery
} = exportedSurveyApiSlice

export const exportedSurveysResult = exportedSurveyApiSlice.endpoints.getExportedSurveys.select()

export const selectSurveyNodesData = createSelector(
  exportedSurveysResult,
  exportedResult => exportedResult.data
)

export const {
  selectAll: selectAllExportedSurveys,
  selectById: selectExportedById
} = SurveyNodesAdapter.getSelectors(state => selectSurveyNodesData(state) ?? initialState)
