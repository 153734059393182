import React from 'react'
import EntityAdd from '../../../features/entities/entityAdd'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, entityOptions } from '../DynamicNavbarOptions'

function CreateEntity () {
  return (
<div className="w-full">
      <DynamicNavBar option="navbar.generalOptions.organisations" options={generalOptions}/>
      <DynamicNavBar option="navbar.common.create" options={entityOptions}/>
      <EntityAdd/>
</div>
  )
}

export default CreateEntity
