import axios from 'axios'
import { baseURL } from '../../../../var.conf.js'

export const HandleSendFile = async (selectedFile ,  setLoadingImportFile) => {
  const formData = new FormData()
  formData.append('file', selectedFile)
  try {
    setLoadingImportFile(true)
    const response = await axios({
      method: 'post',
      url: baseURL + '/question/questionText/import',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    const data = response.data
    return data
  } catch (error) {
    return error
  } finally {
    setLoadingImportFile(false)
  }
}

export const GetExcelFile = async () => {
  try {
    const response = await axios({
      url: baseURL + '/question/export',
      method: 'GET',
      responseType: 'blob' // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'file.xlsx')
      document.body.appendChild(link)
      link.click()
    })
    return response.json()
  } catch (error) {
  }
}

export const ConfirmImportFileMethod = async (formData) => {
  try {
    const response = await axios({
      method: 'post',
      url: baseURL + '/question/confirm_import',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if ([201, 200].includes(response.status)) {
      return { status: 'success', data: response.data }
    } else {
      return { status: 'error', data: response.data }
    }
  // Return the response data
  } catch (error) {
    return { status: 'error', data: error.response.data }
  }
}
