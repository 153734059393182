import React, { useState } from 'react'
import DynamicNavBar from '../../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions, transformModel } from '../../DynamicNavbarOptions'
import { useTranslation } from 'react-i18next'
import IndexAlert from '../../../../components/alerts/indexAlert'
import Input from '../utils/input'
import ClipLoader from 'react-spinners/ClipLoader'
import TextArea from '../utils/textArea'
import { useGetSurveysQuery } from '../../../../features/surveysCreated/surveysSlice'
import { useNavigate } from 'react-router-dom'
import SelectInput from '../utils/selectInput'
import { useCreateEvaluationMethodMutation } from '../../../../features/evaluationMethod/evaluationMethodSlice'

function StartCreateEvaluationModel () {
  const { data: surveys } = useGetSurveysQuery()
  const [evalModel, setEVALModel] = useState({
    name: '',
    method: {},
    description: '',
    survey: ''
  })
  const [createEvaluationMethod, { isSuccess, isLoading, isError, error }] = useCreateEvaluationMethodMutation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleCreateEvaluationMethod = async () => {
    const transformMethod = await createEvaluationMethod(evalModel)
    setEVALModel({ ...transformMethod.data, disabled: false })
  }
  
  return (
    <div className="w-full">
      <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions} />
      <DynamicNavBar option="navbar.surveyOptions.tranformation_model" options={surveyOptions} />
      <DynamicNavBar option="navbar.transformModel.evaluation_method_create" options={transformModel} />

      <div className="mx-auto mt-2 w-1/3" >
        <h5 className="text-center text-2xl font-medium text-[#444444] mb-3 w-full ">{t('admin.survey.evaluation_model.title.create_evaluation_method')}</h5>
        <div className="flex flex-col gap-1 w-full bg-gray-50 max-h-fit">
          <div className='grid justify-items-center'>
            <div className='max-w-fit '>
              {isSuccess && <IndexAlert type="success" text={t('admin.survey.evaluation_model.output.created_success')} />}
              {isError && <IndexAlert type="danger" text={JSON.stringify(error.data.error)} />}
            </div>
          </div>
          <div className="w-2/3 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
            <Input text='admin.survey.evaluation_model.create.fields.titre' placeholder='admin.survey.evaluation_model.create.fields.titre' disabled={evalModel?.disabled}
              onChange={(val) => setEVALModel({ ...evalModel, name: val })} value={evalModel?.name ? evalModel.name : ''} />
          </div>
          <div className='w-2/3 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm '>
            <SelectInput text='admin.survey.evaluation_model.create.fields.choose_survey' disabled={evalModel?.disabled} value={evalModel?.survey} data={surveys || []} onChange={(val) => setEVALModel({ ...evalModel, survey: val })} />
          </div>
          <div className="w-full px- px-3 mb-6 md:mb-0 relative rounded-sm">
            <TextArea text='admin.survey.evaluation_model.create.fields.description' disabled={evalModel?.disabled} placeholder='admin.survey.evaluation_model.create.fields.description'
              onChange={(val) => setEVALModel({ ...evalModel, description: val })} rows={6}
              value={evalModel?.description ? evalModel.description : ''} />
          </div>

        </div>
        {isSuccess
          ? evalModel?.id &&
          <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
            px-2 py-0.5 mt-4 float-right w-20 h-10" style={{ cursor: 'pointer' }} onClick={() => navigate(`/admin/survey/evaluation_method/create/${evalModel.id}`)}>{t('admin.survey.buttons.next')}
          </div>
          : isLoading
            ? <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
           py-0.5 mt-4 float-right w-20 h-10" style={{ cursor: 'pointer' }} >
              <ClipLoader color="#F2F2F2" size={22} /></div>
            : <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
            px-2 py-0.5 mt-4 float-right w-20 h-10" style={{ cursor: 'pointer' }} onClick={() => handleCreateEvaluationMethod()}>{t('admin.survey.buttons.create')}
            </div>}
      </div>

    </div>
  )
}

export default StartCreateEvaluationModel
