import React from 'react'
import Header from './components/header'
import { useNavigate, useParams } from 'react-router-dom'
import { useChangePasswordMutation } from '../../features/auth/authApiSlice'
import IndexAlert from '../../components/alerts/indexAlert'
import { Spinner } from '../../components/loadData/spinner'
import { useTranslation } from 'react-i18next'

function ConfirmNewPassword () {
  const { t } = useTranslation()

  const [changePassword, { isSuccess, isError, isLoading, error }] = useChangePasswordMutation()
  const { uid, token } = useParams()
  const navigate = useNavigate()
  const [password, setPassword] = React.useState({ new_password: '', re_new_password: '' })
  const handelChangePassword = async (e) => {
    e.preventDefault()
    const data = { ...password, uid, token }
    if (data.uid, data.token, data.new_password, data.re_new_password) {
      await changePassword(data)
    } else {
      alert(t('remplirForm'))
    }
  }

  return (
    <section className="mt-100">
    <Header
        heading="Veuillez changer votre mot de passe"
        paragraph="Vous n'avez pas encore un compte ?"
        linkName="Nous contacter"
        linkUrl="/signup" />
    <div className="flex justify-center mt-3 ">
    <div className="space-y-4 w-400">
        <div className="flex justify-center">
            {isLoading && <Spinner/>}
            {isSuccess && <div className="flex flex-col"><IndexAlert type="success" text={t('login.reset_password.password_change')}/>
                <bottom className="mt-1 p-1 px-2 text-white bg-[#086E7C] flex mx-auto" onClick={() => navigate('/auth/login')} style={{ cursor: 'pointer' }}>{t('login.login_button')}</bottom>

            </div>}
            {isError && <IndexAlert type="danger" text={JSON.stringify(error.data[Object.keys(error.data)[0]])}/>}
        </div>
        {isSuccess
          ? undefined
          : <>
        <div className="-space-y-px ">
            <div className="my-2 flex flex-col">
                <label htmlFor="new_password" className="text-[#444444] text-md font-semibold ">
                {t('login.newPassword')}
                </label>
                <input
                    onChange={(e) => setPassword({ ...password, new_password: e.target.value })}
                    value={password.password}
                    required={true}
                    type="password"
                    className="border-2 border-[#f7f7f7] rounded-sm pl-2 text-md h-8"
                    placeholder={t('login.newPassword')}/>
            </div>
            <div className="my-2 flex flex-col">
                <label htmlFor="Confirm" className="text-[#444444] text-md font-semibold ">
                {t('login.confirm')}
                </label>
                <input
                    onChange={(e) => setPassword({ ...password, re_new_password: e.target.value })}
                    value={password.confirmePassword}
                    required={true}
                    type="password"
                    className="border-2 border-[#f7f7f7] rounded-sm pl-2 text-md h-8"
                    placeholder= {t('login.confirm')}
                />
            </div>

        </div>
        <div className="flex flex-col mt-4">
            <bottom className="p-1 px-2 text-white bg-[#086E7C] flex mx-auto" onClick={(e) => handelChangePassword(e)} style={{ cursor: 'pointer' }}>{t('login.changePassword')}</bottom>
        </div>
        </> }
    </div>
    </div>
    <div className="text-sm mt-4 flex justify-center">
        <bottom className="font-semibold text-md text-[#086E7C] hover:text-[#086E7C]" style={{ cursor: 'pointer' }} onClick={() => navigate('/auth/login')}>
            <spam className="text-[#444]"> {t('login.has_account')}</spam> {t('login.login_button')}
        </bottom>
    </div>

</section>
  )
}

export default ConfirmNewPassword
