import React from 'react'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions } from '../DynamicNavbarOptions'
import ExportedSurveyHistoryList from './export/exportedSurveyHistoryList'
import { useGetExportedSurveysQuery } from '../../../features/exportedSurveys/exportedSurveySlice'
import { useTranslation } from 'react-i18next'

function exportedHistory () {
  const { data: exportedSurveys } = useGetExportedSurveysQuery()
  const { t } = useTranslation()
  return (
        <div className="w-full pb-16">
          <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
          <DynamicNavBar option="navbar.surveyOptions.exported_surveys" options={surveyOptions}/>
            <div className="w-full flex flex-col items-center gap-8  static mt-4" >
              <div className=" md:w-2/3 lg:w-4/6 sm:w-2/3 bg-wite md:mx-8 pb-8">
                <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('admin.survey.exported_surveys.exported_surveys')}</h5>
                  <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4">
                    <div>
                      <ExportedSurveyHistoryList exportedSurveys={exportedSurveys || []}/>
                    </div>
                  </div>
                </div>
            </div>
        </div>
  )
}

export default exportedHistory
