import React from 'react'
import EntityListTable from './table/entityListTable'
import { useGetEntitiesQuery } from '../../../features/entitiesClient/entitiesClientSlice'
import { useTranslation } from 'react-i18next'

function EntityListAdmin () {
  const  {t} =  useTranslation()
  const { data: entities } = useGetEntitiesQuery()
  return (
    <div className="w-full pb-16">
      <div className="w-full flex flex-col items-center gap-8  static mt-6" >
        <div className="md:w-2/3 lg:w-2/3  bg-wite md:mx-8 pb-8">
          <h5 className="text-2xl font-medium text-[#444444] w-min-fit">{t('consultant.organisation.organisation_list')}</h5>
            <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4">
              <div>
                  <EntityListTable entities={entities?.entities ? Object.values(entities?.entities) : []}/>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default EntityListAdmin
