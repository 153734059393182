import React from 'react'
import './DataTableDemo.css'
import { useTranslation } from 'react-i18next'
import { FilterMatchMode } from 'primereact/api'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'

const ImportSurvey = ({ data }) => {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: data,
    stateKey: 'dt-state-import-survey-list',
    onFilter: (e) => setFilters(e.filters),
    header,
    globalFilterFields: ['UUID', 'NAME'],
    filters,
    style: { fontSize: '13px' },
    dataKey: 'type',
    emptyMessage: t('admin.survey.import_export.parametres_list_notFound'),
    columns: [
      { field: 'UUID', sortable: true, header: t('admin.survey.import_export.UUID') },
      { field: 'NAME', sortable: true, header: t('admin.survey.import_export.NAME') }
    ]
  }

  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div className="datatable-rowgroup-demo max-h-500 overflow-auto border-1 border-gray-200 mb-2">
      {DataTableComponent}
    </div>
  )
}

export default ImportSurvey
