import React from 'react'
import Radio from '@mui/material/Radio'
import BaseQuestion from './baseQuestion'

function RadioGroup (props) {
  const question = { questions: props.question.option }
  const [selectedValue, setSelectedValue] = React.useState()
  const handleChange = (e) => {
    setSelectedValue(e.target.value)
  }

  return (
  <BaseQuestion>
    <div>
    <h5 className="text-xl">{props.text}</h5>
          {question.questions.map((question) =>
        <div className="flex justify-between" key={question.id}>
            <h5 className="pt-1">{question.text}</h5>
            <Radio checked={selectedValue === question.id} onChange={(e) => handleChange(e)}
           value={question.text} name="radio-buttons" />
        </div>)}
    </div>
  </BaseQuestion>
  )
}

export default RadioGroup
