import React from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import Evaluations from './evaluations'
import NavBarHub from '../../../components/Bars/navBarHub'
import CreateEvaluation from './createEvaluation'
import EvaluationNotes from './evaluationNotes'
import EvaluationNodesResult from './evaluationNodesResult'

function IndexEvaluation () {
  return (
    <div>
        <NavBarHub option={'evaluation'}/>
        <div className="flex flex justify-center static">
          <div className="w-full">
            <Outlet/>
            <Routes>
                <Route path="" element={<Evaluations/>}></Route>
                <Route path="create" element={<CreateEvaluation/>}></Route>
                <Route path="note_calculation/:evaluationId" element={<EvaluationNotes/>}></Route>
                <Route path=":evaluationId/*" element={<EvaluationNodesResult/>}></Route>
            </Routes>
          </div>
        </div>
    </div>
  )
}

export default IndexEvaluation
