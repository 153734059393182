import React from 'react'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteEntity from './components/deleteEntity'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useGetActivitiesQuery, selectAllActivities } from '../../features/activitiesSector/activitiesSlice'
import { renderHeader } from '../../appComponents/datatable/utils/filter'
import { FilterMatchMode } from 'primereact/api'
import { template2 } from '../../appComponents/datatable/utils/paginators'

import { createDataTable } from '../../appComponents/datatable/data-table-factory'

function EntitiesList ({ entityList }) {
  const { t } = useTranslation()

  // Retreive activity sectors to display
  const activivitySectorsSelector = useSelector(selectAllActivities)
  const activivitySectors = {}
  const { as } = useGetActivitiesQuery()
  activivitySectorsSelector.map((sector) => activivitySectors[sector.id] = sector)
  const [filters, setFilters] = React.useState({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const navigate = useNavigate()
  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        <RemoveRedEyeIcon sx={{ color: '#1b674c', cursor: 'pointer' }} onClick={() => navigate(`/admin/entity/details/${e.id}`)}/>
        <EditIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/admin/entity/edit/${e.id}`)}/>
        <DeleteEntity idEntity={e.id}>
          <DeleteIcon sx={{ color: 'red', cursor: 'pointer', fontSize: 25 }}/>
        </DeleteEntity>
      </div>
    )
  }

  // Building the activity sector name to display
  const activitySectorTemplate = (e) => {
    return (
      <div>{activivitySectors[e.activity_sector] ? activivitySectors[e.activity_sector].reference + ' - ' + activivitySectors[e.activity_sector].name : ''}</div>
    )
  }

  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    stateKey: 'dt-state-entities-list',
    value: entityList,
    paginatorTemplate: template2,
    dataKey: 'id',
    filters,
    onFilter: (e) => setFilters(e.filters),
    header,
    globalFilterFields: ['name'],
    emptyMessage: t('admin.organisation.list.entity_list_notfound'),
    columns: [
      { field: 'name', sortable: true, header: t('admin.organisation.list.entity_name') },
      { field: 'activity_sector', sortable: true, body: activitySectorTemplate, header: t('admin.organisation.list.activity_sector') },
      { field: 'created_at', sortable: true, header: t('common.table.creation_date') },
      { body: actionTemplate, header: t('common.table.action') }]
  }

  const DataTableComponent = createDataTable(tableConfig)
  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300 ">
        {DataTableComponent}
        </div>
    </div>

  )
}
export default EntitiesList
