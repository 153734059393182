import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const responsesAdapter = createEntityAdapter({
  selectId: (entity) => entity.id
})
const initialState = responsesAdapter.getInitialState({
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const responsesApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'responsesSlice',
  tagTypes: ['Response'],
  endpoints: builder => ({
    getResponses: builder.query({
      query: (data) => `/response/${data.campaign}/${data.user}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Response']
    }),
    getResponsesStatistics: builder.query({
      query: (data) => `/response/statistics/${data.campaign}/${data.user}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Response']
    }),
    validateResponses: builder.mutation({
      query: (data) => ({
        url: `/response/validate/${data.campaign}/${data.user}/`,
        method: 'PATCH'
      }),
      invalidatesTags: ['Response']
    }),
    submitResponses: builder.mutation({
      query: (data) => ({
        url: `/response/validate/${data.campaign}/${data.user}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['Response']
    }),
    getSingleResponse: builder.query({
      query: (data) => `/response/${data.campaign}/${data.user}/${data.question}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Response']
    }),
    createResponse: builder.mutation({
      query: (data) => ({
        url: '/response/create/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Response']
    })
  })
})

export const {
  useGetResponsesQuery,
  useGetResponsesStatisticsQuery,
  useValidateResponsesMutation,
  useSubmitResponsesMutation,
  useCreateResponseMutation,
  useGetSingleResponseQuery
} = responsesApiSlice

export const selectResponseResult = responsesApiSlice.endpoints.getResponses.select()

export const selectResponsesData = createSelector(
  selectResponseResult,
  entityData => entityData?.data ?? {}
)

export const {
  selectAll: selectResponses,
  selectById: selectSingleResponse
  // Pass in a selector that returns the posts slice of state
} = responsesAdapter.getSelectors(state => selectResponsesData(state) ?? initialState)
