
export const generalOptions = [
  { text: 'navbar.generalOptions.organisations', url: '/admin/entity', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.parametersOptions.settings', url: '/admin/settings/activity_sector', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.generalOptions.users', url: '/admin/users', userTypes: ['SUPERADMIN'] },
  { text: 'navbar.generalOptions.surveys', url: '/admin/survey/start_create', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.generalOptions.user_view', url: '/hub/entity/list/admin', userTypes: ['SUPERADMIN', 'ADMIN', 'CLIENT'] }
]

export const entityOptions = [
  { text: 'navbar.entityOptions.organisation_list', url: '/admin/entity', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.common.create', url: '/admin/entity/create', userTypes: ['SUPERADMIN', 'ADMIN'] }]
export const Consultantption = [
  { text: 'navbar.entityOptions.organisation_list', url: '/hub/entity/list/admin', userTypes: ['SUPERADMIN', 'ADMIN', 'CLIENT'] },
  { text: 'navbar.common.create', url: '/admin/entity/create' }]

export const surveyOptions = [
  { text: 'navbar.surveyOptions.suveys_list', url: '/admin/survey', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.surveyOptions.tranformation_model', url: '/admin/survey/transformation_model', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.surveyOptions.create_survey', url: '/admin/survey/start_create', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.surveyOptions.question_list', url: '/admin/survey/questions', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.surveyOptions.question_settions', url: '/admin/survey/questions/parametres', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.surveyOptions.exported_surveys', url: '/admin/survey/export/historic', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.surveyOptions.import_export', url: '/admin/survey/import', userTypes: ['SUPERADMIN', 'ADMIN'] }
]
export const surveyListOptions = [
  { text: 'navbar.surveyListOptions.surveys_inconstruction', url: '/admin/survey', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.surveyListOptions.surveys_inuse', url: '/admin/survey/used', userTypes: ['SUPERADMIN', 'ADMIN'] }
]
export const parametersOptions = [
  { text: 'navbar.parametersOptions.settings', url: '/admin/survey/questions/parametres', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.parametersOptions.reponse_model', url: '/admin/survey/questions/responses_model', userTypes: ['SUPERADMIN', 'ADMIN'] }
]
export const transformModel = [
  { text: 'navbar.transformModel.transformation_model_list', url: '/admin/survey/transformation_model', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.transformModel.create_transform_model', url: '/admin/survey/transformation_model/start_create', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.transformModel.evaluation_method_list', url: '/admin/survey/evaluation_methods', userTypes: ['SUPERADMIN', 'ADMIN'] },
  { text: 'navbar.transformModel.evaluation_method_create', url: '/admin/survey/evaluation_method/start_create', userTypes: ['SUPERADMIN', 'ADMIN'] }
]

export const importOptions = [
  // { text: 'Importer type de question', url: '/admin/survey/questions/import_type'  , userTypes: ['SUPERADMIN', 'ADMIN']},
  { text: 'navbar.importOptions.import_export', url: '/admin/survey/import', userTypes: ['SUPERADMIN', 'ADMIN'] }
]
export const questionSettings = [
  { text: 'questionSettings.options_list' },
  { text: 'questionSettings.form_list' },
  { text: 'questionSettings.tags_listn' }
]

export const usersOptions = [
  { text: 'navbar.usersOptions.users_list', url: '/admin/users', userTypes: ['SUPERADMIN'] },
  { text: 'navbar.usersOptions.create', url: '/admin/users/create', userTypes: ['SUPERADMIN'] }
]
export const ActivityOptions = [
  { text: 'navbar.ActivityOptions.activity_sector', url: '/admin/settings/activity_sector', userTypes: ['SUPERADMIN', 'ADMIN'] }
]

export const adminType = {
  ADMIN: 'admin.users.edit.restrictions.amdin_explication',
  SUPERADMIN: 'admin.users.edit.restrictions.superamdin_explication',
  CONSULTANT: 'admin.users.edit.restrictions.consultant_explication'
}
