import React from 'react'

import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DeleteEvaluationMethod from '../components/deleteEvaluationMethod'
import DeleteIcon from '@mui/icons-material/Delete'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'
import { FilterMatchMode } from 'primereact/api'

function EvaluationMethodList ({ evaluationMethodList }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        <EditIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/admin/survey/evaluation_method/create/${e.id}`)}/>
        <DeleteEvaluationMethod id={e.id}>
          <DeleteIcon sx={{ color: 'red', cursor: 'pointer' }}/>
        </DeleteEvaluationMethod>
      </div>
    )
  }
  const tableConfig = {
    value: evaluationMethodList,
    stateKey: 'dt-state-evaluation-method-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    header,
    filters,
    globalFilterFields: ['name', 'description'],
    emptyMessage: t('admin.survey.evaluation_model.table.evaluation_methods_listNotFound'),
    columns: [
      { field: 'name', sortable: true, header: t('common.table.titre'), style: { width: '20%' } },
      { field: 'description', sortable: true, header: t('common.table.description'), style: { width: '60%' } },
      { body: actionTemplate, header: t('common.table.action') }]
  }
  const DataTableComponent = createDataTable(tableConfig)

  return (
        <div className="datatable-rowgroup-demo max-h-500 overflow-auto border-1 border-gray-200 mb-2">
          {DataTableComponent}
        </div>
  )
}

export default EvaluationMethodList
