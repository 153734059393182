import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store, persistor } from './app/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { PersistGate } from 'redux-persist/integration/react'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </PersistGate>
        </Provider>
    </React.StrictMode>
)
reportWebVitals()
