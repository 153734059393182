import React from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import './style.css'
import BeatLoader from 'react-spinners/BeatLoader'
import { useAddCampaignMutation } from '../campaignsSlice'
import { formatCampaign } from '../utils/formatCampaign'
import { useNavigate, useParams } from 'react-router-dom'
import { selectCurrentUser } from '../../auth/authSlice'
import { useSelector } from 'react-redux'
import IndexAlert from '../../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const CreateCampaign = ({ children, campaign }) => {
  const { t } = useTranslation()
  const user = useSelector(selectCurrentUser)
  const { idEntity } = useParams()
  const navigate = useNavigate()
  const [addCampaign, { isLoading, isSuccess, isError }] = useAddCampaignMutation()

  const HandleAddCampaign = async () => {
    const finalCampaign = formatCampaign(campaign, idEntity, user.id)
    await addCampaign(finalCampaign)
  }
  const ref = React.useRef()
  const closeTooltip = () => {
    ref.current.close()
    if (isSuccess) { navigate(`/hub/entity/${idEntity}/campaigns`) }
  }
  return (
    <div>
    <Popup
        closeOnDocumentClick={false}
        trigger={children}
        onOpen={() => HandleAddCampaign()}

        contentStyle={{ width: '400px', heigh: '300px' }}
        modal
        ref={ref}
    >
      {close => (
        <div className="modal">
            <div className="content flex flex-col justify-center items-center ">
                <h5 className="#444 text-lg ">{t('common.popup.create_campaign')}</h5>
                <div className="mt-5">
                   {isLoading && <BeatLoader color="#086e7c"/>}

                   {isSuccess && <IndexAlert type="success" text={t('common.popup.finish')}/>}
                   {isError && <div>{Error}</div>}
                </div>

            </div>
          <div className="actions">
          <button className="p-1 px-2 text-md bg-gray-300 rounded-sm" onClick={() => closeTooltip()} style={{ cursor: 'pointer' }}>{t('common.popup.close')}</button>
          </div>
        </div>
      )}
    </Popup>
    </div>
  )
}

CreateCampaign.propTypes = {
  campaign: PropTypes.object,
  children: PropTypes.node
}

export default CreateCampaign
