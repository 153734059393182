import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DeleteTransformMethode from '../components/deleteTransformMethode'
import DeleteIcon from '@mui/icons-material/Delete'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { FilterMatchMode } from 'primereact/api'

function TransformModelList ({ transformModelList }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        <EditIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/admin/survey/transformation_model/create/${e.id}`)}/>
        <DeleteTransformMethode id={e.id}>
          <DeleteIcon sx={{ color: 'red', cursor: 'pointer' }}/>
        </DeleteTransformMethode>
      </div>
    )
  }
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')

  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: transformModelList,
    stateKey: 'dt-state-transform-model-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    header,
    filters,
    globalFilterFields: ['title', 'description'],
    emptyMessage: t('admin.survey.transformation_model.tables.transformModel_list_notFound'),
    columns: [
      { field: 'title', sortable: true, header: t('common.table.titre'), style: { width: '20%' } },
      { field: 'description', sortable: true, header: t('common.table.description'), style: { width: '60%' } },
      { body: actionTemplate, header: t('common.table.action') }
    ]
  }
  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300 ">
        {DataTableComponent}
        </div>
    </div>

  )
}
export default TransformModelList
