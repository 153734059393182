import React from 'react'
import { useGetOptionsQuery, useUpdateOptionTemplateMutation } from './questionSlice'
import IndexAlert from '../../components/alerts/indexAlert'
import { Spinner } from '../../components/loadData/spinner'

import { useTranslation } from 'react-i18next'
import { MultiSelect } from 'primereact/multiselect'

export default function ModelModify ({ data }) {
  const { t } = useTranslation()
  const [updateOptionTemplate, { isLoading, isSuccess, isError, error }] = useUpdateOptionTemplateMutation()

  const { data: options } = useGetOptionsQuery()
  const [optionsFilter, setOptionsFilter] = React.useState([])

  const [questionType, setQuestionType] = React.useState({
    title: '',
    options: ''
  })

  React.useEffect(() => {
    if (options) {
      setOptionsFilter(options)
    }
  }, [options])

  React.useEffect(() => {
    if (data) {
      setQuestionType({
        ...questionType,
        id: data.id,
        title: data.title,
        options: optionsFilter?.filter((op) => data?.options?.includes(op.id))
      })
    }
  }, [optionsFilter, data])

  const handleModiType = async () => {
    const { id, title } = questionType
    const options = questionType.options.map((op) => op.id)
    await updateOptionTemplate({ id, title, options })
  }
  return (
    <div className="w-full">
      <div className="md:w-full px-0.5 mb-6 md:mb-0 relative rounded-sm ">
        {isSuccess && <IndexAlert type="success" text={t('question.question_modified')} />}
        {isLoading && <Spinner />}
        {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)} /> </div>}
        <label htmlFor="price" className="block mt-2 mb-1 font-medium text-gray-800 text-lg">
          {t('question.text')}
        </label>
        <input type="text" name="price" id="price" className="focus:ring-teal-500
    focus:border-teal-500 block  w-full pl-2 pr-12 text-lg  text-[#444444]
    rounded-sm" style={{ border: '1px solid #e8e6e6' }} disabled={true}
          placeholder="Question text" value={questionType?.title ? questionType.title : ''}
          onChange={(e) => setQuestionType({ ...questionType, title: e.target.value })} />
      </div>
      <div className="md:w-full mb-6 md:mb-0  relative rounded-sm ">
        <div className="w-full flex flex-col gap-1">
          <label htmlFor="price" className="block mt-2 font-medium text-gray-800 text-lg">
            {t('question.questions_options')}
          </label>
          <div className="card flex md:w-full">
            <MultiSelect value={questionType?.options ? questionType.options : []} onChange={(e) => setQuestionType({ ...questionType, options: e.value })} options={options}
            optionLabel="text" display="chip" filter placeholder="Select Cities" maxSelectedLabels={15} className="w-full rounded-sm" />
        </div>
        </div>
        <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
  px-2 py-0.5 mt-4 float-right" style={{ cursor: 'pointer' }} onClick={() => handleModiType()}>{t('question.modify')}</div>
      </div>
    </div>
  )
}
