import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const activitiesSectorAdapter = createEntityAdapter({
})
const initialState = activitiesSectorAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const activitiesApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'activitiesSlice',
  tagTypes: ['Activities'],
  endpoints: builder => ({
    getActivities: builder.query({
      query: () => '/activitySector/',
      transformResponse: responseData => {
        return activitiesSectorAdapter.setAll(initialState, responseData)
      },
      providesTags: ['Activities']
    }),
    getActivityByReference: builder.query({
      query: (reference) => `/activitySector/reference/${reference}`,
      transformResponse: responseData => {
        return activitiesSectorAdapter.setAll(initialState, responseData)
      },
      providesTags: ['Activities']
    })
  })
})

export const {
  useGetActivitiesQuery,
  useGetActivityByReferenceQuery
} = activitiesApiSlice

export const selectActivitiesAdminResult = activitiesApiSlice.endpoints.getActivities.select()

export const selectActivitiesAdminData = createSelector(
  selectActivitiesAdminResult,
  activitiesResult => activitiesResult.data
)

export const {
  selectAll: selectAllActivities,
  selectById: selectSingleActivity
} = activitiesSectorAdapter.getSelectors(state => selectActivitiesAdminData(state) ?? initialState)
