import React from 'react'
import { useTranslation } from 'react-i18next'
import { selectAllActivities } from '../../../features/activitiesSector/activitiesSlice'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
function EntityGenralInfo ({ onChangeEntity, entity, onChangeStep }) {
  const { t } = useTranslation()
  const handleChange = event => {
    const min = 0
    const max = 999999
    const value = Math.max(min, Math.min(max, Number(event.target.value)))
    onChangeEntity({ ...entity, employee_Number: value })
  }

  const activivitySectors = useSelector(selectAllActivities)
  return (
    <section>
      <div className="flex flex-col  flex-wrap gap-2 mt-4">
        <div className="md:w-1/2 w-4/5 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
          <label
            htmlFor="price"
            className="block text-lg font-medium text-gray-800">
            {t('admin.organisation.create.fields.organisation_name')}
          </label>
          <input
            type="text"
            name="price"
            id="price"
            className="focus:ring-teal-500
          focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444]
          rounded-sm"
            style={{ border: '1px solid #e8e6e6' }}
            value={entity?.name ? entity.name : ''}
            onChange={(e) => onChangeEntity({ ...entity, name: e.target.value })}
          />
        </div>
        <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm ">
            <label className="block text-lg font-medium text-gray-800">
              {t('admin.organisation.create.fields.nbr_employee')}
            </label>
            <input
            type="text" name="price" id="price"
            className="focus:ring-teal-500
            focus:border-teal-500 block  w-full pl-2 pr-2 tsext-lg  text-[#444444]
            rounded-sm"
            style={{ border: '1px solid #e8e6e6' }}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            value={entity?.employee_Number ? entity.employee_Number : ''}
            onChange = {(e) => handleChange(e)}/>
        </div>
        <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm ">
          <label
            htmlFor="price"
            className="block text-lg font-medium text-gray-800">
            {t('admin.organisation.create.fields.activity_sector')}
          </label>
          <select className="focus:ring-teal-500 focus:border-teal-500 block border-1  border-[#e8e6e6] w-full pl-1 pr-2 text-cxl py-1  text-[#444444]
          rounded-sm" value ={entity?.activity_sector ? entity.activity_sector : undefined }
        onChange={(e) => onChangeEntity({ ...entity, activity_sector: e.target.value })}>
        <option></option>
          {activivitySectors.map((sector) => <option key = {sector.id} value={sector.id}>{sector.reference}-{sector.name}</option>)}
      </select>
        </div>
        <div className="w-4/5 mt-4">
          <button className="bg-teal-600 mx-2 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1">
            <h5 className="text-white  w-16" onClick={(e) => onChangeStep(3)}>{t('common.buttons.next')}</h5>
          </button>
          <button className="bg-gray-300 mx-2 px-2 py-0.5 rounded-md mb-1 text-md font-medium float-right p-1">
          <h5 className="text-[#444444]  w-max-fit" onClick={() => onChangeStep(1)}>{t('common.buttons.post')}</h5>
        </button>
        </div>
      </div>
    </section>
  )
}
EntityGenralInfo.propTypes = {
  onChangeEntity: PropTypes.func.isRequired,
  entity: PropTypes.object,
  onChangeStep: PropTypes.func.isRequired
}

export default EntityGenralInfo
