import React from 'react'

import { useParams } from 'react-router-dom'
import { useGetsurveyCampaignQuery } from '../../features/surveyCampaign/surveyCampaignsSlice'
import { useGetUserCampaignResponseQuery } from '../../features/surveyCampaign/userCampaignResponseSlice'

import AnswerSurveyNew from './answerSurveyNew'
import AnswerSurvey from './answerSurvey'
import SurveyfinishedState from './surveyfinishedState'

function AnswerSurveyController () {
  const { idCampaign, idUser } = useParams()
  const { data: surveyCampaign, isSuccess } = useGetsurveyCampaignQuery(idCampaign)
  const { data: userCampaignRespons, isSuccess: isSuccessUserCampaign } = useGetUserCampaignResponseQuery({ idCampaign, idUser })

  if (isSuccess && isSuccessUserCampaign) {
    if (userCampaignRespons?.submmitted_state) {
      return <SurveyfinishedState/>
    }
    if (surveyCampaign.show_tree) {
      return <AnswerSurvey showComments={false} commentUser={idUser} />
    } else {
      return <AnswerSurveyNew showComments={false} commentUser={idUser} />
    }
  } else {
    return undefined
  }
}

export default AnswerSurveyController
