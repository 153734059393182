import React from 'react'
import { FilterMatchMode } from 'primereact/api'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { useTranslation } from 'react-i18next'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'

function EvaluationList ({ evaluations, onChangeDetails }) {
  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
         <RemoveRedEyeIcon sx={{ color: '#444', cursor: 'pointer' }} onClick={(e) => onChangeDetails({ ...e, type: 'evaluation' })}/>
      </div>
    )
  }
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: evaluations || [],
    stateKey: 'dt-state-evaluation-list-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    header,
    filters,
    globalFilterFields: ['add_date', 'name', 'report_data.evaluationMethod'],
    emptyMessage: t('admin.organisation.details.evaluation_list_notFound'),
    columns: [
      { field: 'name', sortable: true, header: t('common.table.name') },
      { field: 'add_date', sortable: true, header: t('common.table.creation_date') },
      { field: 'report_data.evaluationMethod', sortable: true, header: t('admin.organisation.details.evaluationMethod') },
      { body: actionTemplate, header: t('common.table.action') }]
  }
  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300 ">
          {DataTableComponent}

        </div>
    </div>
  )
}
export default EvaluationList
