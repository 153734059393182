export const getCategories = (survey) => {
  const categories = survey.survey_tree.filter((survey) => survey.type === 'category')
  const questions = getQuestions(survey)
  const categoriesQuestions = []
  categories.map((category) => {
    const categoryQuestions = questions.filter((question) => question.parent === category.uuid)
    if (categoryQuestions.length > 0) {
      categoriesQuestions.push({ category, questions: categoryQuestions })
    }
  })
  return categoriesQuestions
}

export const getCategoryWichHasQuestions = (surveys) => {
  let categories = []
  for (let i = 0; i < surveys.length; i++) {
    const category = getCategories(surveys[i].survey_tree)
    categories = categories.concat([...category])
  }
  return categories
}

export const getQuestionParent = (survey, questions, id) => {
  const question = questions.find((question) => question.id === id)
  const questionParent = survey.survey_tree.filter((survey) => survey.id === question.parent)
  return questionParent
}

export const getQuestions = (survey) => {
  const questionChildren = survey.survey_tree.filter((question) => question.type === 'question')
  return questionChildren
}

export const getChildren = (survey, id) => {
  const categoryChildren = survey.survey_tree.filter(() => survey.type === 'category' && survey.parent === id)
  const questionChildren = survey.survey_tree.filter(() => survey.type === 'question' && survey.parent === id)
  return { categoryChildren, questionChildren }
}
