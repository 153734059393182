import React from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import ActivitySector from './ActivitySector'
import CreateActivitySector from './createActivitySector'

function IndexSettings () {
  return (
    <>
        <Outlet/>
        <Routes>
            <Route path="/activity_sector" exact element={<ActivitySector/>}></Route>
            <Route path="/activity_sector/create" exact element={<CreateActivitySector/>}></Route>

        </Routes>
    </>
  )
}

export default IndexSettings
