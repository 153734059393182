import React from 'react'
import CampaignUserList from './tables/campaignUserList'
import { useTranslation } from 'react-i18next'

function Card ({ text }) {
  return (
      <div className="bg-[#DCD3C6] px-2 p-1">{text}</div>
  )
}
function CampaignDetail ({ data }) {
  const { t } = useTranslation()
  return (
    <section>
        <div className="grid grid-span-3">
            <div className="col-span-2">
                <h5 className="block text-xl font-medium text-[#444] mt-2 pl-4">{t('admin.organisation.details.campagn_info')}</h5>
                <CampaignUserList data={data?.users_list?.users_list ? Object.values(data.users_list.users_list) : [] }/>
            </div>
        </div>
        <div className="grid grid-cols-2 w-full mb-4 px-2 overflow-auto h-250">
            <div className="col-span-1">
              <h5 className="block text-lg font-medium text-[#444] mt-2">{t('surveys')}</h5>
              <div className="flex flex-wrap gap-1">
              {['Treasure256', 'Treasure256 1.0', 'Treasure256 2.0'].map((survey, index) => (
                <Card key={index} text={survey}/>))}
              </div>

              <h5 className="block text-lg font-medium text-[#444] mt-2">{t('common.fields.description')}</h5>
              <div className="flex flex-wrap gap-1">
                <Card text={data.description}/>
              </div>
              <h5 className="block text-lg font-medium text-[#444] mt-2">{t('common.fields.status')}</h5>
              <div className="flex flex-wrap gap-1">
                <Card text={t(`backend.${data.status}`)}/>
              </div>
              <h5 className="block text-lg font-medium text-[#444] mt-2">{t('common.fields.users')}</h5>
            </div>
            <div className="col-span-1 flex flex-col gap-2 w-full overflow-auto h-200 pr-4 pt-4">
              <div className="flex justify-between">
                <p>{t('common.fields.creation_date')}</p>
                <p>10/05/2019</p>
              </div>

              <div className="flex justify-between">
                <p>{t('common.fields.deadLine')}</p>
                <p>{data.time_deadLine}</p>
              </div>
            </div>
        </div>

    </section>
  )
}

export default CampaignDetail
