import React from 'react'
import { useTranslation } from 'react-i18next'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'
import { FilterMatchMode } from 'primereact/api'

function ListEntityCampaigns ({ onChangeDetails, campaignsData }) {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))
  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        <RemoveRedEyeIcon sx={{ color: '#444', cursor: 'pointer' }} onClick={() => onChangeDetails({ ...e, type: 'campaign' })}/>
      </div>
    )
  }
  const tableConfig = {
    paginator: true,
    stateKey: 'dt-state-entity-campaign-list',
    onFilter: (e) => setFilters(e.filters),
    paginatorTemplate: template2,
    header,
    filters,
    dataKey: 'id',
    Fields: ['name', 'time_deadLine', 'status'],
    emptyMessage: t('admin.organisation.details.compaigne_list_notFound'),
    columns: [
      { field: 'name', sortable: true, header: t('common.table.name') },
      { field: 'time_deadLine', sortable: true, header: t('common.table.deadLine'), className: 'text-center' },
      { field: 'status', sortable: true, header: t('common.table.status'), style: { textAlign: 'center' } },
      { body: actionTemplate, header: t('common.table.action') }
    ]
  }

  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div className="p-1">
        <div className="card mx-auto w-full border-1 border-gray-100 ">
        {DataTableComponent}
        </div>
    </div>
  )
}
export default ListEntityCampaigns
