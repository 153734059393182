import React from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import CreateUser from './createUser'
import EditUser from './editUser'
import UsersTracking from './usersTracking'
import DetailsUser from './detailsUser'
import { useGetUsersQuery } from '../../../features/users/usersSlice'

function IndexUser () {
  const { isSuccess: isSuccessAdmins } = useGetUsersQuery()
  return (
    <>
        <Outlet/>
        <Routes>
            <Route path="create" exact element={<CreateUser/>}></Route>
            <Route path="edit/:id" exact element={<EditUser/>}></Route>
            <Route path="details/:id" exact element={<DetailsUser/>}></Route>
            <Route path="" exact element={<UsersTracking isSuccess={isSuccessAdmins}/>}></Route>
        </Routes>
    </>
  )
}

export default IndexUser
