import React from 'react'
import { useGetQuestionTagsQuery, useGetOptionsQuery, useGetOptionsTemplatesQuery, useModifQuestionMutation } from './questionSlice'
import IndexAlert from '../../components/alerts/indexAlert'
import { Spinner } from '../../components/loadData/spinner'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AdditionalParams from './components/additionalParams'
import PropTypes from 'prop-types'
import { MultiSelect } from 'primereact/multiselect'
import Input from './utils/input'

function QuestionModify ({ data }) {
  const { t } = useTranslation()
  const [modifQuestion, { isLoading, isSuccess, isError, error }] = useModifQuestionMutation()
  const { data: questionTags } = useGetQuestionTagsQuery()
  const { data: questionTemplates } = useGetOptionsTemplatesQuery()
  const { data: options } = useGetOptionsQuery()
  const navigate = useNavigate()

  const [question, setQuestion] = React.useState({})

  React.useEffect(() => {
    if (questionTags && data) {
      setQuestion({
        ...question,
        id: data.id,
        text: data.text,
        question_type: data.question_type,
        options_model: data.options_model,
        select_options: data.select_options,
        question_help_text: data.question_help_text,
        question_tag: questionTags?.filter((tag) => data?.question_tag?.includes(tag.id)),
        params: data?.params?.params ? data.params.params : { },
        unit: data?.params?.unit ? data.params.unit : null,
        reference: data?.reference ? data.reference : null
      })
    }
  }, [questionTags, data])



  React.useEffect(() => {
    if (question.question_type === 'slider' || question.question_type === 'double_slider') {
      setQuestion({ ...question, unit: 'Fraction' })
    } else {
      if (question.question_type !== 'number') {
        setQuestion({ ...question, unit: '' })
      }
    }
  }, [question.question_type])
  const questionType = ['select', 'number', 'boolean', 'multiselect', 'text', 'slider', 'double_slider']
  const handleModifyQuestion = async () => {
    const tags = question?.question_tag?.map((op) => op.id)
    const questionData = { ...question, question_tag: tags }
    await modifQuestion(questionData)
  }
  return (
    <div className="w-full">
      <div className="md:w-full px-0.5 mb-6 md:mb-0 relative rounded-sm ">
        {isSuccess && <IndexAlert type="success" text={t('question.question_modified')} />}
        {isLoading && <Spinner />}
        {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)} /> </div>}
        <label htmlFor="price" className="block mt-2 mb-1 font-medium text-gray-700 text-lg">
          {t('question.text')}
        </label>
        <textarea type="text" name="price" id="price" className="focus:ring-teal-500
    focus:border-teal-500 block  w-full pl-2 pr-12 text-lg  text-[#444444]
    rounded-sm" style={{ border: '1px solid #e8e6e6' }} rows={6}
          placeholder="Question text" value={question?.text ? question.text : ''}
          onChange={(e) => setQuestion({ ...question, text: e.target.value })}></textarea>
      </div>
      <label htmlFor="price" className="block font-medium text-gray-700 text-lg">
          {t('question.question_help_text')}
        </label>
        <textarea type="text" name="price" id="price" className="focus:ring-teal-500
        focus:border-teal-500 block  w-full pl-2 pr-12 text-lg  text-[#444444] rounded-sm" rows={5}
        style={{ border: '1px solid #e8e6e6' }} placeholder={t('question.question_help_text_placeholder')}
        value={question?.question_help_text ? question.question_help_text : ''}
        onChange={(e) => setQuestion({ ...question, question_help_text: e.target.value })}></textarea>
      <div className="md:w-full mb-6 md:mb-0 mt-1 relative rounded-sm ">
        <label htmlFor="price" className="block mt-2 mb-1 font-medium text-gray-700 text-lg">
          {t('question.questionType')}
        </label>
        <select className="focus:ring-teal-500 focus:border-teal-500 block border-2  border-[#e8e6e6] h-8 w-full pl-1 pr-2 text-lg  text-[#444444]
    rounded-sm" value={question?.question_type ? question.question_type : undefined}
          onChange={(e) => {
            setQuestion({ ...question, question_type: e.target.value })
          }}>
          <option></option>
          {questionType.map((qst, index) => <option key={index} value={qst}>{qst}</option>)}
        </select>
        <Input text ={t('question.question_referance')} value={question?.reference || ''}
        onChange={(val) => setQuestion({ ...question, reference: val })}
        type={'text'} disabled={false}/>

        <AdditionalParams question={question || null} ChangeQuestion={(val) => setQuestion(val)}/>
        {['select', 'multiselect', 'slider', 'double_slider'].includes(question.question_type) &&
          <div className="w-full flex gap-4">
            <div className='w-full'>
              <label htmlFor="price" className="block mt-2 mb-1 font-medium text-gray-700 text-lg">
                {t('question.questions_options')}
              </label>
              <div className="w-full flex jusify-between gap-2">
                <select className="w-full focus:ring-teal-500 focus:border-teal-500 block border-2
                  border-[#e8e6e6] pl-1 pr-2 text-lg  text-[#444444] max-w-100
                  rounded-sm"
                  value={question?.select_options ? question.select_options : []}
                  onChange={(e) => setQuestion({ ...question, select_options: Array.from(e.target.selectedOptions, option => option.value), options_model: '' })} multiple={true}>
                  {options ? options.map((op, index) => <option key={index} value={op.id}>{op.text}</option>) : undefined}
                </select>
                <div className='rounded-full bg-teal-700 max-h-fit align-middle' style={{ cursor: 'pointer' }}>
                  <AddIcon onClick={() => navigate('/admin/survey/questions/parametres')} style={{ fill: '#fff' }} />
                </div>
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="price" className="block mt-2 mb-1 font-medium text-gray-700 text-lg">
                {t('question.respond_model')}
              </label>
              <div className="w-full flex jusify-between gap-2">
                <select className="w-full focus:ring-teal-500 focus:border-teal-500 block border-2
    border-[#e8e6e6] pl-1 pr-2 text-lg  text-[#444444] max-w-100
    rounded-sm"
                  value={question?.options_model ? question.options_model : ''}
                  onChange={(e) => setQuestion({ ...question, select_options: [], options_model: e.target.value })} >
                  <option></option>
                  {questionTemplates ? questionTemplates.map((op, index) => <option key={index} value={op.id}>{op.title}</option>) : undefined}
                </select>
                <div className='rounded-full bg-teal-700 max-h-fit align-middle' style={{ cursor: 'pointer' }}>
                  <AddIcon onClick={() => navigate('/admin/survey/questions/parametres')} style={{ fill: '#fff' }} />
                </div>
              </div>
            </div>
          </div>
        }
        <label htmlFor="price" className="block mt-2 mb-1 font-medium text-gray-700 text-lg">
          {t('question.question_tags')}
        </label>
        <div className="card flex md:w-3/4 sm:w-1/2">
            <MultiSelect value={question?.question_tag || []} onChange={(e) => setQuestion({ ...question, question_tag: e.value })} options={questionTags}
            optionLabel="text" display="chip" filter placeholder="Select Cities" maxSelectedLabels={15} className="w-full rounded-sm" />
        </div>
        <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
  px-2 py-0.5 mt-4 float-right" style={{ cursor: 'pointer' }} onClick={() => handleModifyQuestion()}>{t('question.modify')}</div>
      </div>
    </div>
  )
}
QuestionModify.propTypes = {
  data: PropTypes.object
}
export default QuestionModify
