import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetMaterialityMatrixQuery } from '../../../features/materialityMatrix/materialityMatrixSlice'
import { useTranslation } from 'react-i18next'
function MaterialityMatrixDetails () {
  const navigate = useNavigate()
  const { id_matrix } = useParams()
  const { data: materiality_matrix, isSuccess: isSuccessCampaigns, isLoading: isLoadingCampaigns, isError: isErrorCampaigns, Error: ErrorCampaigns } = useGetMaterialityMatrixQuery(id_matrix)
  
  console.log('materiality_matrix', materiality_matrix)
  const { t } = useTranslation()

  return (
    <div className="w-full flex justify-center px-auto ">
      <div className="md:w-2/3 lg:w-2/3 sm:w-2/3 bg-wite md:mx-8 pb-8 mt-4">
        <div className="mt-1 my-5 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4" >
          <div className="flex justify-between w-full">
            <h5 className="text-2xl font-medium  text-[#444444] w-max-fit">{t('consultant.materiality_matrix.Liste_campaigns')} </h5>
          </div>

          <h5 className="text-2xl font-medium  text-[#444444] w-max-fit">{t('consultant.materiality_matrix.intern_campaign')} </h5>
          {// campaigns && <CampaignTable compaigns={campaigns} />
          <h5 className="text-2xl font-medium  text-[#444444] w-max-fit">{t('consultant.materiality_matrix.extern_campaign')} </h5>
          // campaigns && <CampaignTable compaigns={campaigns} />
          }
        </div>
      </div>
    </div>
  )
}

export default MaterialityMatrixDetails
