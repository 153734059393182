import React, { useState } from 'react';

const EntityFile = () => {
  const [formData, setFormData] = useState({
    chiffre_affaires: '',
    profitabilite_ebitda: '',
    conteneur_statuts: '',
    eventuals_labels: '',
    frais_promotion_marque_produit: '',
    frais_marque_employeur: '',
    part_salaries_handicap: '',
    nombre_salaries_etp: '',
    part_capital_detenue_par_salaries: '',
    accords_de_participation: '',
    repartition_hf: { m: '', f: '' },
    egalite_salaires_hf: {},
    ecart_remuneration: '',
    participation_salaries_decisions_strategiques: '',
    nombre_accidents_travail: {},
    informations_absenteisme: '',
    niveaux_competences_salaries: '',
    volume_cout_formations: {},
    part_fournisseurs_locaux: '',
    montant_investissements_societaux: {},
    montant_budget_compensation_carbone: '',
    roe: '',
    budget_philanthropie: '',
    validated: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRepartitionChange = (e, gender) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      repartition_hf: {
        ...prevData.repartition_hf,
        [gender]: value,
      },
    }));
  };

  const handleEgaproChange = (e, key) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      egalite_salaires_hf: {
        ...prevData.egalite_salaires_hf,
        [key]: value,
      },
    }));
  };

  const handleAccidentsChange = (e, key) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      nombre_accidents_travail: {
        ...prevData.nombre_accidents_travail,
        [key]: value,
      },
    }));
  };

  const handleFormationsChange = (e, key) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      volume_cout_formations: {
        ...prevData.volume_cout_formations,
        [key]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  return (
    <section className="bg-gray-50 min-h-screen p-4">
      <div className="flex gap-1">
        <div className="flex bg-[#f5f4f0] rounded-lg mx-auto w-3/5">
          <form onSubmit={handleSubmit} className="flex gap-6 w-4/5 pl-4 pt-4">
            {/* Financial Information */}
            <div className='w-full'>
              <div className='flex flex-col'>
                <label className=''>Chiffre d'affaires:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  step="0.01"
                  name="chiffre_affaires"
                  value={formData.chiffre_affaires}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Profitabilité EBITDA:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  step="0.01"
                  name="profitabilite_ebitda"
                  value={formData.profitabilite_ebitda}
                  onChange={handleChange}
                />
              </div>
              {/* Other Information */}
              <div className='flex flex-col '>
                <label className=''>Conteneur Statuts:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="conteneur_statuts"
                  value={formData.conteneur_statuts}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Eventuals Labels:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="eventuals_labels"
                  value={formData.eventuals_labels}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Frais Promotion Marque Produit:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  step="0.01"
                  name="frais_promotion_marque_produit"
                  value={formData.frais_promotion_marque_produit}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Frais Marque Employeur:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  step="0.01"
                  name="frais_marque_employeur"
                  value={formData.frais_marque_employeur}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Part Salaries Handicap:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  step="0.01"
                  name="part_salaries_handicap"
                  value={formData.part_salaries_handicap}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Nombre Salaries ETP:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  name="nombre_salaries_etp"
                  value={formData.nombre_salaries_etp}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Part Capital Detenue Par Salaries:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  step="0.01"
                  name="part_capital_detenue_par_salaries"
                  value={formData.part_capital_detenue_par_salaries}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Accords de Participation:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="accords_de_participation"
                  value={formData.accords_de_participation}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Repartition Hommes/Femmes:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="repartition_hf_m"
                  value={formData.repartition_hf.m}
                  onChange={(e) => handleRepartitionChange(e, 'm')}
                />
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="repartition_hf_f"
                  value={formData.repartition_hf.f}
                  onChange={(e) => handleRepartitionChange(e, 'f')}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Egalite Salaires Hommes/Femmes:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="egalite_salaires_hf_m"
                  value={formData.egalite_salaires_hf.m}
                  onChange={(e) => handleEgaproChange(e, 'm')}
                />
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="egalite_salaires_hf_f"
                  value={formData.egalite_salaires_hf.f}
                  onChange={(e) => handleEgaproChange(e, 'f')}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Ecart Remuneration:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  step="0.01"
                  name="ecart_remuneration"
                  value={formData.ecart_remuneration}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='w-full'>
              <div className='flex flex-col '>
                <label className=''>Participation Salaries Decisions Strategiques:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="participation_salaries_decisions_strategiques"
                  value={formData.participation_salaries_decisions_strategiques}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Nombre Accidents Travail:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="nombre_accidents_travail_m"
                  value={formData.nombre_accidents_travail.m}
                  onChange={(e) => handleAccidentsChange(e, 'm')}
                />
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="nombre_accidents_travail_f"
                  value={formData.nombre_accidents_travail.f}
                  onChange={(e) => handleAccidentsChange(e, 'f')}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Informations Absenteisme:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  step="0.01"
                  name="informations_absenteisme"
                  value={formData.informations_absenteisme}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Niveaux Competences Salaries:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  name="niveaux_competences_salaries"
                  value={formData.niveaux_competences_salaries}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Volume Cout Formations:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="volume_cout_formations_m"
                  value={formData.volume_cout_formations.m}
                  onChange={(e) => handleFormationsChange(e, 'm')}
                />
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="volume_cout_formations_f"
                  value={formData.volume_cout_formations.f}
                  onChange={(e) => handleFormationsChange(e, 'f')}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Part Fournisseurs Locaux:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  step="0.01"
                  name="part_fournisseurs_locaux"
                  value={formData.part_fournisseurs_locaux}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Montant Investissements Societaux:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="montant_investissements_societaux_m"
                  value={formData.montant_investissements_societaux.m}
                  onChange={(e) => handleFormationsChange(e, 'm')}
                />

                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="text"
                  name="montant_investissements_societaux_f"
                  value={formData.montant_investissements_societaux.f}
                  onChange={(e) => handleFormationsChange(e, 'f')}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>Montant Budget Compensation Carbone:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  step="0.01"
                  name="montant_budget_compensation_carbone"
                  value={formData.montant_budget_compensation_carbone}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col '>
                <label className=''>ROE:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  step="0.01"
                  name="roe"
                  value={formData.roe}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className=''>Budget Philanthropie:</label>
                <input
                  className="ml-2 mt-2 form-number appearance-none block w-3/4 py-0.5 text-base font-normal text-gray-700
                bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                  type="number"
                  step="0.01"
                  name="budget_philanthropie"
                  value={formData.budget_philanthropie}
                  onChange={handleChange}
                />
              </div>
              <button type="submit">Submit</button>
            </div>

            {/* Submit button */}
           
          </form>
        </div>
      </div>
    </section>
  );
};

export default EntityFile;
