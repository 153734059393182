import React from 'react'
import { useCreateOptionTemplateMutation, useGetOptionsLGQuery } from './questionSlice'
import IndexAlert from '../../components/alerts/indexAlert'
import { Spinner } from '../../components/loadData/spinner'
import { useTranslation } from 'react-i18next'
import { MultiSelect } from 'primereact/multiselect'

export default function OptionFormAdd ({ add }) {
  const { t } = useTranslation()
  const [optionTemplates, setOptionTemplates] = React.useState([])
  const [createOptionTemplate, { isSuccess, isLoading, isError, error }] = useCreateOptionTemplateMutation()
  const { data: options } = useGetOptionsLGQuery()

  React.useEffect(() => {
    if (options) {
      setOptionTemplates(options)
    }
  }, [options])
  const [optionForm, setOptionForm] = React.useState({
    title: '',
    options: []
  })

  const handleCreateQuestion = async () => {
    const optionsFiltered = optionForm.options.map((op) => op.id)
    await createOptionTemplate({ title: optionForm.title, options: optionsFiltered })
    if (isSuccess) {
      setOptionForm({ title: '', options: [] })
    }
  }
  return (
    <div className="w-full">
      <div className="md:w-full px-0.5 mb-6 md:mb-0 relative rounded-sm ">
     {isSuccess && <IndexAlert type="success" text="Forme des option crée"/>}
      {isLoading && <Spinner/>}
  {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)}/> </div>}
      <h5 className="text-2xl font-medium text-gray-700 w-max-fit">{t('admin.survey.questions.create_respond_model')}</h5>
        <label htmlFor="price" className="block font-medium text-gray-700 text-lg">
          {t('common.fields.titre')}
        </label>
        <textarea type="text" name="price" id="price" className="focus:ring-teal-500
        focus:border-teal-500 block  w-full pl-2 pr-12 text-md  text-[#444444]
        rounded-sm" style={{ border: '1px solid #e8e6e6' }} rows={4}
        placeholder={t('common.fields.titre')} value={optionForm?.title ? optionForm.title : ''}
          onChange={(e) => setOptionForm({ ...optionForm, title: e.target.value })}></textarea>
      </div>
      <div className="md:w-full mb-6 md:mb-0 mt-1 relative rounded-sm ">
      <div className="flex flex-col justify-between">
          <label htmlFor="price" className="block font-medium text-gray-700 text-lg">
          {t('admin.survey.questions.questions_options')}
        </label>
        <div className="card flex md:w-3/4 sm:w-1/2">
            <MultiSelect value={optionForm.options || []} onChange={(e) => setOptionForm({ ...optionForm, options: e.value })} options={options}
            optionLabel="text" display="chip" filter placeholder={t('admin.survey.questions.questions_options')} maxSelectedLabels={15} className="w-full rounded-sm" />
        </div>
      </div>
      <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
        px-2 py-0.5 mt-4 float-right" style={{ cursor: 'pointer' }} onClick={() => handleCreateQuestion()}>{t('common.buttons.create')}</div>
      </div>
  </div>
  )
}
/*

*/
