import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const createdSurveysAdapter = createEntityAdapter({
})
const initialState = createdSurveysAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const createdSurveysApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'createdSurveys',
  endpoints: builder => ({
    getSurveys: builder.query({
      query: () => '/question/surveys',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['CreatedSurvey']
    }),
    getSurvey: builder.query({
      query: (id) => '/question/survey/',
      transformResponse: responseData => {
        return responseData
      }
    }),
    createSurvey: builder.mutation({
      query: (data) => ({
        url: '/question/survey/start_create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['CreatedSurvey']
    }),
    deleteSurvey: builder.mutation({
      query: (id) => ({
        url: `/question/survey/${id}/details`,
        method: 'DELETE'
      }),
      invalidatesTags: ['CreatedSurvey']
    })
  })
})

export const {
  useGetSurveysQuery,
  useCreateSurveyMutation,
  useDeleteSurveyMutation
} = createdSurveysApiSlice

export const createdSelectSurveyResult = createdSurveysApiSlice.endpoints.getAllSurveys.select()

export const selectSurveyData = createSelector(
  createdSelectSurveyResult,
  surveysResult => surveysResult.data
)

export const {
  selectAll: selectAllSurveys,
  selectById: selectSurveyById
} = createdSurveysAdapter.getSelectors(state => selectSurveyData(state) ?? initialState)
