import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetEvaluationsQuery } from '../../../features/evaluation/evaluationSlice'
import EvaluationList from './components/evaluationList'
import { useTranslation } from 'react-i18next'

function Evaluations () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchText, setSearchText] = React.useState()
  const { idEntity } = useParams()

  const { data: evaluations, isSuccess, isError, isLoading, error } = useGetEvaluationsQuery(idEntity)

  return (
    <div className="w-full flex justify-center px-auto">
      <div className="md:w-2/3 lg:w-2/3 sm:w-2/3 bg-wite md:mx-8 pb-8 mt-4">
        <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4" >
            <div className="flex my-2 justify-between w-full">
              <h5 className="text-2xl font-medium  text-[#444444] w-max-fit">{t('consultant.evaluation.Liste_évaluations')} </h5>
              <div className="bg-teal-600 w-max-fit px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium"
                style={{ cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${idEntity}/evaluation/create`)}>{t('consultant.buttons.createEvaluation')}
              </div>
            </div>
            <EvaluationList evaluations={evaluations || []} />
        </div >
      </div>
    </div>
  )
}

export default Evaluations
