import React from 'react'
import CapitalCard from './component/CapitalCard'
import Questions from '../../components/survey/questions'
import { getIdRoot, Category, CategoryQuestions, CategoryStart } from './surveytree'
import SimpleStepper from './component/simpleStepperTree'
import CapitalCardSurvey from './component/CapitalCardSurvey'
import { useParams, useNavigate } from 'react-router-dom'
import { useGetsurveyCampaignQuery } from '../../features/surveyCampaign/surveyCampaignsSlice'
import { useSubmitCampaignMutation } from '../../features/surveyCampaign/userCampaignResponseSlice'
import { useGetResponsesQuery, useGetResponsesStatisticsQuery, useValidateResponsesMutation } from '../../features/response/responsesSlice'
import PropTypes from 'prop-types'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ResponseStatistics from '../../appComponents/questions/bar/responseStatistics'
import UploadIcon from '@mui/icons-material/Upload'
import { useTranslation } from 'react-i18next'
import {sortByOrder} from './utils/orderQuestionCategories'

function AnswerSurvey (props) {
  const { idCampaign, idUser } = useParams()
  let [survey, setSurvey] = React.useState()
  const [activeStep, SetActiveStep] = React.useState()
  let [steps, setSteps] = React.useState([])
  const { data: responsesStatistics } = useGetResponsesStatisticsQuery({ campaign: idCampaign, user: idUser })
  const { data: surveyCampaign, isSuccess: isSuccessCampaign } = useGetsurveyCampaignQuery(idCampaign)
  let [surveyAf, setIdroot] = React.useState({ id_root: '', category: '', CategoryQuestions: '' })
  const [surveys, setSurveys] = React.useState([])
  const [mainSurvey, setMainSurvey] = React.useState({})
  const { data: responses } = useGetResponsesQuery({ campaign: idCampaign, user: idUser })
  const [ValidateResponses] = useValidateResponsesMutation()
  const [SubmitCampaign, { isSuccess, isError, error }] = useSubmitCampaignMutation() 
  const { t } = useTranslation()
  const sorted_categories = sortByOrder(surveyAf?.category || [] )  

  React.useEffect(() => {
    if (mainSurvey?.id) {
      const surveyTree = mainSurvey.survey_tree
      const rootId = getIdRoot(surveyTree)
      const category = Category(surveyTree, rootId)
      const categoryQuestion = CategoryQuestions(surveyTree, rootId)
      setIdroot(surveyAf = {
        ...surveyAf,
        id_root: rootId,
        category,
        CategoryQuestions: categoryQuestion
      })
      setSteps(steps = [{ uuid: rootId, text: mainSurvey.name }])
    }
  }, [mainSurvey])

  React.useEffect(() => {
    if (isSuccessCampaign && surveyCampaign) {
      setSurvey(survey = surveyCampaign.survey_tree)
      const surveys = CategoryStart(survey)
      setSurveys(surveys)
    }
  }, [isSuccessCampaign, surveyCampaign])

  const handlechangeStep = activeStep => {
    SetActiveStep(activeStep)
    const nSteps = steps.slice(0, activeStep + 1)
    setSteps(nSteps)
    setIdroot(surveyAf = {
      ...surveyAf,
      category: Category(mainSurvey.survey_tree, steps[activeStep].uuid),
      CategoryQuestions: CategoryQuestions(mainSurvey.survey_tree, steps[activeStep].uuid)
    })
  }

  const surveyNavigate = (category) => {
    setIdroot(surveyAf = { ...surveyAf, category: Category(mainSurvey.survey_tree, category.uuid), CategoryQuestions: CategoryQuestions(mainSurvey.survey_tree, category.uuid) })
    setSteps(steps = [...steps, category])
  }
  const handleSubmitResponse = async () => {
    await SubmitCampaign({ campaign: idCampaign, user: idUser })
  }
  const handleValidateResponses = async () => {
    await ValidateResponses({ campaign: idCampaign, user: idUser })
  }
  React.useEffect(() => {
    if (isSuccess) {
      window.location.reload()
    }
  }, [isSuccess])

  if (!survey) {
    return undefined
  } else {
    return (
      <div className="mt-8 px-2">
          <div className='flex justify-center w-full bg-gary-400 gap-3'>
            <button className="flex justify-center items-center bg-teal-600 mx-2 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1"
            onClick={() => handleSubmitResponse()} style={{ cursor: 'pointer' }}>
              <UploadIcon sx={{ fontSize: 18, fill: 'white' }}/> {t('survey.buttons.submit_data')}
              </button>
            <button className="flex justify-center items-center bg-teal-600 mx-2 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1"
            onClick={() => handleValidateResponses()} style={{ cursor: 'pointer' }}>
              <CheckCircleOutlineIcon sx={{ fontSize: 18, fill: 'white' }}/> {t('survey.buttons.validate')}
            </button>
          </div>
      <div className="w-4/6 mx-8  sm:mx-auto mt-4">
      <div className="flex w-full rounded-lg gap-1 mb-1">
        <div className="w-250"></div>
        <div className="flex flex-col w-full">
          <ResponseStatistics responsesStatistics={responsesStatistics || null}/>
          {steps && <div className="flex flex-col w-full  bg-[#f5f4f0]  rounded-lg">
           <SimpleStepper steps={steps} changeStep={(activestep) => handlechangeStep(activestep)} />
           </div>
           }
        </div>
      </div>
      <div className="flex gap-1">
          <div className="flex bg-[#f5f4f0] rounded-lg w-250 h-700">
            <div className="p-2 flex flex-col gap-2 w-200 h-700 overflow-auto">
              {survey
                ? survey.map((survey, index) =>
                  <div key={index} onClick={() => setMainSurvey(survey)}>
                    <CapitalCardSurvey mainSurvey={mainSurvey || undefined} survey={survey} />
                  </div>)
                : undefined}
            </div>
          </div>
          <div className="flex bg-[#f5f4f0] rounded-lg">
            <div className={surveyAf.category[0] ? 'p-2 flex flex-col gap-2 w-250 h-700 overflow-auto' : ''}>
              {surveyAf.category
                ? sorted_categories.map((category, index) =>
                  <div key={index} onClick={() => surveyNavigate({ uuid: category.uuid, text: category.text, new: true })}>
                    <CapitalCard category={category} />
                  </div>)
                : undefined}
            </div>
          </div>
          <div className="w-full bg-[#f5f4f0] rounded-lg">
            {console.log(surveyAf)}
            {surveyAf.CategoryQuestions
              ? <Questions displayComments= {false} showComments={props.showComments}
              commentUser={props.commentUser} disabled={false} responses={responses} surveyQuestions={sortByOrder(surveyAf?.CategoryQuestions) || []} />
              : undefined}
          </div>
        </div>
      </div>
    </div>
    )
  }
}
AnswerSurvey.propTypes = {
  commentUser: PropTypes.string,
  showComments: PropTypes.string
}
export default AnswerSurvey
