import React from 'react'
import IndexAlert from '../../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function SelectEvaluationMethods ({ text, onChange, evaluations }) {
  const { t } = useTranslation()
  return (
    <>
      {evaluations
        ? <div className="md:w-2/3 w-3/5 md:mb-0 mt-1 relative rounded-sm">
          <label htmlFor="transformation_select" className="block text-lg font-medium text-gray-800">{t(text)}</label>
          <select id="countries_multiple" className="border bg-white border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-teal-500 focus:border-teal-500 block
            w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                  onChange={(e) => { onChange(e.target.value) }}>
                    <option value={null}></option>
                  { evaluations.map((method) =>
                    <option value={method.id} key={method.id}>{method.name}</option>
                  )}
                </select>
        </div>
        : <div className='w-3/5'>
          <IndexAlert type="warning" text={t('transformQuestion.evaluationMethod_missing')} />
        </div>}
    </>
  )
}
SelectEvaluationMethods.propTypes = {
  onChange: PropTypes.func
}
export default SelectEvaluationMethods
