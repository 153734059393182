import React from 'react'
import { useTranslation } from 'react-i18next'


function ClientData({onChangeEntity, entity}) {
    const { t } = useTranslation()
    return (
        <div>
            <h5 className="block text-xl font-medium text-gray-800 ">{t('admin.organisation.create.title.principle_client')}</h5>
            <div className="md:w-1/2 w-4/5 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm">
                <label
                    htmlFor="price"
                    className="block text-lg font-medium text-gray-800">
                    {t('admin.organisation.create.fields.prenom')}
                </label>
                <input
                    type="text" name="client_name" id="price"
                    className="focus:ring-teal-500 focus:border-teal-500 block w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm"
                    style={{ border: '1px solid #e8e6e6' }}
                    value={entity?.client?.first_name || ''}
                    onChange={(e) => onChangeEntity({ ...entity, client:{ ...entity.client, first_name: e.target.value } })}
                />
            </div>
            <div className="md:w-1/2 w-4/5 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm">
                <label
                    htmlFor="price"
                    className="block text-lg font-medium text-gray-800">
                    {t('admin.organisation.create.fields.name')}
                </label>
                <input
                    type="text" name="client_name" id="price"
                    className="focus:ring-teal-500 focus:border-teal-500 block w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm"
                    style={{ border: '1px solid #e8e6e6' }}
                    value={entity?.client?.last_name || ''}
                    onChange={(e) => onChangeEntity({ ...entity, client:{ ...entity.client, last_name: e.target.value } })}
                />
            </div>
            <div className="md:w-1/2 w-4/5 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm">
                <label
                    htmlFor="price" className="block text-lg font-medium text-gray-800"> {t('admin.organisation.create.fields.adresse_email')}
                </label>
                <input
                    type="email" 
                    name="client_name"
                    id="price"
                    className="focus:ring-teal-500 focus:border-teal-500 block w-full pl-2 pr-5 tsext-lg text-[#444444] rounded-sm"
                    style={{ border: '1px solid #e8e6e6' }}
                    value={entity?.client?.email || ''}
                    onChange={(e) => onChangeEntity({ ...entity, client: { ...entity.client, email: e.target.value } })}
                />
            </div>
        </div>
    )
}

export default ClientData
