import React from 'react'
import Header from './components/header'
import { useNavigate } from 'react-router-dom'
import IndexAlert from '../../components/alerts/indexAlert'
import { Spinner } from '../../components/loadData/spinner'
import { useRequestResetPasswordMutation } from '../../features/auth/authApiSlice'
import { useTranslation } from 'react-i18next'

export default function ConfirmforgotPassword () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [resetPassword, setResetPassword] = React.useState({ email: '' })
  const [requestResetPassword, { isSuccess, isLoading, isError, error }] = useRequestResetPasswordMutation()
  const handleRequestPassword = async () => {
    await requestResetPassword(resetPassword)
  }
  return (
    <section className="mt-100">
    <Header
    heading={t('login.reset_password.heading')}
    linkUrl="/signup" />
    <div className="flex justify-center ">
    <div className="space-y-4 w-400">
    <div className="flex justify-center">
    {isLoading && <Spinner/>}
    {isSuccess && <div className="flex flex-col"><IndexAlert type="success" text={t('login.reset_password.reset_success')}/>
    <bottom className="mt-1 p-1 px-2 text-white bg-[#086E7C] flex mx-auto" onClick={() => navigate('/auth/login')}
    style={{ cursor: 'pointer' }}>{t('login.login_button')}</bottom>
    </div>}
    {isError && <IndexAlert type="danger" text={JSON.stringify(error.data[Object.keys(error.data)[0]])}/>}
    </div>
    {isSuccess
      ? undefined
      : <>
      <div className="-space-y-px ">
      <div className="my-5 flex flex-col">
      <label htmlFor="email" className="text-[#444444] text-md font-semibold ">
      {t('login.email_address')}
      </label>
      <input
      onChange={(e) => setResetPassword({ email: e.target.value })}
      value={resetPassword.email}
      required={true}
      className="border-2 border-[#f7f7f7] rounded-sm pl-2 text-md h-8"
      placeholder={t('login.email_address')}
      />
      </div>
      </div>
      <div className="flex flex-col ">
      <bottom className="p-1 px-2 text-white bg-[#086E7C] flex mx-auto" onClick={() => handleRequestPassword()} style={{ cursor: 'pointer' }}>{t('login.reset_password.request_button')}</bottom>
      </div>
      </>
    }
    </div>
    </div>
    <div className="text-sm mt-4 flex justify-center">
    <bottom className="font-semibold text-md text-[#086E7C] hover:text-[#086E7C]" style={{ cursor: 'pointer' }} onClick={() => navigate('/auth/login')}>
    {t('login.reset_password.return_login')}
    </bottom>
    </div>
    </section>
  )
}
