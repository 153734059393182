import React from 'react'

const SelectOptionsGlobal = ({ onChange, data, value, onBlur }) => {
  return (
    <select className="focus:outline-none block text-xl h-8 w-1/3 pl-1 pr-2
    text-md text-[#444444] rounded-sm" value={value || ''}
    onBlur={() => onBlur()}
    onChange={(e) => onChange(e.target.value)}>
      <option></option>
      {data &&
        data.map((instance) =>
        <option value={instance.id} key={instance.id}>{instance.text}</option>)}
    </select>
  )
}

export default SelectOptionsGlobal
