import * as React from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'

export default function SimpleStepper (props) {
  const [activeStep, setActiveStep] = React.useState(props.steps.length - 1)

  React.useEffect(() => {
    setActiveStep(props.steps.length - 1)
  }, [props.steps])
  const handleStep = (step) => () => {
    setActiveStep(step)
    if (step < activeStep) {
      props.changeStep(step)
    }
  }

  return (
    <div className="bg-[#f5f4f0] rounded-lg p-4 w-full " style={{ border: '1px solid #ededed' }}>
      <Stepper nonLinear activeStep={activeStep} alternativeLabel >
        {props.steps.map((step, index) => (
          <Step key={index}>
            <StepButton onClick={handleStep(index)} disableRipple>
              {step.text}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}
