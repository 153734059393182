import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const fr = require('./languages/fr.json')
const en = require('./languages/en.json')

const resources = { en, fr }

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources
  })

export default i18n
