import React, { useRef } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import './style.css'
import BeatLoader from 'react-spinners/BeatLoader'
import { useUnblockAdminMutation } from '../usersSlice'
import { useNavigate } from 'react-router-dom'
import IndexAlert from '../../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'

const UnblockAdmin = ({ children, idAdmin }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [unBlockAdmin, { isLoading, isSuccess, isError, error }] = useUnblockAdminMutation()

  const HandleBlockUser = async () => {
    await unBlockAdmin(idAdmin)
  }

  const ref = useRef()
  const closeTooltip = () => {
    ref.current.close()
    navigate('/admin/users')
  }

  return (
    <div>
    <Popup
        closeOnDocumentClick={false}
        trigger={children}
        contentStyle={{ width: '350px', heigh: '300px' }}
        modal
        ref={ref}
    >
      {close => (
        <div className="modal">
            <div className="content flex flex-col justify-center items-center ">
              <h5 className="#444 text-lg ">{t('common.popup.unblockUser')}</h5>
                <div className="mt-2">
                   {isLoading && <BeatLoader color="#086e7c"/>}
                   {isSuccess && <div>
                    <IndexAlert type="success" text={t('admin.users.outputs.unblockUserSuccess')}/>
                  </div>}
                  {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)}/> </div>}
                </div>
            </div>
          <div className="actions mt-2 flex justify-center gap-2">
            <button onClick={() => closeTooltip()} className="bg-gray-200 text-gray-700 p-1 text-lg px-1 rounded-sm " style={{ cursor: 'pointer' }}>{t('common.buttons.Closed')}</button>
            {isSuccess || isError
              ? undefined
              : <button onClick={() => HandleBlockUser()} className="bg-teal-800 text-white p-1 text-lg px-1 rounded-sm" style={{ cursor: 'pointer' }}>{t('common.buttons.unBlock')}</button>}
          </div>
        </div>
      )}
    </Popup>
    </div>
  )
}
export default UnblockAdmin
