import React from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import './style.css'
import BeatLoader from 'react-spinners/BeatLoader'
import { useCreateEntityMutation } from '../entitiesSlice'
import { useNavigate } from 'react-router-dom'
import IndexAlert from '../../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'

const CreateEntity = ({ children, entity }) => {
  const navigate = useNavigate()
  const [createEntity, { isLoading, isSuccess, isError, error }] = useCreateEntityMutation()
  const { t } = useTranslation()
  const HandleAddEntity = async (e) => {
    await createEntity(entity)
  }

  const ref = React.useRef()
  const closeTooltip = () => {
    ref.current.close()
    if (isSuccess) {
      navigate('/admin/entity')
    }
  }
  return (
    <div>
    <Popup
        closeOnDocumentClick={false}
        trigger={children}
        contentStyle={{ width: '400px', heigh: '300px' }}
        onOpen={(e) => HandleAddEntity(e)}
        modal
        ref={ref}>
      {close => (
        <div className="modal">
            <div className="content flex flex-col justify-center items-center ">
                <h5 className="#444 text-lg ">{t('create_organisation')}</h5>
                <div className="mt-2">
                  {isLoading && <BeatLoader color="#086e7c"/>}
                  {isSuccess && <div className="flex flex-col gap-1">
                    <IndexAlert type="success" text={t('admin.organisation.create.output.success_organisation')}/>
                    <IndexAlert type="success" text={t('admin.organisation.create.output.email_sent')}/></div>}
                  {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)}/> </div>}
                </div>
            </div>
          <div className="actions">
          <button className="text-lg bg-gray-200 rouned-md px-2 py-0.5" onClick={() => closeTooltip()} style={{ cursor: 'pointer' }}>{t('close')}</button>
          </div>
        </div>
      )}
    </Popup>
    </div>
  )
}
export default CreateEntity
