import React from 'react'
import CheckBox from './checkBox'

const AddParams = ({ onChange, value, disabled }) => {
  return (
      <div className="form-check">
        <CheckBox text={'admin.survey.transformation_model.create.fields.showParameter'} disabled={disabled} value={value} onChange={(val) => onChange(val)}/>
      </div>
  )
}

export default AddParams
