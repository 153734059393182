import React from 'react'
import DynamicNavBar from '../../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions } from '../../DynamicNavbarOptions'
import ImportQuestion from './importQuestion'

function ImportIndex () {
  return (
    <div className="w-full pb-16">
      <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
      <DynamicNavBar option="navbar.surveyOptions.import_export" options={surveyOptions}/>
      <ImportQuestion/>
    </div>
  )
}

export default ImportIndex
