import React from 'react'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { useTranslation } from 'react-i18next'
import { FilterMatchMode } from 'primereact/api'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'
import { Dropdown } from 'primereact/dropdown'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import DeleteQuestion from './deleteQuestion'
import DeleteIcon from '@mui/icons-material/Delete'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'

function QuestionGeneralList ({ questionData }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        <RemoveRedEyeIcon onClick={() => navigate(`/admin/survey/question/${e.id}/details`)} sx={{ color: 'green', cursor: 'pointer' }}/>
        <DeleteQuestion id={e.id}>
            <DeleteIcon sx={{ color: 'red', cursor: 'pointer', fontSize: 25 }}/>
        </DeleteQuestion>
        <EditIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/admin/survey/question/${e.id}/edit`)}/>
      </div>
    )
  }
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    question_type: { value: null, matchMode: FilterMatchMode.EQUALS }
  })

  const [globalFilterValue, setGlobalFilterValue] = React.useState('')

  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const status = ['number', 'slider', 'select', 'boolean', 'text', 'multiselect']
  const statusItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>
  }
  const statusBodyTemplate = (rowData) => {
    return <span className={`customer-badge status-${rowData.question_type}`}>{rowData.question_type}</span>
  }

  const statusRowFilterTemplate = (options) => {
    return <Dropdown style={{ height: '35px' }} value={options.value} options={status} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate}
    placeholder="Selectionner un type" className="p-column-filter items-center w-5/6" showClear/>
  }
  const tableConfig = {
    value: questionData,
    stateKey: 'dt-state-questions-data-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    filterDisplay: 'row',
    dataKey: 'id',
    stateStorage: 'session',
    header,
    globalFilterFields: ['question_type', 'text', 'reference'],
    filters,
    emptyMessage: t('admin.survey.questions.question_list_notFound'),
    columns: [
      { field: 'reference', header: t('common.table.reference'), sortable: true, style: { width: '10%' } },
      {
        field: 'question_type',
        header: t('common.table.type'),
        sortable: true,
        style: { width: '5%' },
        showFilterMenu: false,
        body: statusBodyTemplate,
        filter: true,
        filterElement: statusRowFilterTemplate
      },
      { field: 'text', header: t('common.table.text'), sortable: true, style: { width: '85%' } },
      { body: actionTemplate, header: t('common.table.action') }
    ]
  }

  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300 ">
          {DataTableComponent}
        </div>
    </div>
  )
}
export default QuestionGeneralList

/*

*/
