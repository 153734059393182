import React from 'react'
import { FilterMatchMode } from 'primereact/api'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import DeleteSurvey from './deleteSurvey'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import IosShareIcon from '@mui/icons-material/IosShare'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'

function SurveyGeneralList ({ entityList }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        <EditIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/admin/survey/create/${e.id}`)}/>
        <DeleteSurvey id={e.id}>
          <DeleteIcon sx={{ color: '#f87171', cursor: 'pointer' }}/>
        </DeleteSurvey>
        <IosShareIcon sx={{ color: '#197BBF', cursor: 'pointer' }} onClick={() => navigate(`/admin/survey/export/${e.id}`)}/>
      </div>
    )
  }
  const tableConfig = {
    value: entityList,
    paginatorTemplate: template2,
    dataKey: 'id',
    stateKey: 'dt-state-survey-general-admin-list',
    onFilter: (e) => setFilters(e.filters),
    header,
    filters,
    globalFilterFields: ['title', 'description'],
    emptyMessage: t('admin.survey.surveys.tables.surveyGeneral_list_notFound'),
    columns: [
      { field: 'title', header: t('common.table.titre'), sortable: true, style: { width: '20%' } },
      { field: 'description', header: t('common.table.description'), style: { width: '60%' } },
      { body: actionTemplate, header: t('common.table.action') }
    ]
  }
  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300 ">
          {DataTableComponent}
        </div>
    </div>

  )
}
export default SurveyGeneralList
