import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const Input = ({ text, value, onBlur, onChange, type, disabled }) => {
  const { t } = useTranslation()
  return (
        <div className=''>
            <label className="form-check-label inline-block text-gray-800 text-lg " htmlFor="flexCheckChecked" style={{ cursor: 'pointer' }}>
                    {t(text)}
                </label>
                <input className="ml-2 form-number appearance-none block w-200 py-0.5 text-base font-normal text-gray-700
            bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
            focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
            value={value && value} onChange={(e) => onChange(e.target.value)}
            type={type} onBlur= {(e) => onBlur(e.target.value)} disabled={disabled} />
        </div>
  )
}
Input.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  type: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired
}
export default Input

// 'transformQuestion.titre'
