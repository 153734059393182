import React from 'react'
import BookmarkIcon from '@mui/icons-material/Bookmark'
function CapitalCardSurvey ({ mainSurvey, survey, text }) {
  return (
      <div className="bg-white rounded-md shadow-sm p-2 flex justify-between w-max-fit" style={{ cursor: 'pointer' }}>
        <div className="flex justify-between">
        <h5 className="text-[#444]">{text}</h5>
        {mainSurvey
          ? mainSurvey.id === survey.id
            ? <BookmarkIcon sx={{ color: '#2d9183' }}/>
            : <BookmarkIcon sx={{ color: '#5ECABA' }}/>
          : undefined}
            <h5 className="text-[#444444] text-md">{survey.name}</h5>

        </div>

      </div>
  )
}
export default CapitalCardSurvey
