import React, { useState } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import './style.css'
import { useTranslation } from 'react-i18next'

const CreateUser = ({ users, onChangeUsers, children }) => {
  const { t } = useTranslation()
  const ref = React.useRef()
  const closeTooltip = () => ref.current.close()
  const [user, setUser] = useState()

  const handleAddUser = (e) => {
    e.preventDefault()

    if (user?.first_name && user?.first_name && user?.email && user?.post) {
      if (!users.find((filter_user) => user.email === filter_user.email)) {
        onChangeUsers([...users, { ...user, id: users.length }])
        setUser({})
        closeTooltip()
      } else { alert("Erreur: l'utilisateur existe déjà") }
    }
  }

  return (
        <div >
            <Popup
                closeOnDocumentClick={false}
                trigger={children}
                modal
                contentStyle={{ width: '340px', heigh: '250px' }}
                ref={ref}>
                {close => (
                    <div className="modal">
                        <button className="close" onClick={() => close()}>
                            &times;
                        </button>
                        <div className="content flex flex-col justify-center items-center ">
                            <h5 className="text-teal-600 text-2xl ">{t('Ajouter membre')}</h5>
                            <form onSubmit={handleAddUser}>
                                <div className="mt-2">
                                    <div className="md:w-300 px-3 mt-1 relative rounded-sm ">
                                        <label htmlFor="price" className="block text-lg font-medium text-gray-700">
                                            {t('common.popup.last_name')}
                                        </label>
                                        <input type="text" name="last_name" id="last_name" className="focus:ring-teal-500
                    focus:border-teal-500 block  w-full text-lg  text-[#444444] pl-1
                    rounded-sm border-1 border-gray-300" placeholder={t('common.popup.last_name')} value={user?.last_name ? user.last_name : ''} onChange={(e) => setUser({ ...user, last_name: e.target.value })} />
                                        <label htmlFor="last_name" className="block text-lg font-medium text-gray-700">
                                            {t('common.popup.first_name')}
                                        </label>
                                        <input type="text" name="first_name" id="first_name" className="focus:ring-teal-500
                    focus:border-teal-500 block  w-full text-lg  text-[#444444] pl-1
                    rounded-sm border-1 border-gray-300" placeholder= {t('common.popup.first_name')} value={user?.first_name ? user.first_name : ''} onChange={(e) => setUser({ ...user, first_name: e.target.value })} />
                                        <label htmlFor="first_name" className="block text-lg font-medium text-gray-700">
                                            {t('common.popup.adresse_email')}
                                        </label>
                                        <input type="email" name="adresse_email" id="adresse_email" className="focus:ring-teal-500
                    focus:border-teal-500 block  w-full text-lg  text-[#444444] pl-1
                    rounded-sm border-1 border-gray-300" placeholder= {t('common.popup.adresse_email')} value={user?.email ? user.email : ''} onChange={(e) => setUser({ ...user, email: e.target.value })} />

                                        <label htmlFor="adresse_email" className="block text-lg font-medium text-gray-700">
                                            {t('common.popup.poste')}
                                        </label>
                                        <input type="text" name="poste" id="poste" className="focus:ring-teal-500
                        focus:border-teal-500 block  w-full text-lg  text-[#444444] pl-1
                        rounded-sm border-1 border-gray-300" placeholder={t('common.popup.poste')} value={user?.post ? user.post : ''} onChange={(e) => setUser({ ...user, post: e.target.value })} />
                                    </div>
                                </div>
                                <div className="actions mt-2 float-right">
                                    <input className="bg-[#086e7c] p-1 px-2 text-lg text-white rounded-sm font-medium" type="submit" style={{ cursor: 'pointer' }} value={t('common.popup.validate')} />
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </Popup>
        </div>
  )
}
export default CreateUser
