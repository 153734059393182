import React from 'react'
import Header from './components/header'
import { useNavigate } from 'react-router-dom'
import { useRequestResetPasswordMutation } from '../../features/auth/authApiSlice'
import IndexAlert from '../../components/alerts/indexAlert'
import { Spinner } from '../../components/loadData/spinner'
import { selectCurrentUser, logout } from '../../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

function FirstLogin () {
  const { t } = useTranslation()
  const [requestResetPassword, { isLoading, isSuccess, isError, error }] = useRequestResetPasswordMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(selectCurrentUser)
  const handelChangePassword = async (e) => {
    await requestResetPassword({ email: user.email })
  }
  const handleConnexion = (e) => {
    dispatch(logout())
  }

  return (
  <section className="mt-100 bg-white">
  <Header
      heading={t('login.first_login_heading')}
      paragraph={t('login.first_login_text')} />
  <div className="flex justify-center mt-3 ">

  <div className="flex flex-col mx-auto gap-2 space-y-4 w-500">
        <div>
            {isLoading && <Spinner/> }
            {isError && <IndexAlert type="danger" text={JSON.stringify(error.data[Object.keys(error.data)[0]])}/>}
            {isSuccess && <IndexAlert type="success" text={t('login.change_password_url_text')}/>}
        </div>
        <div className="flex gap-2 mt-4 max-w-fit mx-auto">
            {isSuccess
              ? <bottom className="p-1 px-2 text-white bg-[#086E7C] flex mx-auto" onClick={(e) => navigate('/auth/login')} style={{ cursor: 'pointer' }}>{t('login.login_button')}</bottom>
              : <>
                    <bottom className="p-1 px-2 text-white bg-gray-400 flex mx-auto" onClick={(e) => handleConnexion(e)} style={{ cursor: 'pointer' }}>{t('login.plusTard')}</bottom>
                    <bottom className="p-1 px-2 text-white bg-[#086E7C] flex mx-auto" onClick={(e) => handelChangePassword(e)} style={{ cursor: 'pointer' }}>{t('login.changePassword')}</bottom>
                </>
                }
        </div>
  </div>
  </div>
</section>
  )
}

export default FirstLogin
