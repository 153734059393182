import React from 'react'
import CreateCampaign from './createCampaign'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function FinalizeCampain ({ onChangeCampaign, campaign, onChangeStep }) {
  const { t } = useTranslation()
  const disable = !((campaign?.agreement?.terms && campaign?.agreement?.personal_Info))

  return (
  <section className="w-full mt-8 pb-5">
    <div className="flex flex-col w-full">
    <div>
    <h5 className="block font-medium text-gray-800
            text-lg pt-1">{t('consultant.campaign.create.validation_header')}</h5>
     <div className="overflow-auto h-200  border p-2 md:w-800  text-gray-700">
   {t('consultant.campaign.create.validation_text')}
  </div>
  <div className="p-8 pt-4">
    <div className="form-check">
      <input className="form-check-input border  text-white
      border-gray-300 rounded-sm bg-white checked:bg-teal-600 checked:border-teal-600
      focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain
      float-left mr-2 cursor-pointer" type="checkbox"
      checked={!!campaign?.agreement?.terms} id="flexCheckDefault"
      onChange={(e) => onChangeCampaign({
        ...campaign,
        agreement:
        { ...campaign.agreement, terms: e.target.checked }
      })}
        style={{ width: '19px', height: '19px' }}/>
      <label className="form-check-label inline-block text-gray-800 text-lg" htmlFor="flexCheckDefault" style={{ cursor: 'pointer' }}>
      {t('consultant.campaign.create.valid_1')}
      </label>
    </div>
    <div className="form-check">
      <input className="form-check-input border border-gray-300 rounded-sm
       bg-white checked:bg-teal-600 checked:border-teal-600 focus:outline-none transition duration-200 mt-1
       align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox"
       checked={!!campaign?.agreement?.personal_Info} id="flexCheckChecked"
       onChange={(e) => onChangeCampaign({
         ...campaign,
         agreement:
       { ...campaign.agreement, personal_Info: e.target.checked }
       })}
       style={{ width: '19px', height: '19px' }}/>
      <label className="form-check-label inline-block text-gray-800 text-lg" htmlFor="flexCheckChecked" style={{ cursor: 'pointer' }}>
      {t('consultant.campaign.create.valid_2')}
      </label>
    </div>
  </div>
</div>
</div>
      <div className="w-4/5">
        <CreateCampaign campaign={campaign}>
          <button className={!disable
            ? 'bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1'
            : 'bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1 opacity-50' } disabled={disable}>
            <h5 className="text-white  w-max-fit" >{t('common.buttons.finalize')}</h5>
          </button>
        </CreateCampaign>
        <button className="bg-gray-300 mx-2 px-2 py-0.5 rounded-md mb-1 text-md font-medium float-right p-1">
          <h5 className="text-[#444444]  w-max-fit" onClick={() => onChangeStep(2)}>{t('common.buttons.post')}</h5>
        </button>
      </div>
</section>
  )
}
FinalizeCampain.propTypes = {
  onChangeStep: PropTypes.func.isRequired,
  onChangeCampaign: PropTypes.func.isRequired,
  campaign: PropTypes.object
}

export default FinalizeCampain
