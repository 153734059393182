import React from 'react'
import { useTranslation } from 'react-i18next'

const InputNumber = ({ text, value, onBlur, onChange }) => {
  const { t } = useTranslation()
  return (
        <>
            <label className="form-check-label inline-block text-gray-800 text-lg" htmlFor="flexCheckChecked" style={{ cursor: 'pointer' }}>
                    {t(text)}
                </label>
                <input className="ml-2 form-number appearance-none block w-200 px-2 py-0.5 text-base font-normal text-gray-700
            bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
            focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1"
                    value={value || ''} onChange={(e) => onChange(e.target.value)}
                    max="9999" min="0" type="Number" onBlur= {() => onBlur()} />
        </>
  )
}

export default InputNumber

// 'transformQuestion.titre'
