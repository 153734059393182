import React from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import './style.css'
import { useEditEntityConsultantMutation } from '../../../../features/entities/entitiesSlice'
import BeatLoader from 'react-spinners/BeatLoader'
import { useNavigate } from 'react-router-dom'
import IndexAlert from '../../../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'

const EditEntityLoader = ({ children, entity }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [editEntityConsultant, { isLoading, isSuccess, isError, error }] = useEditEntityConsultantMutation()

  const ref = React.useRef()

  const closeTooltip = () => {
    ref.current.close()
    if (isSuccess) {
      navigate('/hub/entity/list')
    }
  }
  const HandleModify = async () => {
    await editEntityConsultant(entity)
  }

  return (
    <div>
    <Popup
        closeOnDocumentClick={false}
        trigger={children}
        onOpen={HandleModify}
        contentStyle={{ width: '350px', heigh: '300px' }}
        modal
        ref={ref}>
      {close => (
        <div className="modal">
            <div className="content flex flex-col justify-center items-center ">
                <h5 className="#444 text-lg">{t('common.popup.modify_organisation')}</h5>
                <div className="mt-5">
                  {isLoading && <BeatLoader color="#086e7c"/>}
                  {isSuccess && <IndexAlert type="success" text={t('common.popup.modifyOrgSuccess')}/>}
                  {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)}/> </div>}
                </div>
            </div>
          <div className="actions">
          <button onClick={() => closeTooltip()} className="bg-gray-300 px-2 p-1 text-lg rounded-sm" style={{ cursor: 'pointer' }}>{t('common.popup.close')}</button>
          </div>
        </div>
      )}
    </Popup>
    </div>
  )
}
export default EditEntityLoader
