import React from 'react'
import Alert from '@mui/material/Alert'
import PropTypes from 'prop-types'

function DangerAlert (props) {
  return (
    <Alert className="max-w-fit items-center text:md md:text-lg" severity="info">{props.text}</Alert>
  )
}

DangerAlert.propTypes = {
  text: PropTypes.string.isRequired
}

export default DangerAlert
