import React from 'react'
import { useGetUserCampaignResponseQuery } from '../../features/surveyCampaign/userCampaignResponseSlice'
import ResponseStatistics from '../../appComponents/questions/bar/responseStatistics'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'

function UserResponseState ({ userStats }) {

  const { campaignId } = useParams()
  const { t } = useTranslation()
  const { data } = useGetUserCampaignResponseQuery({ idCampaign: campaignId, idUser: userStats?.id || undefined })
  if (data) {
    return (
    <div className='mt-2'>
      <h5 className="text-xl font-medium text-[#444444] ">{t('survey.states.user_statistics')}</h5>
      <div className='mt-2 px-2 flex flex-col gap-2'>
          <div className='flex gap-2'>
            <h5 className="text-md font-medium text-[#444444] ">{t('survey.states.email_address')}</h5>
            <h5 className="text-sm font-medium text-gray-500 pt-1 ">({userStats?.email})</h5>
        </div>
        <div className='flex gap-2'>
            <h5 className="text-md font-medium text-[#444444] ">{t('survey.states.submitted_campaigns')}</h5>
            <h5 className="text-sm font-medium text-[#444444] ">{data?.submmitted_state ? <VerifiedUserIcon style={{ fill: '#12B64E' }}/> : <UnpublishedIcon style={{ fill: '#E44526' }} /> }</h5>
        </div>
        {data?.statistics
          ? <ResponseStatistics responsesStatistics={data?.statistics || {}} />
          : <h5 className="text-sm font-medium text-gray-500 pt-1 ">{t('survey.states.noStats')}</h5>
      }
      </div>
    </div>
    )
  }
}

export default UserResponseState
