import React from 'react'
import valueanco from '../../../assets/valueanco.png'
export default function Header ({
  heading,
  paragraph,
  linkName,
  linkUrl = '#'
}) {
  return (
        <div className="mb-1">
            <div className="flex justify-center">
                <img
                    alt=""
                    className="h-16 w-54"
                    src={valueanco}/>
            </div>
            <h2 className="mt-2 text-center text-3xl font-medium text-[#444444]">
                {heading}
            </h2>

        </div>
  )
}
