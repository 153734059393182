import React from 'react'
import { useTranslation } from 'react-i18next'

const TextArea = ({ placeholder, text, value, onChange, rows, disabled, onBlur }) => {
  const { t } = useTranslation()
  return (
    <>
      <label htmlFor="price" className="block font-medium text-gray-700 text-lg">
                {t(text)}
              </label>
              <textarea type="text" name="price" id="price" className="focus:ring-teal-500
              focus:border-teal-500 block  w-full pl-2 pr-12 text-md  text-[#444444]
              rounded-sm" style={{ border: '1px solid #e8e6e6' }} rows={rows}
              placeholder={t(placeholder)} value={value || ''} disabled={disabled}
                onChange={(e) => onChange(e.target.value)}
                onBlur = {() => onBlur()}></textarea>
    </>
  )
}

export default TextArea
