import React from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import IndexEntity from './entity/indexEntity'
import NavBar from '../../components/Bars/navBar'
import IndexUser from './users/indexUser'
import RequireAuth from '../../layouts/auth/RequireAuth'
import IndexSettings from './settings/indexSettings'
import IndexSurvey from './survey/indexSurvey'
function IndexAdmin () {
  return (
    <section className="bg-gray-50 w-full min-h-screen">
      <NavBar/>
        <Outlet/>
        <Routes>
            <Route path="entity/*" element={<IndexEntity/>}></Route>
            <Route path="survey/*" element={<IndexSurvey/>}></Route>
              <Route path="settings/*" element={<IndexSettings/>}></Route>
            <Route element={<RequireAuth allowedRole={['SUPERADMIN']}/>}>
              <Route path="users/*" element={<IndexUser/>}></Route>
            </Route>
        </Routes>
    </section>
  )
}

export default IndexAdmin
