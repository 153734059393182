import React from 'react'
import { FilterMatchMode } from 'primereact/api'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'

function TransformHistorics ({ historics }) {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: historics,
    stateKey: 'dt-state-transdorm-history-hub-list',
    onFilter: (e) => setFilters(e.filters),
    sortField: 'reference',
    sortOrder: 1,
    rowGroupMode: 'rowspan',
    groupRowsBy: ['question_uuid', 'reference', 'text'],
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    header,
    filters,
    globalFilterFields: ['input_unit', 'output_unit', 'function_value', 'text', 'reference', 'function_type'],
    emptyMessage: t('consultant.evaluation.result_not_found'),
    columns: [
      { field: 'reference', sortable: true, header: t('consultant.evaluation.historic.reference'), style: { width: '7%' } },
      { field: 'text', sortable: true, header: t('consultant.evaluation.historic.text'), style: { width: '23%' } },
      { field: 'input_unit', sortable: true, header: t('consultant.evaluation.historic.input_unit') },
      { field: 'function_value', sortable: true, header: t('consultant.evaluation.historic.function_value') },
      { field: 'output_unit', sortable: true, header: t('consultant.evaluation.historic.output_unit') },
      { field: 'input_value', sortable: true, header: t('consultant.evaluation.historic.input_value') },
      { field: 'output_value', sortable: true, header: t('consultant.evaluation.historic.output_value') },
      { field: 'function_type', sortable: true, header: t('consultant.evaluation.historic.function_type') },
      { field: 'denominator', sortable: true, header: t('consultant.evaluation.historic.denominator') },
      { field: 'last_denominator', sortable: true, header: t('consultant.evaluation.historic.last_denominator') }
    ]
  }
  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div className="border-1 border-gray-200 ">
      {DataTableComponent}
      </div>
  )
}
TransformHistorics.propTypes = {
  historics: PropTypes.object
}
export default TransformHistorics
