import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { useTranslation } from 'react-i18next'
import { FilterMatchMode } from 'primereact/api'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'

function

ExportedSurveyHistoryList ({ exportedSurveys }) {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    mode: { value: null, matchMode: FilterMatchMode.EQUALS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  // const navigate = useNavigate()
  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        {/* <RemoveRedEyeIcon onClick={()=> navigate(`/admin/survey/question/${e.id}/details`)} sx={{ color: 'green', cursor: 'pointer' }}/>
        <EditIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/admin/survey/question/${e.id}/edit`)}/> */}
      </div>
    )
  }

  const statuses = [
    'Création', 'Modification'
  ]
  const statusItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>
  }
  const statusBodyTemplate = (rowData) => {
    return <span className={`customer-badge status-${rowData.mode}`}>{t(`admin.survey.exported_surveys.mode.${rowData.mode}`)}</span>
  }

  const statusRowFilterTemplate = (options) => {
    return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate}
    placeholder="Select a Status" className="p-column-filter" showClear />
  }

  const tableConfig = {
    value: exportedSurveys,
    stateKey: 'dt-state-exported-survey-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    size: 'small',
    dataKey: 'id',
    filters,
    header,
    globalFilterFields: ['name', 'created_at', 'mode'],
    filterDisplay: 'row',
    emptyMessage: t('admin.survey.exported_surveys.used_exportedsurvey_listNotFound'),
    columns: [
      { field: 'name', header: t('common.table.titre'), sortable: true, style: { width: '20%' } },
      { field: 'created_at', header: t('common.table.creation_date'), sortable: true, style: { width: '30%' } },
      {
        field: 'mode',
        sortable: true,
        header: t('common.table.mode'),
        showFilterMenu: false,
        filterMenuStyle: { width: '8rem' },
        style: { width: '30%' },
        body: statusBodyTemplate,
        filter: true,
        filterElement: statusRowFilterTemplate
      },
      { body: actionTemplate, header: t('common.table.action') }
    ]
  }

  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300 ">
          {DataTableComponent}
        </div>
    </div>
  )
}
export default ExportedSurveyHistoryList
