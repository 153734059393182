import React from 'react'
import IndexAlert from '../../../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function SelectCampaign ({ text, onChange, campaigns }) {
  const { t } = useTranslation()
  return (
    <>
      {campaigns
        ? <div className="md:w-2/3 w-3/5 md:mb-0 mt-1 relative rounded-sm">
          <label htmlFor="transformation_select" className="block text-lg font-medium text-gray-800">{text}</label>
          <select id="transformation_select" className="border bg-white border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-teal-500 focus:border-teal-500 block
            w-max-fit p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
            onChange={(e) => { onChange(e.target.value) }}>
            <option></option>
            {Object.values(campaigns.entities).map((campaign) =>
              <option value={campaign.id} key={campaign.id}>{campaign.name}</option>
            )}
          </select>
        </div>
        : <div className='w-3/5'>
          <IndexAlert type="warning" text={t('consultant.materiality_matrix.create.campaign_missing')} />
        </div>}

    </>
  )
}
SelectCampaign.propTypes = {
  onChange: PropTypes.func,
  campaigns: PropTypes.object,
  text: PropTypes.string
}
export default SelectCampaign
