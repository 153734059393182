export function getSurveys (surveys) {
  const roots = []
  surveys.map((survey) => roots.push({ id: survey.id, name: survey.name }))
  return roots
}

export function getIdRoot (survey) {
  const rootsurvey = survey.survey_tree.filter((survey) => survey.type === 'root')
  return rootsurvey[0].uuid
}
export function CategoryStart (surveys) {
  const rootCategory = []
  surveys.map((survey) => {
    rootCategory.push({ name: survey.name, uuid: survey.id, id: getIdRoot(survey.survey_tree) })
  })
  return rootCategory
}

export function Category (survey, rootId) {
  const res = survey.survey_tree.filter((survey) => survey.type === 'category' && survey.parent == rootId)
  return res
}
export function getCategory (survey, rootId) {
  const res = survey.survey_tree.filter((survey) => survey.uuid === rootId)
  return res
}
export function CategoryQuestions (survey, rootId) {
  const res = survey.survey_tree.filter((survey) => survey.type === 'question' && survey.parent === rootId)
  return res
}
