import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const UnitSelect = ({ text, value, onChange, data, onBlur, disabled }) => {
  const { t } = useTranslation()
  return (
    <div>
        <h5 htmlFor="price" className="form-check-label inline-block text-gray-800 text-lg">
              {t(text)}
            </h5>
            <select className="focus:ring-teal-500 focus:border-teal-500 block border-1 text-xl border-[#e8e6e6] h-8 w-full pl-1 pr-2 text-md  text-[#444444]
            rounded-sm" value={value} disabled= {disabled}
              onChange={(e) => onChange(e.target.value)}
              onBlur={(e) => onBlur(e.target.value)}>
              <option></option>
              {data &&
                 data.map((instance, index) => <option value={instance[0]} key={index}>{instance[0]} - {instance[1]}</option>)}
            </select>
    </div>
  )
}
UnitSelect.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.object,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool.isRequired
}
export default UnitSelect
