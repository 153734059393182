import axios from 'axios'
import { baseURL } from '../../../../var.conf.js'


export const GetEvaluationExcelFile = async (id) => {
    try {
      const response = await axios({
        url: baseURL + `/evaluation/export/${id}`,
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download','file.xlsx')
        document.body.appendChild(link)
        link.click()
      })
      return response.json()
    } catch (error) {
    }
  }