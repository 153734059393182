import React from 'react'
import { useGetChoicesListQuery } from '../../../features/questions/questionSlice'
import CheckBox from '../../../layouts/admin/survey/utils/checkBox'
import { useTranslation } from 'react-i18next'
import UnitSelect from '../utils/unitSelect'
import Input from '../utils/input'
import PropTypes from 'prop-types'

const ShowAdditionalParams = ({ question, ChangeQuestion }) => {
  const { t } = useTranslation()
  const { data: choicesList } = useGetChoicesListQuery()
  return (
        <>
            {['slider', 'number'].includes(question.question_type) &&
                <div className='md:w-1/2'>
                    <CheckBox text="question.show_additional_params"
                        value={question?.showParams ? question.showParams : false}
                        onChange={(val) => ChangeQuestion({ ...question, showParams: val })} disabled={false} />
                    {question?.showParams &&
                        <>
                            <UnitSelect text="question.select_unit" value={question?.unit ? question.unit : null}
                                 data={choicesList || []} disabled={true} />
                            {question.question_type === 'number' &&
                                <>
                                    {question?.unit === 'fraction' &&
                                        <Input text="question.additional_denominator" type="Number"
                                            value={question?.params?.denominator ? question?.params.denominator : null}
                                            disabled={true} />
                                    }
                                    <div className='flex pt-2 gap-2'>
                                        <Input text="question.number_min" type="Number"
                                            value={question?.params?.min ? question?.params.min : null}
                                            disabled={true}/>
                                        <Input text="question.number_max" type="Number"
                                            value={question?.params?.max ? question?.params.max : null}
                                            disabled={true} />
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>}
        </>
  )
}
ShowAdditionalParams.propTypes = {
  question: PropTypes.object,
  ChangeQuestion: PropTypes.func
}
export default ShowAdditionalParams
