import React from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import IndexCampaigns from './campaign/indexCampaigns'
import NavBar from '../../components/Bars/navBar'
import EntityList from './entity/entityList'
import IndexEvaluation from './evaluation/indexEvaluation'
import NotFound from '../../layouts/auth/notFound'
import EditEntity from './entity/EditEntity'
import EntityListAdmin from './entity/entityListAdmin'
import CheckAdminList from './entity/CheckAdminList'
import { generalOptions } from './DynamicNavbarOptions'
import DynamicNavBar from '../../components/Bars/DynamicNavBar'
import { selectCurrentUser } from '../../features/auth/authSlice'
import { useSelector } from 'react-redux'
import IndexMaterialityMatrix from './evaluationMatrix/IndexMaterialityMatrix'

function IndexHub () {
  const user = useSelector(selectCurrentUser)
  return (
    <section className="bg-gray-50 min-h-screen w-full mx-auto items-center">
        <NavBar/>
        {['ADMIN', 'SUPERADMIN'].includes(user.type) &&
        <DynamicNavBar option="navbar.generalOptions.user_view" options={generalOptions}/>}
            <Outlet/>
            <Routes>
                  <Route path="entity/:idEntity/">
                    <Route path="campaigns/*" element={<IndexCampaigns/>}/>
                    <Route path="evaluation/*" element={<IndexEvaluation/>}/>
                    <Route path="materiality_matrix/*" element={<IndexMaterialityMatrix/>}/>
                    <Route path="details" element={<IndexCampaigns/>}/>
                    <Route path="edit" element={<EditEntity/>}></Route>
                  </Route>
                  <Route element={<CheckAdminList allowedRole={['SUPERADMIN', 'ADMIN']}/>}>
                    <Route path="entity/list" element={<EntityList/>}/>
                  </Route>
                    <Route path="entity/list/admin" element={<EntityListAdmin/>}/>
                  <Route path="*" element={<NotFound/>}/>
            </Routes>
    </section>
  )
}

export default IndexHub

// <Route path="" element={<DashboadEntity/>}></Route>
