import React from 'react'
import valueanco from '../../assets/valueanco.png'
import { logout, selectCurrentUser } from '../../features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DropDownLanguage from './dropDownLanguage'

function NavBar () {
  const user = useSelector(selectCurrentUser)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const logoutUser = () => {
    dispatch(logout())
    navigate('/auth/login')
  }
  
  return (
    <div className="h-10 bg-[#24292F] w-full">
      <div className="h-full  w-4/5 flex justify-between mx-auto">
        <img src={valueanco} alt="valueAndCo logo" className="h-8 w-22 pt-1 pl-2" />
        <div className="mt-2 flex gap-2 text-white">{user ? t('common.navbar.login.logged_as') + ' : ' + user.email : '' } ({t("common.users.type."+user.type)})</div>
        <div className="mt-2 flex gap-2">
          <DropDownLanguage/>
          <div className="text-md pt-1 text-white" style={{ cursor: 'pointer' }} onClick={() => logoutUser()}>{t('login.logout')}</div>
        </div>
      </div>
    </div>
  )
}

export default NavBar
