import React from 'react'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { useTranslation } from 'react-i18next'
import { FilterMatchMode } from 'primereact/api'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'

const ImportSurveyTree = ({ data }) => {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))
  const tableConfig = {
    value: data ,
    stateKey: 'dt-state-import-survey-tree-list',
    onFilter: (e) => setFilters(e.filters),
    header,
    globalFilterFields: ['text', 'reponse.text'],
    filters,
    style: { fontSize: '13px' },
    dataKey: 'type',
    emptyMessage: t('admin.survey.import_export.surveyTree_list_notFound'),
    columns: [
      { field: 'SURVEY_UUID', sortable: true, header: t('admin.survey.import_export.SURVEY_UUID') },
      { field: 'SURVEY_NAME', sortable: true, header: t('admin.survey.import_export.SURVEY_NAME') },
      { field: 'NODE_TYPE', sortable: true, header: t('admin.survey.import_export.NODE_TYPE') },
      { field: 'NODE_UUID', sortable: true, header: t('admin.survey.import_export.NODE_UUID') },
      { field: 'NODE_TEXT', sortable: true, header: t('admin.survey.import_export.NODE_TEXT') },
      { field: 'ORDER', sortable: true, header: t('admin.survey.import_export.ORDER') },
      { field: 'LEVEL', sortable: true, header: t('admin.survey.import_export.LEVEL') },
      { field: 'LINK_UUID', sortable: true, header: t('admin.survey.import_export.LINK_UUID') },
      { field: 'LINK_TEXT', sortable: true, header: t('admin.survey.import_export.LINK_TEXT') },
      { field: 'PARENT_UUID', sortable: true, header: t('admin.survey.import_export.PARENT_UUID') },
      { field: 'PARENT_TEXT', sortable: true, header: t('admin.survey.import_export.PARENT_TEXT') }]
  }
  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div className="datatable-rowgroup-demo max-h-500 overflow-auto border-1 border-gray-200 mb-2">
      {DataTableComponent}
    </div>
  )
}
export default ImportSurveyTree
