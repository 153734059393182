import React from 'react'
import ActivitySectorTable from './table/activitySectorTable'
import { useGetActivitiesQuery, selectAllActivities } from '../../../features/activitiesSector/activitiesSlice'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, ActivityOptions } from '../DynamicNavbarOptions'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

function EntityList () {
  const [activitySector, SetActivity] = React.useState()

  const { data } = useGetActivitiesQuery()
  const activivitySectors = useSelector(selectAllActivities)

  const { t } = useTranslation()
  return (
    <div className="w-full pb-16">
    <DynamicNavBar option="navbar.parametersOptions.settings" options={generalOptions}/>
      <DynamicNavBar option="navbar.ActivityOptions.activity_sector" options={ActivityOptions}/>
      <div className="w-full flex flex-col items-center gap-8  static mt-4" >
        <div className=" md:w-2/3 lg:w-4/6 sm:w-2/3 bg-wite md:mx-8 pb-8">
          <h5 className="text-2xl font-medium  text-[#444444] w-max-fit">{t('admin.settings.activity_sector.title.activity_sector')}</h5>
            <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4">
                <div>
                    <ActivitySectorTable ChangeActivity={SetActivity} activities={activivitySectors || []}/>
                </div>
                {activitySector &&
                <div className="flex flex-col ">
                    <h5 className="text-lg font-medium  text-[#444444] w-max-fit">{t('admin.settings.activity_sector.title.activity_sector')}</h5>
                    <h5 className="text-lg  text-[#444444] w-max-fit pl-2">{activitySector.name}</h5>
                    <h5 className="text-lg font-medium  text-[#444444] w-max-fit">{t('common.table.description')}</h5>
                    <p className="text-lg  bg-gray-100 p-2 rounded-md text-[#444444] w-800 md:w-1/2 pl-2">{activitySector.description}</p>
                </div>
                }
            </div>
        </div>
      </div>
    </div>
  )
}

export default EntityList
