import React from 'react'
import catUnauthorized from '../../assets/cat_unauthorized.png'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
function Unauthorized () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <div className="h-screen w-screen bg-gray-100 flex items-center">
        <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
          <div className="max-w-md">
            <div className="text-3xl font-dark font-bold">401 {t('unauthorized.actionUnauthorized')}</div>
            <p className="text-xl md:text-3xl font-light leading-normal">{t('unauthorized.notAllowed')} </p>
            <p className="text-md mb-4">{t('unauthorized.redirectNotFound')}</p>
            <bottom className="px-2 inline py-1 text-md font-medium text-white   border border-transparent
    rounded-lg bg-teal-600 active:bg-teal-600
    hover:bg-teal-700" style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>{t('unauthorized.backWelcome')}</bottom>
          </div>
          <div className="max-w-lg">
            <img src={catUnauthorized} alt="unauthorized_picture" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Unauthorized
