import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { DataTableBuilder } from '@valueandco/vcdesign/packages/datatable-library/src/builders/DataTableBuilder'
import { TableDirector } from '@valueandco/vcdesign/packages/datatable-library/src/directors/TableDirector'

// Your builder classes and director here...
export function createDataTable (config) {
  const dataTableBuilder = new DataTableBuilder()
  const tableDirector = new TableDirector(dataTableBuilder)

  const { dataTable, columns } = tableDirector.constructTable(config, config.columns)
  return (
    <DataTable {...dataTable} >
        {columns.map((column, index) =>
          (<Column key={index} {...column} ></Column>))
    }
    </DataTable>
  )
}