import React from 'react'
import { useGetEntitySurveysQuery } from '../../surveys/surveysSlice'
import DualboxSurvey from '../components/dualboxSurvey'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Today } from '@mui/icons-material'
function GenralInfo ({ onChangeCampaign, campaign, onChangeStep }) {
  const { idEntity } = useParams()
  const { t } = useTranslation()

  let now = new Date();
  let year = now.getFullYear();
  let month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
  let day = String(now.getDate()).padStart(2, '0');
  let hours = String(now.getHours()).padStart(2, '0');
  let minutes = String(now.getMinutes()).padStart(2, '0');

  let dateTimeString = `${year}${month}${day}-${hours}${minutes}-campaign`
  if (!campaign.name)
    campaign.name = dateTimeString
  campaign.time_deadLine = new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0]

// Now you can use dateTimeString where you need it

  const { data: surveys, isSuccess } = useGetEntitySurveysQuery(idEntity)
  return (
    <section>
      <div className="flex flex-col  flex-wrap gap-1 mt-4">
        <div className="md:w-1/3 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
          <label
            htmlFor="price"
            className="block text-lg font-medium text-gray-800 "
          >
            {t('consultant.campaign.create.campaignName')}
          </label>
          <input
              type="text"
              name="price"
              id="price"
              className="focus:ring-teal-500
              focus:border-teal-500 block  w-full py-0.5 pl-2 pr-12 text-md  text-[#444444]
              rounded-sm border-1 border-gray-300"
              value={campaign?.name ? campaign.name : dateTimeString}
              onChange={(e) =>
                onChangeCampaign({ ...campaign, name: e.target.value })
              }
            />
        </div>
        <div className="md:w-2/3 px-3 mt-1 relative rounded-sm ">
          {isSuccess &&
          <>
            <label htmlFor="price" className="block text-lg  font-medium text-gray-800">
              {t('consultant.campaign.create.survey')}
            </label>
            <DualboxSurvey surveys={surveys} onChangeCampaign={onChangeCampaign} campaign={campaign}/>
          </>}

        </div>

        <div className="md:w-1/3 px-3 mt-1 relative rounded-sm ">
          <label
            htmlFor="price"
            className="block text-lg  font-medium text-gray-800"
          >
            {t('consultant.campaign.create.deadLine')}
          </label>
          <input
            type="date"
            name="price"
            id="price"
            placeholder={t('common.date_format')}
            className="focus:ring-teal-500
          focus:border-teal-500 block w-full py-0.5 pl-2 text-md  text-[#444444]
          rounded-sm border-1 border-gray-300"
            value={campaign?.time_deadLine ? campaign.time_deadLine : //today + 1 month 
              new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0]}
            onChange={(e) =>
              onChangeCampaign({ ...campaign, time_deadLine: e.target.value })
            }
          />
        </div>
        <div className="md:w-2/3 px-3 mt-1 relative rounded-sm ">
          <label
            htmlFor="price"
            className="block text-lg font-medium text-gray-800"
          >
            {t('consultant.campaign.create.description')}
          </label>
          <textarea
            rows="5"
            name="price"
            id="price"
            className="focus:ring-teal-500
          focus:border-teal-500 block  w-full py-0.5 pl-2 pr-2 text-md  text-[#444444]
          rounded-sm border-1 border-gray-300"
            value={campaign?.description ? campaign.description : ''}
            onChange={(e) =>
              onChangeCampaign({ ...campaign, description: e.target.value })
            }
          ></textarea>
        </div>
        <div className="w-4/5">
          <button className="bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1">
            <h5 className="text-white  w-max-fit" onClick={(e) => onChangeStep(2)}>
              {t('common.buttons.next')}
            </h5>
          </button>
        </div>
      </div>
    </section>
  )
}
GenralInfo.propTypes = {
  onChangeStep: PropTypes.func,
  onChangeCampaign: PropTypes.func,
  campaign: PropTypes.object
}

export default GenralInfo
