// TODO : create a component for the following snippet and reuse
// in both src/layouts/admin/entity/editEntity/setMembersList.js
// and src/layouts/hub/entity/editEntity/setMembersList.js


import React, { useState, useRef } from 'react'
import DataTableImportUserList from './DataTableImportUserList'
import { read, utils, writeFile } from 'xlsx'
import CreateUser from './createUser'
import { mockData } from './setMemberMockData'
import AddIcon from '@mui/icons-material/Add'
import ReplayIcon from '@mui/icons-material/Replay'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'

function SetMembersList ({ onChangeEntity, entity, members }) {
  const { t } = useTranslation()
  const [selectedData, setSelectedData] = useState(null)
  const [file, setFile] = React.useState()
  const [users, setUsers] = useState({})
  const fileRef = useRef()
  const DownloadTemplate = () => {
    const wb = utils.book_new(); const ws = utils.json_to_sheet(mockData)
    utils.book_append_sheet(wb, ws, 'Liste_employee')
    writeFile(wb, 'entity_member_template.xlsx')
  }
  const handleImport = (e) => {
    const files = e.target.files
    if (files.length) {
      const file = files[0]
      const reader = new FileReader()
      setFile(file)
      reader.onload = (event) => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames
        if (sheets.length) {
          const import_rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
          const users_row = [...entity.users, ...import_rows]
          let rows = [...new Set(users_row)]
          rows = rows.map((row) => row = { ...row, id: rows.indexOf(row) })
          onChangeEntity({ ...entity, users: rows })
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }
  const clearTable = () => {
    fileRef.current.value = ''
    onChangeEntity({ entity, users: members })
  }
  return (
    <section className="p-2">
     <div className="flex justify-between">
     <div>
          <h5 className="block text-xl font-medium text-gray-800 ">{t('admin.organisation.create.fields.importListEmpl')}</h5>
          <div className="flex max-h-fit pt-1">
            <label htmlFor="inputGroupFile" className="bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1">{t('common.buttons.choose_file')}</label>
            <div>
              <span className="text-gray-700 ml-1">{file?.name || t('common.no_file_selected')}</span>
            </div>
            <input type="file" name="file" ref={fileRef} className="opacity-0 absolute"
              id="inputGroupFile" required onChange={(e) => handleImport(e)}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        </div>
        <div>
         <h5 className="block text-lg font-medium text-gray-800" style={{ cursor: 'pointer' }} onClick={DownloadTemplate}>{t('consultant.organisation.edit.downloadForm')}
          <DownloadForOfflineIcon sx={{ color: '#444' }}/></h5>
        </div>
  </div>
         <h5 className="block text-lg font-medium text-gray-800 ">{t('components.tables.entity.member_list.title')}</h5>
      <div className="flex flex-col  flex-wrap gap-2 mt-2">
        <div className="md:w-full w-3/5   px-3 mb-6 md:mb-0 mt-1 relative rounded-sm  gap-8">
        <div className="flex gap-2 min-w-fit ">
          <button className="border-2 border-gray-300 rounded-md min-w-fit flex p-0.5 bg-white mb-1" style={{ cursor: 'pointer' }}
              onClick={() => onChangeEntity({ ...entity, users: entity.users.filter((user) => !selectedData.includes(user)) })}>
              <DeleteIcon sx={{ color: 'red', cursor: 'pointer' }}/>
              <h5 className="text-md font-medium px-1">{t('common.delete')} {selectedData > 0 ? selectedData.length + 'items' : undefined}</h5>
          </button>
          <CreateUser members={entity?.users ? entity.users : []} entity={entity} onChangeMembers={onChangeEntity}>
            <button className="border-2 border-gray-300 rounded-md min-w-fit flex p-0.5 bg-white mb-1" style={{ cursor: 'pointer' }}>
              <AddIcon sx={{ color: 'green' }}/>
              <h5 className="text-md font-medium px-1">{t('common.add')}</h5>
            </button>
          </CreateUser>
          {users.length !== 0 &&
            <button className="border-2 border-gray-300 rounded-md min-w-fit flex p-0.5 bg-white mb-1" style={{ cursor: 'pointer' }} onClick={() => clearTable()}>
              <ReplayIcon sx={{ color: '#4682B4' }}/>
              <h5 className="text-md font-medium px-1">{t('common.reset')}</h5>
            </button>}
          </div>
         <DataTableImportUserList setSelectedData={setSelectedData} selectedData={selectedData} usersList={entity?.users ? entity.users : []} default_users={members} entity={entity} onChangeEntity={onChangeEntity} changeUserList={setUsers}/>
        </div>
      </div>
    </section>
  )
}
export default SetMembersList
