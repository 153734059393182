import React from 'react'
import Questions from '../../../components/survey/questions'
import SimpleStepper from './simpleStepper'
import { useGetsurveyCampaignQuery } from '../../../features/surveyCampaign/surveyCampaignsSlice'
import { getCategoryWichHasQuestions } from './surveyformatter'
import { useGetResponsesQuery, useGetResponsesStatisticsQuery } from '../../../features/response/responsesSlice'
import IndexAlert from '../../../components/alerts/indexAlert'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { getUser } from './getUser' 
import { useTranslation } from 'react-i18next'
import { selectCurrentUser } from '../../../features/auth/authSlice'
import { useSelector } from 'react-redux'
import ResponseStatistics from '../../.././appComponents/questions/bar/responseStatistics'

function UserAnswers ({ userType }) {
  const { idCampaign, idUser } = useParams()
  let [survey, setSurvey] = React.useState()
  const { t } = useTranslation()
  const user = useSelector(selectCurrentUser)
  let [stats, setStats] = React.useState({ questions: 0, reponses: 0 })
  const [activeStep, SetActiveStep] = React.useState(0)
  const { data: surveyCampaign, isSuccess: isSuccessCampaign } = useGetsurveyCampaignQuery(idCampaign)
  const [categories, setCategories] = React.useState([])
  const { data: responses } = useGetResponsesQuery({ campaign: idCampaign, user: idUser })
  const { data: responsesStatistics } = useGetResponsesStatisticsQuery({ campaign: idCampaign, user: idUser })
  const [commentUser, setCommentUser] = React.useState(idUser)

  React.useEffect(() => {
    const selectedUser = getUser(userType, user?.id ? user.id : undefined, idUser)
    setCommentUser(selectedUser)
  }, [])

  React.useEffect(() => {
    if (isSuccessCampaign && surveyCampaign) {
      setSurvey(survey = surveyCampaign.survey_tree)
      const categories = getCategoryWichHasQuestions(survey)
      if (categories) {
        setCategories(categories)
      }
    }
  }, [isSuccessCampaign, surveyCampaign])

  const handleStep = (stp) => {
    SetActiveStep(stp)
  }

  React.useEffect(() => {
    if (categories && responses) {
      const nbrReponse = responses.length
      let nbrQuestions = 0
      categories.map((category) => { nbrQuestions += category.questions.length })

      setStats(stats = { ...stats, reponses: nbrReponse })
      setStats(stats = { ...stats, questions: nbrQuestions })
    }
  }, [categories, responses])

  const showStats = (reponse, questions) => {
    if (reponse / questions <= 0.25) {
      return <spam className="bg-red-200 text-xl text-gray-800 font-medium px-2 w-max-16 rounded-md py-0.5 ">{reponse}/ {questions}</spam>
    }
    if ((reponse / questions > 0.25) && (reponse / questions < 0.85)) {
      return <spam className="bg-yellow-200 text-xl text-gray-800 font-medium px-2 w-max-16 rounded-md py-0.5">{reponse}/ {questions}</spam>
    }
    if (reponse / questions >= 0.85) {
      return <spam className="bg-green-200 text-xl text-gray-800 font-medium px-2 w-max-16 rounded-md py-0.5">{reponse}/ {questions}</spam>
    }
  }
  if (categories.length === 0) {
    return undefined
  } else {
    return (
    <div className="w-3/4 mx-8  sm:mx-auto mt-8 px-2 py-0.5">
        <SimpleStepper steps={categories.length} step={activeStep} category={categories[activeStep]} changeStep={handleStep} />
        <div className="w-max-fit flex justify-center mb-2">
        {(stats.questions && stats.reponses)
          ? showStats(stats.reponses, stats.questions)
          : undefined}
        </div>
        <IndexAlert type="warning" text={ t('consultant.campaign.repond_not_allowed')}/>
        <div className="flex">
        <div className="w-full">
        <div className="bg-[#f5f4f0] w-full rounded-md ">
          <ResponseStatistics responsesStatistics={responsesStatistics || null}/>
        <h5 className="pt-2 pl-3 text-2xl">{categories[activeStep].category.text}</h5>
        </div>
        <div className="flex flex-wrap w-full bg-[#f5f4f0] gap-1 ">
          {categories[activeStep].questions
            ? <Questions displayComments= {true} showComments={true} commentUser={commentUser} disabled={true} responses={responses} surveyQuestions={categories[activeStep].questions} />
            : undefined }
            <SimpleStepper steps={categories.length} step={activeStep} category={categories[activeStep]} changeStep={handleStep} />
        </div>
        </div>
       </div>
    </div>
    )
  }
}

UserAnswers.propTypes = {
  idCampaign: PropTypes.string,
  idUser: PropTypes.string,
  user: PropTypes.string
}

export default UserAnswers
