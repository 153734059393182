import * as React from 'react'
import BaseQuestion from '../baseQuestion'
import PropTypes from 'prop-types'


function SimpleSelect ({ disabled, saveResponse, fullResponse, reponse, question, comment, commentUser, showComments, questionHelpText }) {
  const sendData = (state) => {
    const response = {
      ...fullResponse,
      value: state === 'delete' ? '' : reponse,
      question: question.uuid,
      state
    }
    saveResponse(response)
  }

  return (
    <BaseQuestion disabled={disabled} reponse={fullResponse} showComments={showComments} commentUser ={commentUser} questionHelpText={questionHelpText}
    comment={comment} question={question} sendData={(state) => sendData(state)}>

    <h5 className="text-md">{question.text}</h5>
     <select className="ml-6 mt-2 form-select appearance-none block w-min-fit pl-1 pr-4 py-0.5 text-base font-normal text-gray-700
    bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
    focus:text-gray-700 focus:bg-white focus:border-teal-500 focus:border-1" aria-label="Default select example"
     value={reponse} onChange={(e) => saveResponse({ ...fullResponse, value: e.target.value, question: question.uuid, state: e.target.value && 'draft' })}
     disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}>
      <option></option>
      {question.option.map((opt, index) =>
      <option key={index} value={opt.id}>{opt.text}</option>)}
    </select>
    </BaseQuestion>
  )
}

SimpleSelect.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  responses: PropTypes.array,
  commentUser: PropTypes.string,
  saveResponse: PropTypes.func,
  reponse: PropTypes.string,
  question: PropTypes.string,
  comment: PropTypes.object,
  questionHelpText: PropTypes.string,
  showComments: PropTypes.bool,
  fullResponse: PropTypes.object

}

export default SimpleSelect
