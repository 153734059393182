import React from 'react'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, parametersOptions } from '../DynamicNavbarOptions'
import CreateActivity from './createActivity'
import { useTranslation } from 'react-i18next'

function CreateActivitySector () {
  const { t } = useTranslation()
  const [activitySector, SetActivity] = React.useState({ name: '', description: '' })
  return (
    <div className="w-full pb-16">
    <DynamicNavBar option="navbar.parametersOptions.settings" options={generalOptions}/>
        <DynamicNavBar option="navbar.ActivityOptions.activity_sector" options={parametersOptions}/>
      <div className="w-full flex flex-col items-center gap-8  static mt-4" >
        <div className=" w-1/3 bg-wite md:mx-8 pb-8">
          <h5 className="text-2xl font-medium text-[#444444] w-250">{t('entity.activity_sector')}</h5>
            <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4">
            <div className="md:w-full px-3 mt-1 relative rounded-sm ">
              <label
                htmlFor="price"
                className="block text-lg  font-medium text-gray-800">
                {t('entity.activity_sector')}
              </label>
              <input
                type="text"
                name="price"
                id="price"
                className="focus:ring-teal-500
              focus:border-teal-500 block w-full py-0.5 pl-2 text-md  text-[#444444]
              rounded-sm border-1 border-gray-300"
                value={activitySector?.name ? activitySector.name : ''}
                onChange={(e) =>
                  SetActivity({ ...activitySector, name: e.target.value })
                }
              />
            </div>
            <div className="md:w-full px-3 mt-1 relative rounded-sm ">
              <label
                htmlFor="price"
                className="block text-lg  font-medium text-gray-800">
                {t('description')}
              </label>

              <textarea
                type="date"
                name="price"
                id="price"
                className="focus:ring-teal-500  h-300
              focus:border-teal-500 block py-0.5 pl-2 text-md  text-[#444444]
              rounded-sm border-1 border-gray-300 w-full"
              value={activitySector?.description ? activitySector.description : ''}
              onChange={(e) =>
                SetActivity({ ...activitySector, description: e.target.value })
              }
              ></textarea>
            </div>

        <CreateActivity activity={activitySector}>
          <button
            className="bg-[#086e7c] rounded-sm float-right p-1">
            <h5 className="text-white  w-max-fit px-1" disabled={activitySector.name !== ''}>{t('create_activity_sector')}</h5>
          </button>
        </CreateActivity>
        </div>

      </div>
    </div>
    </div>

  )
}

export default CreateActivitySector
