import React from 'react'
import 'reactjs-popup/dist/index.css'
import './stylePop.css'
import BeatLoader from 'react-spinners/BeatLoader'
import QuestionAddComponent from '../questionAddComponent'
import IndexAlert from '../../../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'
import QuestionList from './questionList'
import { useCreateSuveyNodeMutation } from '../../../../features/surveyNodesSlice/surveyNodesSlice'

const CreateSurveyNodeComponent = ({ surveyNode, nodeSurvey, setNodeSurvey, setChosedNode }) => {
  const [createSuveyNode, { isSuccess, isLoading, isError, error }] = useCreateSuveyNodeMutation()
  const { t } = useTranslation()

  const handleCreateSurveyNode = async () => {
    const question = nodeSurvey.question.map((qst) => qst.id)
    const data = { ...nodeSurvey, question, parent: surveyNode.node }
    await createSuveyNode(data)
  }

  const ref = React.useRef()
  const closeTooltip = () => {
    setChosedNode({ node: '', action: '' })
  }
  return (
        <div className="modal overflow-auto" >
            <div className="content flex flex-col justify-start">
                <h5 className="#444 text-2xl text-center ">{t('admin.survey.surveys.create.create_surveyNode')}</h5>
                <div className="mt-2">
                  {isLoading && <BeatLoader color="#086e7c"/>}
                  {isSuccess && <div className="flex flex-col gap-1"><IndexAlert type="success" text={t('admin.survey.surveys.create.create_surveyNode_success')}/></div>}
                  {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)}/> </div>}
                </div>
                <div>
                <div className="w-3/5 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
                  <label
                    htmlFor="price"
                    className="block text-lg font-medium text-gray-800">
                    {t('admin.survey.surveys.create.node_title')}
                  </label>
                  <input
                  placeholder={t('admin.survey.surveys.create.node_title')} type="text" name="price" id="price"
                  className="focus:ring-teal-500 focus:border-teal-500 block
                    w-full pl-2 pr-12 text-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}
                    value={nodeSurvey?.title ? nodeSurvey.title : ''}
                    onChange={(e) =>
                      setNodeSurvey({ ...nodeSurvey, title: e.target.value })}
                  />
                </div>
                <div className="w-full px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
                  <label
                    htmlFor="price"
                    className="block text-lg font-medium text-gray-800">
                    {t('admin.survey.surveys.create.question_list')}
                  </label>
                  <QuestionList setNodeSurvey={setNodeSurvey} nodeSurvey={nodeSurvey}/>
            </div>
            </div>
            </div>
          <div className="actions">
          {<button className="text-lg bg-gray-200 rouned-md px-2 py-0.5" onClick={() => closeTooltip()} style={{ cursor: 'pointer' }}>{t('close')}</button>}
          <button className="text-lg bg-green-800 rouned-md px-2 py-0.5 text-white" onClick={() => handleCreateSurveyNode()} style={{ cursor: 'pointer' }}>{t('question.create')}</button>
          </div>
          <div className='mb-16'>
            <QuestionAddComponent/>
          </div>
        </div>
  )
}
export default CreateSurveyNodeComponent
