import React from 'react'
import DualListBox from 'react-dual-listbox'
import { useTranslation } from 'react-i18next'
import 'react-dual-listbox/lib/react-dual-listbox.css'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import PropTypes from 'prop-types'

function DualboxSurvey ({ campaign, surveys, onChangeCampaign }) {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])
  const [options, setOptions] = React.useState([])
  React.useEffect(() => {
    if (surveys) {
      const formatOptions = []
      surveys.map((survey) => {
        formatOptions.push({ value: survey.id, label: survey.name })
      })
      setOptions(formatOptions)
    }
  }, [surveys])

  React.useEffect(() => {
    if (campaign?.survey_trees) {
      const surveysTrees = surveys.filter((survey) => campaign.survey_trees.includes(survey.id))
      const formatSelected = []
      surveysTrees.map((survey) => {
        formatSelected.push(survey.id)
      })
      setSelected(formatSelected)
    }
  }, [])
  React.useEffect(() => {
    onChangeCampaign({ ...campaign, survey_trees: selected })
  }, [selected])

  return (
            <DualListBox
                canFilter
                options={options}
                selected={selected}
                onChange={setSelected}
                filterPlaceholder={t('common.table.search')}
                icons={{
                  moveLeft: <KeyboardArrowLeftIcon/>,
                  moveAllRight: <KeyboardDoubleArrowRightIcon/>,
                  moveRight: <KeyboardArrowRightIcon/>,
                  moveAllLeft: <KeyboardDoubleArrowLeftIcon/>
                }}
            />
  )
}
DualboxSurvey.propTypes = {
  onChangeCampaign: PropTypes.func.isRequired,
  surveys: PropTypes.array,
  campaign: PropTypes.object

}

export default DualboxSurvey
