import React from 'react'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions, parametersOptions } from '../DynamicNavbarOptions'
import OptionsGeneralList from './components/optionsGeneralList'
import QuestionTagGeneralList from './components/questionTagGeneralList'
import { useGetQuestionTagsQuery, useGetOptionsQuery } from '../../../features/questions/questionSlice'
import OptionAdd from '../../../features/questions/optionAdd'
import TagAdd from '../../../features/questions/tagAdd'
import { useTranslation } from 'react-i18next'

function QuestionTracking () {
  const { t } = useTranslation()

  const { data: options } = useGetOptionsQuery()
  const { data: questionTags } = useGetQuestionTagsQuery()
  return (
    <div className="w-full pb-16">
      <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
      <DynamicNavBar option="navbar.surveyOptions.question_settions" options={surveyOptions}/>
      <DynamicNavBar option="navbar.parametersOptions.settings" options={parametersOptions}/>
        <div className="w-full flex flex-col items-center  static mt-4" >
          <div className="w-1/3 bg-wite pb-4">
            <h5 className="text-2xl  font-medium text-[#444444] w-max-fit">{t('admin.survey.questions.options_list')}</h5>
              <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-2 py-4 flex flex-col gap-4">
                <div className='w-full'>
                  <OptionsGeneralList options={options || []}/>
                  </div>
                    <OptionAdd optionType={false}/>
              </div>
          </div>
          <div className="w-1/3 bg-wite pb-4">
            <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('admin.survey.questions.question_tags_list')}</h5>
              <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-2 py-4 flex flex-col gap-4">
                <div className=''>
                  <QuestionTagGeneralList questionTags={questionTags || []}/>
                </div>
                    <TagAdd/>
              </div>
          </div>
        </div>
    </div>
  )
}
export default QuestionTracking



