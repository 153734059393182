import React from 'react'
import Switch from '@mui/material/Switch'
import BaseQuestion from './baseQuestion'

function BooleanQ (props) {
  const [checked, setChecked] = React.useState(true)
  const handleChange = (event) => {
    setChecked(event.target.checked)
  }
  return (
    <BaseQuestion>

      <h5 className="text-xl">{props.question.text}</h5>
         <Switch
         checked={checked}
         onChange={handleChange}
         inputProps={{ 'aria-label': 'controlled' }}/>
    </BaseQuestion>
  )
}

export default BooleanQ
