import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const materialityMatrixAdapter = createEntityAdapter({
})
const initialState = materialityMatrixAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const materialityMatrixApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'materialityMatrixSlice',
  tagTypes: ['materialityMatrix'],
  endpoints: builder => ({
    createMaterialityMatrix: builder.mutation({
      query: (materialityMatrix) => ({
        url: '/materiality_matrix/create',
        method: 'POST',
        body: materialityMatrix
      }),
      invalidatesTags: ['materialityMatrix']
    }),
    evaluateMaterialityMatrix: builder.mutation({
      query: (idMaterialityMatrix) => ({
        url: `/materiality_matrix/evaluate/${idMaterialityMatrix}/`,
        method: 'POST',
        body: idMaterialityMatrix
      }),
      invalidatesTags: []
    }),
    deleteMaterialityMatrix: builder.mutation({
      query: (id) => ({
        url: `/materiality_matrix/delete/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['materialityMatrix']
    }),
    getMaterialityMatrixUserList: builder.query({
      query: (id) => `/materiality_matrix/list/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['materialityMatrix']
    }),
    getMaterialityMatrixUserEntityList: builder.query({
      query: ({ id_user, id_entity }) => `/materiality_matrix/list/${id_user}/${id_entity}`,
      transformResponse: responseData => {
        return materialityMatrixAdapter.setAll(initialState, responseData)
      },
      providesTags: ['materialityMatrix']
    }),
    getMaterialityMatrixAllList: builder.query({
      query: () => '/materiality_matrix/all/list',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['materialityMatrix']
    }),
    getMaterialityMatrixSurvey: builder.query({
      query: (id) => `/materiality_matrix/survey/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['materialityMatrix']
    }),
    getMaterialityMatrix: builder.query({
      query: (id) => `/materiality_matrix/get_one_matrix/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: []
    })
  })
})

export const {
  useCreateMaterialityMatrixMutation,
  useDeleteMaterialityMatrixMutation,
  useEvaluateMaterialityMatrixMutation,
  useGetMaterialityMatrixUserListQuery,
  useGetMaterialityMatrixUserEntityListQuery,
  useGetMaterialityMatrixAllListQuery,
  useGetMaterialityMatrixSurveyQuery,
  useGetMaterialityMatrixQuery

} = materialityMatrixApiSlice

export const selectmaterialityMatrixResult = materialityMatrixApiSlice.endpoints.getMaterialityMatrixUserEntityList.select()

export const selectMatMatrixData = createSelector(
  selectmaterialityMatrixResult,
  materialityMatrixResult => materialityMatrixResult.data
)

export const {
} = materialityMatrixAdapter.getSelectors(state => selectMatMatrixData(state) ?? initialState)
