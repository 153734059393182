import React from 'react'
import { useParams } from 'react-router-dom'
import TransformHistorics from './table/transformHistorics'
import { useGetEvaluationHistoricQuery } from '../../../features/evaluation/evaluationSlice'
import { useTranslation } from 'react-i18next'

const EvaluationNotes = () => {
  const { t } = useTranslation()
  const [historics, setHistorics] = React.useState()
  const { evaluationId } = useParams()
  const { data: historicList } = useGetEvaluationHistoricQuery(evaluationId)
  React.useEffect(() => {
    if (historicList) {
      setHistorics(historicList)
    }
  }, [historicList])
  return (
    <div className='w-full px-4 py-4 '>
      <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('consultant.evaluation.result.transformation_result')}</h5>
      <TransformHistorics historics={historics || []}/>
    </div>
  )
}

export default EvaluationNotes
