import React, { useEffect } from 'react'
import Tree from 'react-d3-tree'
import { useTranslation } from 'react-i18next'
import ColorPalet from '../components/colorPalet'

const NOTECOLOR = {
  red: '#e38f8f',
  green: '#5ac49c',
  yellow: '#f3f7a6',
  None: '#c2c2c2'
}
export default function OrgChartTree ({ data, onChangeCategory }) {
  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => {
    const color = (() => {
      if (nodeDatum?.rawValue == null) {
        return NOTECOLOR.None
      } else if (nodeDatum?.rawValue < 7) {
        return NOTECOLOR.red
      } else if (nodeDatum?.rawValue > 7 && nodeDatum?.rawValue < 15) {
        return NOTECOLOR.yellow
      } else if (nodeDatum?.rawValue >= 15) {
        return NOTECOLOR.green
      }
    })()

    return (
    <g onClick={() => {
      if (nodeDatum) {
        onChangeCategory(nodeDatum)
      }
    }}>
      <circle r="10" fill={color} stroke={color} onClick={toggleNode}
      />
      <text strokeWidth="1" x="20">
        {nodeDatum.name}
      </text>
      {nodeDatum.attributes?.department && (
        <text x="20" dy="20" strokeWidth="1">
          Department: {nodeDatum?.name}
          <br />
          Value : {nodeDatum?.value}
        </text>
      )}
    </g>
    )
  }
  const { t } = useTranslation()
  const [evaluationData, setEvaluationData] = React.useState()
  const [treeContainer, setTreeContainer] = React.useState(undefined)
  const [coordinates, setCoordinates] = React.useState({ translateX: 400, translateY: 100 })
  const [dimensions, setDimensions] = React.useState()
  React.useEffect(() => {
    if (data) {
      setEvaluationData(data)
    }
  }, [data])

  useEffect(() => {
    if (treeContainer) {
      const { width, height } = treeContainer.getBoundingClientRect()
      setCoordinates({
        translateX: width / 2.5,
        translateY: height / 2
      })
    }
  }, [])

  return (
    <div className="flex flex-col justify-center w-full" >
      <ColorPalet/>
      <div ref={tc => (setTreeContainer(tc))} className="tree-container"></div>
      <Tree data={evaluationData || {}}
        nonSiblings={1} siblings={1}
        dimensions={dimensions}
        shouldCollapseNeighborNodes={true}
        centeringTransitionDuration={800}
        transitionDuration={500}
        translate={{ x: coordinates?.translateX, y: coordinates?.translateY }}
        orientation="vertical"
        onNodeClick={(node, evt) => {
          if (node?.data) {
            onChangeCategory(node.data)
          }
        }}
        renderCustomNodeElement={renderRectSvgNode}
        initialDepth={1}
      />
    </div>
  )
}
