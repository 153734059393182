import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGetAllSurveysQuery, selectAllSurveys } from '../../../../features/surveys/surveysSlice'
import { useSelector } from 'react-redux'

const ChooseSurvey = ({ survey, setSurvey }) => {
  const { data } = useGetAllSurveysQuery()
  const surveys = useSelector(selectAllSurveys)
  const { t } = useTranslation()
  return (
    <>
      <div className="flex flex-col justify-between px-3 mb-1">
            <label htmlFor="price" className="block font-medium text-gray-800 text-lg mb-1">
            {t('question.choose_survey')}
          </label>
          <div className="flex jusify-between gap-2">
        <select className="focus:ring-teal-500 focus:border-teal-500 block border-2
         border-[#e8e6e6] pl-1 pr-2 text-lg  text-[#444444] w-1/2
            rounded-sm"
          onChange={(e) => setSurvey({ ...survey, survey: e.target.value })}
          >
            <option></option>
            {surveys.map((op) => <option key={op.id} value={op.id}>{op.name}</option>)}
    </select>
        </div>
        </div>
    </>
  )
}
export default ChooseSurvey
