import React from 'react'
import { useGetAllSurveysQuery } from '../../../../features/surveys/surveysSlice'
import { useGetUserQuery } from '../../../../features/users/usersSlice'
import { useSelector } from 'react-redux'
import { useGetActivitiesQuery, selectAllActivities } from '../../../../features/activitiesSector/activitiesSlice'
import { useTranslation } from 'react-i18next'

function Card ({ survey }) {
  return (
      <div className="bg-[#DCD3C6] px-2 p-1">{survey}</div>
  )
}
function EntityDetails ({ entity }) {
  const { data, isSuccess } = useGetAllSurveysQuery()

  const { data: user } = useGetUserQuery(entity?.creator ? entity.creator : undefined)

  const { t } = useTranslation()

  // Retreive activity sectors to display
  const activitySectorsSelector = useSelector(selectAllActivities)
  const activitySectors = {}
  const { as } = useGetActivitiesQuery()
  activitySectorsSelector.map((sector) => activitySectors[sector.id] = sector)

  return (
    <>
    <h5 className="block text-xl font-medium text-[#444] mt-2 ">{t('admin.organisation.details.Organisation_infos')}</h5>
      <div className="overflow-auto h-250 grid xl:grid-cols-2  grid-cols-1 gap-1 max-w-800">
        <div className="col-span-1">
          <h5 className="block text-lg font-medium text-[#444] mt-2">{t('admin.organisation.details.Authorized_survey')}</h5>
          {<div className="flex flex-wrap gap-1">

          {isSuccess &&
          entity.survey_trees.map((survey, index) => (
            <Card key={index} survey={data?.entities?.[survey]?.name ? data.entities[survey].name : undefined}/>))}
          </div> }
        </div>
        
        <div className="col-span-1 flex flex-col gap-2">
          <div className="flex justify-between">
            <p>{t('admin.organisation.details.organisation_name')}</p>
            <p>{entity.name}</p>
          </div>
          <div className="flex justify-between">
            <p>{t('admin.organisation.details.creation_date')}</p>
            <p>{entity.created_at}</p>
          </div>
          <div className="flex justify-between">
            <p>{t('admin.organisation.details.creator')}</p>
            <p>{user?.name ? user.name : entity.creator}</p>
          </div>
          <div className="flex justify-between">
            <p>{t('admin.organisation.details.nbr_employee')}</p>
            <p>{entity.employee_Number}</p>
          </div>
          <div className="flex justify-between">
            <p>{t('admin.organisation.details.updateDate')}</p>
            <p>{entity.updated_at}</p>
          </div>
          <div className="flex justify-between">
            <p>{t('admin.organisation.details.ActivitySector')}</p>
            <p>{activitySectors[entity.activity_sector] ? activitySectors[entity.activity_sector].reference + ' - ' + activitySectors[entity.activity_sector].name : 'n'}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default EntityDetails
