import React from 'react'
import add from '../../assets/add.png'
import list from '../../assets/list.png'
import manager from '../../assets/manager.png'
import ok from '../../assets/ok.png'
import users from '../../assets/users.png'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function StepCard ({ step, number, text, onChangeStep, image, title }) {
  return (
        <div className={number === step
          ? ' flex justify-start gap-2 shadow-[1px_0px_8px_-2px_rgba(8,110,124,0.97)] mx-auto w-full py-2 h-min-fit'
          : 'flex justify-start gap-2  shadow-[1px_0px_8px_-2px_rgba(205,207,207)] mx-auto w-full py-2'} style={{ cursor: 'pointer' }} onClick={() => onChangeStep(step)}>
            <img src={image} alt="value" className="w-20  py-4 px-2 ml-2"/>
             <div className=" grid  place-items-center">
             <div>
                <h5 className="text-md text-gray-700 font-semibold w-full">{title}</h5>
                <p className="text-xs font-sans pr-4 my-0   text-gray-400  w-full h-min-fit" >{text}</p>
            </div>
            </div>
        </div>
  )
}

function SideBarCreateEntity ({ onChangeStep, step }) {
  const { t } = useTranslation()

  return (
    <div className="min-h-screen w-full bg-white pt-4 px-4">
        <div className="flex flex-col gap-4 ">
        <StepCard title={t('admin.organisation.create.stepper.general_info')} number={1} text={t('admin.organisation.create.stepper.general_info_text')} image={add} step={step} onChangeStep={() => onChangeStep(1)}/>
            <StepCard title={t('admin.organisation.create.stepper.organisation_info')} number={2} text={t('admin.organisation.create.stepper.general_info_text')} image={add} step={step} onChangeStep={() => onChangeStep(2)}/>
            <StepCard title={t('admin.organisation.create.stepper.organisation_member')} number={3} text={t('admin.organisation.create.stepper.organisation_membe_text')} image={list} step={step} onChangeStep={() => onChangeStep(3)}/>
            <StepCard title={t('admin.organisation.create.stepper.survey_selection')} number={4} text={t('admin.organisation.create.stepper.survey_selectio_text')} image={users} step={step} onChangeStep={() => onChangeStep(4)}/>
            <StepCard title={t('admin.organisation.create.stepper.consultant')} number={5} text={t('admin.organisation.create.stepper.consultan_text')} image={manager} step={step} onChangeStep={() => onChangeStep(5)}/>
            <StepCard title={t('admin.organisation.create.stepper.submit')} number={6} text={t('admin.organisation.create.stepper.submit_text')} image={ok} step={step} onChangeStep={() => onChangeStep(6)}/>
        </div>
    </div>
  )
}
SideBarCreateEntity.propTypes = {
  onChangeStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired
}
StepCard.propTypes = {
  step: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onChangeStep: PropTypes.func,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}
export default SideBarCreateEntity
