import React from 'react'
import OrgChartComponent from './OrgChartComponent'
import { useParams } from 'react-router-dom'

function CreateOrgChart ({ dataOrg, chosenNode, setChosedNode }) {
  let addNodeChildFunc = null
  const { id } = useParams()
  const [nodeSurvey, setNodeSurvey] = React.useState({
    title: '',
    specification: '',
    question: [],
    categories: [],
    parent: '',
    node_type: 'category',
    survey: id
  })
  React.useEffect(() => {
    if (chosenNode) {
      setNodeSurvey({ ...nodeSurvey, parent: chosenNode })
    }
  }, [chosenNode])
  return (
    <div>

        <OrgChartComponent
        setClick={click => (addNodeChildFunc = click)}
        chosenNode={chosenNode}
        setChosedNode={setChosedNode}
        data={dataOrg}/>
    </div>
  )
}

export default CreateOrgChart
