import React from 'react'
import UserAdd from '../../../features/users/userAdd'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, usersOptions, adminType } from '../DynamicNavbarOptions'
import { useTranslation } from 'react-i18next'

function CreateUser () {
  const { t } = useTranslation()
  return (
  <div className="w-full">
    <DynamicNavBar option="navbar.generalOptions.users" options={generalOptions}/>
    <DynamicNavBar option="navbar.usersOptions.create" options={usersOptions}/>
    <div className="w-full flex flex-col items-center gap-8  static mt-4" >
          <div className=" md:w-2/3 lg:w-4/6 sm:w-2/3 bg-wite md:mx-8 pb-8">
            <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('admin.users.title.admin_create')}</h5>
              <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4">
                <UserAdd userType={adminType}/>
              </div>
          </div>
      </div>
  </div>
  )
}

export default CreateUser
