import React from 'react'
import DangerAlert from './dangerAlert'
import DangerInfo from './dangerInfo'
import DangerSuccess from './dangerSuccess'
import DangerWarning from './dangerWarning'
import PropTypes from 'prop-types'
function IndexAlert ({ type, text }) {
  if (type === 'danger') {
    return (
      <DangerAlert text={text}/>
    )
  }
  if (type === 'info') {
    return (
      <DangerInfo text={text}/>
    )
  }
  if (type === 'success') {
    return (
      <DangerSuccess text={text}/>
    )
  }
  if (type === 'warning') {
    return (
      <DangerWarning text={text}/>
    )
  }
}
IndexAlert.propTypes = {
  type: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
}
export default IndexAlert
