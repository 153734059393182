import React from 'react'
import DualListBoxComponent from '../dualListBox'
import { useTranslation } from 'react-i18next'

function ModifySurveys ({ changeEntity, entity, surveys }) {
  const { t } = useTranslation()
  return (
    <section>
      <div className="flex flex-col  flex-wrap gap-2">
        <div className="md:w-1/2 w-4/5 px-3 mb-3 md:mb-0 relative rounded-sm ">
          <h5 className="text-lg font-medium text-gray-700 ">{t('admin.organisation.edit.ajouter_questionnaire')} </h5>
        </div>
        <div className="mx-4">
          <DualListBoxComponent surveys={surveys} entity={entity} onChangeEntity={changeEntity}/>
        </div>
      </div>
    </section>

  )
}

export default ModifySurveys
