import React from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import NavBar from '../../components/Bars/navBar'
import AnswerSurveyController from './answerSurveyController'

function IndexSurvey () {
  return (
    <section className="bg-gray-50 min-h-screen mx-auto items-center">
        <NavBar/>
            <Outlet/>
            <Routes>
                <Route path="/:idCampaign/:idUser" element={<AnswerSurveyController/>}/>
            </Routes>
    </section>
  )
}

export default IndexSurvey
