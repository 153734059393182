import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetMaterialityMatrixQuery } from '../../../features/materialityMatrix/materialityMatrixSlice'
import { useTranslation } from 'react-i18next'
import { BubblePlot } from '../../../components/charts/bubbleChart/BubblePlot.tsx'

function MaterialityMatrixEvaluation () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id_matrix } = useParams()
  const { idEntity } = useParams()
  const [matrixResults, setData] = React.useState()

  const { data: materialityMatrixList, isSuccess, isError, isLoading, error } = useGetMaterialityMatrixQuery(id_matrix)

  React.useEffect(() => {
    if (materialityMatrixList?.matrix) {
      setData(Object.values(materialityMatrixList?.matrix))
    }
  }, [materialityMatrixList])
  return (
    <div className="w-full flex justify-center px-auto">
      <div className="md:w-2/3 lg:w-2/3 sm:w-2/3 bg-wite md:mx-8 pb-8 mt-4">
        <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4" >
            <div className="flex my-2 justify-between w-full">
              <h5 className="text-2xl font-medium  text-[#444444] w-max-fit">{t('consultant.materiality_matrix.materiality_matrix')} </h5>
              <div className="bg-teal-600 w-max-fit px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium"
                style={{ cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${idEntity}/materiality_matrix/create`)}>{t('consultant.buttons.createMaterialityMatrix')}
              </div>
            </div>
            <div>
              <BubblePlot data={matrixResults || []} width={800} height={800} />
            </div>
        </div>
      </div>
    </div>
  )
}

export default MaterialityMatrixEvaluation
