import React from 'react'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions, surveyListOptions } from '../DynamicNavbarOptions'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { selectAllSurveys, useGetAllSurveysQuery } from '../../../features/surveys/surveysSlice'
import UsedSurveysList from './export/usedSurveysList'
import { useSelector } from 'react-redux'

function usedSuveys () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data } = useGetAllSurveysQuery()
  const surveysTrees = useSelector(selectAllSurveys)
  return (
        <div className="w-full pb-16">
          <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
          <DynamicNavBar option="navbar.surveyOptions.suveys_list" options={surveyOptions}/>
          <DynamicNavBar option="navbar.surveyListOptions.surveys_inuse" options={surveyListOptions}/>
            <div className="w-full flex flex-col items-center gap-8  static mt-4" >
              <div className=" md:w-2/3 lg:w-4/6 sm:w-2/3 bg-wite md:mx-8 pb-8">
                <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('admin.survey.surveys.title.survey_inuse_Information')}</h5>
                  <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4">
                    <div>
                      <div className="flex justify-between w-full">
                      <div className="bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium  floart-right"
                      style={{ cursor: 'pointer' }} onClick={() => navigate('/admin/survey/start_create')}>{t('admin.survey.buttons.create_surey')}</div>
                      </div>
                      <UsedSurveysList surveysTrees={surveysTrees || []}/>
                    </div>
                  </div>
                </div>
            </div>
        </div>
  )
}

export default usedSuveys
