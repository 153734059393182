import React from 'react'
import { useTranslation } from 'react-i18next'

const InputField = ({ placeholder, value, onChange, disabled, type, onBlur }) => {
  const { t } = useTranslation()
  return (
            <input
                placeholder={t(placeholder)} type={type} name="price" id="price"
                className="focus:ring-teal-500 focus:border-teal-500 block
                w-full pl-2 pr-12 tsext-lg  text-[#444444] rounded-sm"
                style={{ border: '1px solid #e8e6e6' }} disabled={ disabled }
                value={value || ''} onChange={(e) => onChange(e.target.value)}
                onBlur={(e) => onBlur(e.target.value)}
            />
  )
}

export default InputField

// 'transformQuestion.titre'
