import React from 'react'
import IndexAlert from '../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'

function SurveyfinishedState ({}) {
  const { t } = useTranslation()
  return (
    <div className='flex justify-center mt-100 margin-auto'>
        <div className='md:w-3/4 w-7/8 mx-4'>
            <IndexAlert type="warning" text={t('survey.states.survey_finished')}/>
        </div>
    </div>
  )
}

export default SurveyfinishedState
