import React from 'react'
import { FilterMatchMode } from 'primereact/api'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import HistoryIcon from '@mui/icons-material/History'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'
import DownloadIcon from '@mui/icons-material/Download'
import { GetEvaluationExcelFile } from '../export/exportExcel'

function EvaluationList ({ evaluations }) {
  const { t } = useTranslation()
  const { idEntity } = useParams()

  const navigate = useNavigate()
  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        <RemoveRedEyeIcon sx={{ color: '#1b674c', cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${idEntity}/evaluation/${e.id}`)}/>
        <HistoryIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${idEntity}/evaluation/note_calculation/${e.id}`)}/>
        <DownloadIcon sx={{ color: '#1b674c', cursor: 'pointer' }} onClick={()=> GetEvaluationExcelFile(e.id)}/>
      </div>
    )
  }
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: evaluations,
    stateKey: 'dt-state-evaluation-hub-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    filters,
    header,
    globalFilterFields: ['name', 'add_date', 'report_data.evaluationMethod'],
    emptyMessage: t('consultant.evaluation.table.evaluation_methods_listNotFound'),
    columns: [
      { field: 'name', sortable: true, header: t('common.table.name') },
      { field: 'add_date', sortable: true, header: t('common.table.creation_date') },
      { field: 'matrix_module_name', sortable: true, header: t('consultant.evaluation.table.allow_matrix') },
      { field: 'evaluation_method_name', sortable: true, header: t('consultant.evaluation.table.evaluationMethod') },
      { body: actionTemplate, header: t('common.table.action') }]
  }

  const DataTableComponent = createDataTable(tableConfig)
  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300 ">
          {DataTableComponent}
        </div>
    </div>

  )
}
export default EvaluationList
