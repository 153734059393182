



// oauth vriables 
export const clientId  = "4Wm1kHxAq7r5U3IUVYpZ9mp0FsmodheL5dtLmQEg" 
export const secretKey = "PliMJggpDBjT7zO0WGjgJC7OE4x11HlupWYnZT8LaaoTwIyzQkg8iFHBXbG2hEOsOT8GihyETCzV7RKB4fbLUxTzMybONPPemsGEfVmVrbLAEr4DvLDb6bYBUyRwCCOW"  
export const defaultListDropdownOptions = [
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 }
  ]
export const defaultListDropdownValue = 20
// backend url 

export const  baseURL  = '/api'
