import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: { token: null, refresh_token: null, scope: null, expires_in: null, token_type: null, user: null },
  reducers: {
    setCredentials: (state, action) => {
      const { access_token, expires_in, token_type, scope, refresh_token } = action.payload
      state.token = access_token
      state.refresh_token = refresh_token
      state.scope = scope
      state.expires_in = expires_in
      state.token_type = token_type
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    logout: (state, action) => {
      state.token = null
      state.refresh_token = null
      state.scope = null
      state.expires_in = null
      state.token_type = null
      state.user = null
    }
  }
})

export const { setCredentials, logout, setUser } = authSlice.actions
export default authSlice.reducer
export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => state.auth.token
