import React from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import NavBarHub from '../../../components/Bars/navBarHub'
import MaterialityMatrixList from './materialityMatrixList'
import CreateMaterialityMatrixEvaluation from './createMaterialityMatrixEvaluation'
import MaterialityMatrixDetails from './materialityMatrixDetails'
import MaterialityMatrixEvaluation from './materialityMatrixEvaluation'



function IndexEvaluation () {
  return (
    <div>
        <NavBarHub option={'materiality_matrix'}/>
        <div className="flex flex justify-center static">
          <div className="w-full">
            <Outlet/>
            <Routes>
            <Route path="" element={<MaterialityMatrixList/>}></Route>
            <Route path="evaluation/:id_matrix" element={<MaterialityMatrixEvaluation/>}></Route>
            <Route path="details/:id_matrix" element={<MaterialityMatrixDetails/>}></Route>
            <Route path="create" element={<CreateMaterialityMatrixEvaluation/>}></Route>
            </Routes>
          </div>
        </div>
    </div>
  )
}

export default IndexEvaluation
