import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import SelectOptions from './selectOptions'
import InputField from './inputField'
import SelectOptionsGlobal from './selectOptionsGlobal'
import PropTypes from 'prop-types'
import UnitSelect from '../../../../features/questions/utils/unitSelect'
import Input from '../../../../features/questions/utils/input'
import { useGetChoicesListQuery } from '../../../../features/questions/questionSlice'

const MODULE_OPTIONS = ['module1', 'module2', 'module3', 'module4']
const FormulasDetailQuestion = ({ formulas, onChange, FORMULAS_OPTIONS, globalFormulas }) => {
  const { t } = useTranslation()
  const { data: choicesList } = useGetChoicesListQuery()
  const handleAddFormula = (index) => {
    const uuidQuestion = uuidv4()
    const formulaCode = uuidQuestion.split('-')[0]
    if (formulas[index].value) {
      const newFormula = {
        text: 'formula-' + formulaCode,
        value: '',
        id: uuidQuestion,
        type: 'python'
      }
      onChange([...formulas, newFormula])
    } else {
      alert(t('admin.survey.transformation_model.create.output.add_formula_alert'))
    }
  }
  const handleDeleteFormula = (index) => {
    const formulasList = [...formulas]
    formulasList.splice(index, 1)
    onChange(formulasList)
  }

  return (
    <div className='flex flex-col gap-1' >
      {formulas.map((formula, index) =>
        <div key={index}>
          <input className="block w-200 px-2 py-1 text-base font-normal text-gray-800
                bg-no-repeat rounded bg-transparent focus:outline-none" value={formula?.text || ''} type="text"
            onChange={(e) => {
              formula.text = e.target.value
              formulas[index] = formula
            }} />
          <div className='flex flex-col pb-4'>
            <div className='flex gap-1'>
              <div className='flex w-4/5 gap-2'>
                <SelectOptions onChange={(val) => {
                  const form = { ...formula, type: val, value: '' }
                  const formulaList = [...formulas]
                  formulaList[index] = form
                  onChange(formulaList)
                }}
                  data={FORMULAS_OPTIONS} value={formula?.type} />
                {formula?.type?.toLowerCase() === 'python' &&
                  <SelectOptions onChange={(val) => {
                    const form = { ...formula, value: val }
                    const formulaList = [...formulas]
                    formulaList[index] = form
                    onChange(formulaList)
                  }} data={MODULE_OPTIONS} value={formula?.value} />
                }
                {formula?.type?.toLowerCase() === 'local' &&
                  <InputField value={formula?.value}
                    onChange={(val) => {
                      const form = { ...formula, value: val }
                      const formulaList = [...formulas]
                      formulaList[index] = form
                      onChange(formulaList)
                    }}
                    disabled={false} type='text' />
                }
                {globalFormulas && formula?.type?.toLowerCase() === 'global' &&
                  <SelectOptionsGlobal value={formula?.id}
                    onChange={(val) => {
                      const form = { ...formula, value: val ? globalFormulas[val].text : '', id: val }
                      const formulaList = [...formulas]
                      formulaList[index] = form
                      onChange(formulaList)
                    }}
                    data={Object.values(globalFormulas)} />
                }
              </div>
              <div className='bg-red-700 w-15 h-15' style={{ cursor: 'pointer' }} onClick={() => handleDeleteFormula(index)}>
                <DeleteForeverIcon style={{ color: 'white' }} />
              </div>

              {index === formulas.length - 1 &&
                <div className='bg-teal-700' style={{ cursor: 'pointer' }} onClick={() => handleAddFormula(index)}>
                  <AddIcon style={{ color: 'white' }} />
                </div>
              }
            </div>
            {formula?.type !== 'global' &&
              <div className=''>
                <div>
                  <div className='flex'>
                    <UnitSelect text="admin.survey.transformation_model.create.fields.input_unit" value={formula?.input_unit || null} disabled={false}
                      onChange={(val) => {
                        const updatedFormulas = [...formulas]
                        updatedFormulas[index] = { ...formula, input_unit: val, input_denominator: val !== 'Fraction' && null }
                        onChange(updatedFormulas)
                      }} data={choicesList || []} />
                    {formula?.input_unit === 'Fraction' &&
                      <Input text="admin.survey.transformation_model.create.fields.additional_denominator" type="Number"
                        value={formula?.input_denominator}
                        onChange={(val) => {
                          const updatedFormulas = [...formulas]
                          updatedFormulas[index] = { ...formula, input_denominator: val }
                          onChange(updatedFormulas)
                        }
                        } />
                    }
                  </div>
                  <div className='flex'>
                    <UnitSelect text="admin.survey.transformation_model.create.fields.output_unit" value={formula?.output_unit || null} disabled={false}
                      onChange={(val) => {
                        const updatedFormulas = [...formulas]
                        updatedFormulas[index] = { ...formula, output_unit: val, output_denominator: val !== 'Fraction' && null }
                        onChange(updatedFormulas)
                      }} data={choicesList || []} />
                    {formula?.output_unit === 'Fraction' &&
                      <Input text="admin.survey.transformation_model.create.fields.additional_denominator" type="Number"
                        value={formula?.output_denominator}
                        onChange={(val) => {
                          const updatedFormulas = [...formulas]
                          updatedFormulas[index] = { ...formula, output_denominator: val }
                          onChange(updatedFormulas)
                        }
                        } />
                    }
                  </div>
                </div>
                  <div className='flex pt-2 gap-2'>
                    <Input text="admin.survey.transformation_model.create.fields.number_min" type="Number"
                      value={formula?.min || ''}
                      onChange={(val) => {
                        const updatedFormulas = [...formulas]
                        updatedFormulas[index] = { ...formula, min: val }
                        onChange(updatedFormulas)
                      }}
                    />
                    <Input text="admin.survey.transformation_model.create.fields.number_max" type="Number"
                      value={formula?.max || ''}
                      onChange={(val) => {
                        const updatedFormulas = [...formulas]
                        updatedFormulas[index] = { ...formula, max: val }
                        onChange(updatedFormulas)
                      }}
                    />
                  </div>
              </div>
            }
          </div>
          <hr />
        </div>

      )}

    </div>
  )
}
FormulasDetailQuestion.propTypes = {
  formulas: PropTypes.object,
  onChange: PropTypes.func,
  FORMULAS_OPTIONS: PropTypes.object,
  globalFormulas: PropTypes.object
}
export default FormulasDetailQuestion

/*
const updatedFormulas = [...formulas];
updatedFormulas[index] = { ...formula, type: val, value: '' };
*/
