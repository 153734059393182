import React, { useRef, useState } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import './style.css'
import BeatLoader from 'react-spinners/BeatLoader'
import IndexAlert from '../../../../components/alerts/indexAlert'
import { useDeleteSurveyMutation } from '../../../../features/surveysCreated/surveysSlice'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const DeleteSurvey = ({ children, id }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const confirmRef = useRef()

  const [deleteSurvey, { isLoading, isSuccess, isError, error }] = useDeleteSurveyMutation()
  const [confirm, setConfim] = useState()

  // const {data:  entity}=    useGetEntityQuery()

  const HandleDeleteEntity = async () => {
    await deleteSurvey(id)
  }
  const ref = useRef()
  const closeTooltip = () => {
    ref.current.close()
    navigate('/admin/survey')
  }

  return (
    <div>
    <Popup
        closeOnDocumentClick={false}
        trigger={children}
        contentStyle={{ width: '350px', heigh: '300px' }}
        modal
        ref={ref}
    >
      {close => (
        <div className="modal">
            <div className="content flex flex-col justify-center items-center ">
                <h5 className="#444 text-lg ">{t('common.popup.confirm')}<span className="text-red-600">{t('common.popup.confirm')}</span></h5>
                <div className="mt-2">
                   {isLoading && <BeatLoader color="#086e7c"/>}
                   {isSuccess && <IndexAlert type="success" text={t('common.popup.survey_deleted')}/>}
                {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)}/> </div>}
                </div>
                {(!isLoading && !isSuccess) &&
                  <input ref={confirmRef} onChange={(e) => setConfim(e.target.value)} id="confirm" className=" border-1 border-gray-300 pl-2 py-0.5 w-200"/>
                }
            </div>
            <div className="actions mt-2">
            <button onClick={() => closeTooltip()} className="bg-gray-200 text-gray-700 p-1 text-lg px-1 " style={{ cursor: 'pointer' }}>
            {t('common.popup.close')}</button>
            {confirm === 'Confirm'
              ? (isLoading || isSuccess)
                  ? undefined
                  : <button onClick={() => HandleDeleteEntity()} className="bg-red-800 text-white p-1 text-lg px-1" style={{ cursor: 'pointer' }}>
            {t('common.popup.delete')}</button>
              : undefined
            }
            </div>
        </div>
      )}
    </Popup>
    </div>
  )
}
export default DeleteSurvey
