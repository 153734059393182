import React from 'react'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { useTranslation } from 'react-i18next'
import { FilterMatchMode } from 'primereact/api'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'

const ImportGlobalFormulas = ({ data }) => {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))
  const tableConfig = {
    value: data,
    stateKey: 'dt-state-import-global-formulas-list',
    onFilter: (e) => setFilters(e.filters),
    header,
    globalFilterFields: ['text', 'reponse.text'],
    filters,
    style: { fontSize: '13px' },
    dataKey: 'type',
    emptyMessage: t('admin.survey.import_export.empty_transformation_method'),
    columns: [{ field: 'TRANSFORMATION_UUID', sortable: true, header: t('admin.survey.import_export.TRANSFORMATION_UUID') },
      { field: 'FORMULA_UUID', sortable: true, header: t('admin.survey.import_export.FORMULA_UUID') },
      { field: 'TRANSFORM_METHOD_TITLE', sortable: true, header: t('admin.survey.import_export.TRANSFORM_METHOD_TITLE') },
      { field: 'TEXT', sortable: true, header: t('admin.survey.import_export.TEXT') },
      { field: 'TYPE', sortable: true, header: t('admin.survey.import_export.TYPE') },
      { field: 'VALUE', sortable: true, header: t('admin.survey.import_export.VALUE') },
      { field: 'INPUT_UNIT', sortable: true, header: t('admin.survey.import_export.INPUT_UNIT') },
      { field: 'INPUT_DENOMINATOR', sortable: true, header: t('admin.survey.import_export.INPUT_DENOMINATOR') },
      { field: 'OUTPUT_UNIT', sortable: true, header: t('admin.survey.import_export.OUTPUT_UNIT') },
      { field: 'OUTPUT_DENOMINATOR', sortable: true, header: t('admin.survey.import_export.OUTPUT_DENOMINATOR') },
      { field: 'MAX_VALUE', sortable: true, header: t('admin.survey.import_export.MAX') },
      { field: 'MIN_VALUE', sortable: true, header: t('admin.survey.import_export.MIN') }]
  }
  const DataTableComponent = createDataTable(tableConfig)
  return (
      <div className="datatable-rowgroup-demo max-h-500 overflow-auto border-1 border-gray-200 mb-2">
        {DataTableComponent}
      </div>
  )
}
export default ImportGlobalFormulas
