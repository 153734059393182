import React from 'react'
import { FilterMatchMode } from 'primereact/api'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { useTranslation } from 'react-i18next'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'

function ActivitySectorTable ({ activities, ChangeActivity }) {
  const { t } = useTranslation()

  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        <RemoveRedEyeIcon sx={{ color: '#1b674c', cursor: 'pointer' }} onClick={() => ChangeActivity(e)}/>
      </div>
    )
  }
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')

  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: activities,
    stateKey: 'dt-state-activity-sector-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    header,
    filters,
    globalFilterFields: ['name', 'reference', 'referential', 'description'],
    emptyMessage: t('admin.settings.activity_sector.activitySector_list_notFound'),
    columns: [ 
      { field: 'reference', sortable: true, header: t('admin.settings.activity_sector.list.reference'), style: { width: '30%' } },
      { field: 'referential', sortable: true, header: t('admin.settings.activity_sector.list.referential'), style: { width: '30%' } },
      { field: 'name', sortable: true, header: t('admin.settings.activity_sector.list.name'), style: { width: '30%' } },
      { field: 'description', sortable: true, header: t('admin.settings.activity_sector.list.description'), style: { width: '60%' } },
      { body: actionTemplate, style: { width: '10%' }, header: t('common.table.action') }
    ]
  }
  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div className="p-1">
        <div className="card mx-auto w-full border-1 border-gray-300">
        {DataTableComponent}

        </div>
    </div>

  )
}
export default ActivitySectorTable
