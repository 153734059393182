import React from 'react'
import { useCreateOptionMutation } from './questionSlice'
import IndexAlert from '../../components/alerts/indexAlert'
import { Spinner } from '../../components/loadData/spinner'
import { useTranslation } from 'react-i18next'

export default function OptionAdd ({ optionType }) {
  const { t } = useTranslation()
  const [createOption, { isSuccess, isLoading, isError, error }] = useCreateOptionMutation()
  const [option, setOption] = React.useState({ text: '', type: '', status: 'Global' })
  const dfSwitchStatus = { Global: 'Local', Local: 'Global' }
  const handleCreateOption = async () => {
    await createOption(option)
  }

  return (
    <div className="w-full">
      <div className="md:w-full px-0.5 mb-3 md:mb-0 relative rounded-sm ">
      {isSuccess && <IndexAlert type="success" text="L'option a été crée"/>}
      {isLoading && <Spinner/>}
      {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)}/> </div>}
      <h5 className="text-xl font-medium text-gray-700 w-max-fit">{t('admin.survey.questions.optionCreate')}</h5>
      <div>
        <label htmlFor="price" className="block font-medium text-gray-700 text-md">
        {t('common.fields.text')}
        </label>
        <input type="text" name="price" id="price" className="focus:ring-teal-500
        focus:border-teal-500 h-8  block  w-full pl-2 pr-12 text-md  text-[#444444]
        rounded-sm" style={{ border: '1px solid #e8e6e6' }} placeholder={t('common.fields.text')}
          value={option?.text ? option.text : ''}
          onChange={(e) => setOption({ ...option, text: e.target.value })}/>
      </div>
      {optionType &&
      <div className='flex flex-col mt-2'>
        <label className="text-gray-800" htmlFor="flexSwitchCheckDefault">{t('admin.survey.questions.reponse_status')}
        <span className={option?.status === 'Glabal' ? 'font-semibold  text-md text-teal-700' : 'font-semibold text-md text-teal-700' }>{'  '}{option?.status === 'Global' ? t('admin.survey.questions.global') : t('admin.survey.questions.local')} </span>
        </label>
        <input className="w-9  rounded-full h-5  bg-white
        bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
        type="checkbox" id="flexSwitchCheckDefault" value={option?.status !== 'Glabal' } onClick={() => setOption({ ...option, status: dfSwitchStatus[option.status] })}/>
      </div>
      }
      </div>
      <div className="md:w-full px-1 mb-6 md:mb-0 mt-1 relative rounded-sm ">
      <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
      px-2 py-0.5 mt-4 float-right" style={{ cursor: 'pointer' }} onClick={() => handleCreateOption()}>{t('common.buttons.create')}</div>

      </div>
  </div>
  )
}
