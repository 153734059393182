import React from 'react'
import CreateEntity from './createEntity'
import { useTranslation } from 'react-i18next'

function FinalizeEntity ({ onChangeEntity, entity, onChangeStep }) {
  const { t } = useTranslation()
  const disable =
    !(entity?.agreement?.create && entity?.agreement?.terms)
  return (
    <section className="w-full p-2 ">
      <div className="flex flex-col w-full">
        <div>
          <h5
            className="block font-medium text-gray-800
            text-xl pt-1 pb-2">
            {t('admin.organisation.create.fields.create_organisation_condition')}
          </h5>
          <div className="overflow-auto h-200  border p-2 md:w-600  text-gray-700">
            {t('admin.organisation.create.fields.agrumentAddOrg')}
          </div>
          <div className="p-8 pt-4">
            <div className="form-check">
              <input
                className="form-check-input  border  text-white
      border-gray-300 rounded-sm bg-white checked:bg-teal-600 checked:border-teal-600
      focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain
      float-left mr-2 cursor-pointer"
                type="checkbox"
                style={{ width: '19px', height: '19px' }}
                checked={!!entity?.agreement?.terms}
                id="flexCheckDefault"
                onChange={(e) =>
                  onChangeEntity({
                    ...entity,
                    agreement: { ...entity.agreement, terms: e.target.checked }
                  })
                }
              />
              <label
                className="form-check-label inline-block text-gray-800 text-lg"
                htmlFor="flexCheckDefault"
                style={{ cursor: 'pointer' }}
              >
                {t('admin.organisation.create.fields.confirm_read')}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input border border-gray-300 rounded-sm
       bg-white  focus:outline-none transition duration-200 mt-1
       align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                style={{ width: '19px', height: '19px' }}
                checked={!!entity?.agreement?.create}
                id="flexCheckChecked"
                onChange={(e) =>
                  onChangeEntity({
                    ...entity,
                    agreement: {
                      ...entity.agreement,
                      create: e.target.checked
                    }
                  })
                }
              />
              <label
                className="form-check-label inline-block text-gray-800 text-lg"
                htmlFor="flexCheckChecked"
                style={{ cursor: 'pointer' }}
              >
                {t('admin.organisation.create.fields.confirm_createOrg')}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="w-4/5">
        <CreateEntity entity={entity}>
          <button
            className={
              !disable
                ? 'bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1'
                : 'bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1 opacity-50'
            }
            disabled={disable}>
            <h5 className="text-white  w-16">{t('admin.organisation.create.title.finalize')}</h5>
          </button>
        </CreateEntity>
        <button className="bg-gray-300 px-2 py-0.5 rounded-md mb-1 text-md font-medium float-right p-1">
          <h5 className="text-[#444444]  w-max-fit" onClick={() => onChangeStep(5)}>
            {t('common.buttons.post')}
          </h5>
        </button>
      </div>
    </section>
  )
}
export default FinalizeEntity
