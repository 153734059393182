import React from 'react'
import CreateUser from './components/createUser'
import { useTranslation } from 'react-i18next'

function QuestionAdd ({ userType }) {
  const [user, setUser] = React.useState({ email: '', first_name: '', last_name: '', admin_type: '' })
  const { t } = useTranslation()
  return (
    <div className="w-full">
      <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
        <label htmlFor="price" className="block font-medium text-gray-700 text-md">
          {t('admin.users.create.fields.prenom')}
        </label>
        <input type="text" name="price" id="price" className="focus:ring-teal-500
        focus:border-teal-500 h-8 block  w-full pl-2 pr-12 text-md  text-[#444444]
        rounded-sm" style={{ border: '2px solid #e8e6e6' }} placeholder="Prénom"
          value={user?.first_name ? user.first_name : ''}
          onChange={(e) => setUser({ ...user, first_name: e.target.value })}/>
      </div>
      <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
        <label htmlFor="price" className="block font-medium text-gray-700 text-md">
          {t('admin.users.create.fields.name')}
        </label>
        <input type="text" name="price" id="price" className="focus:ring-teal-500
        focus:border-teal-500 h-8 block  w-full pl-2 pr-12 text-md  text-[#444444]
        rounded-sm" style={{ border: '2px solid #e8e6e6' }} placeholder="Nom de famille"
          value={user?.last_name ? user.last_name : ''}
          onChange={(e) => setUser({ ...user, last_name: e.target.value })}/>
      </div>
      <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
        <label htmlFor="price" className="block font-medium text-gray-700 text-md">
        {t('admin.users.create.fields.adresse_email')}
        </label>
        <input type="text" name="price" id="price" className="focus:ring-teal-500
        focus:border-teal-500 h-8 block  w-full pl-2 pr-12 text-md  text-[#444444]
        rounded-sm" style={{ border: '2px solid #e8e6e6' }} placeholder="Adresse email"
          value={user?.email ? user.email : ''}
          onChange={(e) => setUser({ ...user, email: e.target.value })}/>
      </div>
      <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
        <label htmlFor="price" className="block font-medium text-gray-700 text-md">
          {t('admin.users.create.fields.admin_type')}
        </label>
      <select className="focus:ring-teal-500 focus:border-teal-500 block border-2  border-[#e8e6e6] h-8 w-full pl-1 pr-2 text-lg  text-[#444444]
          rounded-sm" value ={user?.admin_type ? user.admin_type : undefined }
        onChange={(e) => setUser({ ...user, admin_type: e.target.value })}>
            <option></option>
            {Object.keys(userType).map((user, index) => <option key={index} value={user}>{user}</option>)}
      </select>
      {user?.admin_type
        ? <div className="w-400 bg-gray-200 p-1 mt-2">{t(userType[user.admin_type])}</div>
        : undefined}
      <CreateUser user={user}>
      <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
      px-2 py-0.5 mt-4 float-right" style={{ cursor: 'pointer' }}>{t('admin.users.create.fields.admin_create')}</div>
        </CreateUser>
      </div>
  </div>
  )
}

export default QuestionAdd
