import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import LoginPage from './loginPage'
import ConfirmforgotPassword from './confirmforgotPassword'
import NavBarLogin from '../../components/Bars/navBarLogin'
import ConfirmNewPassword from './confirmNewPassword'
import FirstLogin from './firstlogin'

function IndexAuth () {
  return (
    <>
      <NavBarLogin/>
      <Outlet/>
      <Routes>
          <Route path="login" element={<LoginPage/>}/>
          <Route path="first" exact element={<FirstLogin/>}/>
          <Route path="forget_password" element={<ConfirmforgotPassword/>}/>
          <Route path="password/reset/confirm/:uid/:token" element={<ConfirmNewPassword/>}/>
      </Routes>
    </>
  )
}
export default IndexAuth
