import React from 'react'
import Alert from '@mui/material/Alert'
import PropTypes from 'prop-types'
function DangerAlert ({ text }) {
  return (
    <Alert className="max-w-fit items-center text:md md:text-lg" severity="warning">{text}</Alert>
  )
}
DangerAlert.propTypes = {
  text: PropTypes.string.isRequired
}
export default DangerAlert
