import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const SurveyNodesAdapter = createEntityAdapter({
})
const initialState = SurveyNodesAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const SurveyNodesApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'SurveyNodes',
  endpoints: builder => ({
    getSurveyNodes: builder.query({
      query: (id) => `/question/survey/${id}/surveyNodes`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['SurveyNodes']
    }),
    createSurvey: builder.mutation({
      query: (data) => ({
        url: '/question/survey/start_create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['SurveyNodes']
    }),
    createSuveyNode: builder.mutation({
      query: (data) => ({
        url: '/question/surveynode/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['SurveyNodes']
    }),
    updateSuveyNode: builder.mutation({
      query: (data) => ({
        url: `/question/surveynode/${data.id}/details`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['SurveyNodes']
    }),
    DeleteSuveyNode: builder.mutation({
      query: (id) => ({
        url: `/question/surveynode/${id}/details`,
        method: 'DELETE'
      }),
      invalidatesTags: ['SurveyNodes']
    })
  })
})

export const {
  useGetSurveyNodesQuery,
  useCreateSuveyNodeMutation,
  useDeleteSuveyNodeMutation,
  useUpdateSuveyNodeMutation
} = SurveyNodesApiSlice

export const SurveyNodesResult = SurveyNodesApiSlice.endpoints.getAllSurveys.select()

export const selectSurveyNodesData = createSelector(
  SurveyNodesResult,
  surveysResult => surveysResult.data
)

export const {
  selectAll: selectAllSurveys,
  selectById: selectSurveyById
} = SurveyNodesAdapter.getSelectors(state => selectSurveyNodesData(state) ?? initialState)
