import React from 'react'
import { useCreateTagQuestionMutation } from './questionSlice'
import IndexAlert from '../../components/alerts/indexAlert'
import { Spinner } from '../../components/loadData/spinner'
import { useTranslation } from 'react-i18next'

export default function TagAdd () {
  const { t } = useTranslation()

  const [tag, setTag] = React.useState({
    text: '',
    description: ''
  })

  const [createTagQuestion, { isSuccess, isLoading, isError, error }] = useCreateTagQuestionMutation()
  const handleCreateQuestionTag = async () => {
    await createTagQuestion(tag)
    if (isSuccess) {
      setTag({ text: '', description: '' })
    }
  }
  return (
    <div className="w-full">
    <div className="md:w-full px-0.5 mb-6 md:mb-0 relative rounded-sm ">
    {isSuccess && <IndexAlert type="success" text="Le tag a été crée "/>}
    {isLoading && <Spinner/>}
    {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)}/> </div>}
    <h5 className="text-2xl font-medium text-gray-700 w-max-fit">{t('admin.survey.questions.question_tags_create')}</h5>
    <label htmlFor="price" className="block font-medium text-gray-700 text-md">
    {t('common.fields.text')}
    </label>
    <input type="text" name="price" id="price" className="focus:ring-teal-500
    focus:border-teal-500 h-8 block  w-full pl-2 pr-12 text-md  text-[#444444]
    rounded-sm" style={{ border: '1px solid #e8e6e6' }} placeholder={t('common.fields.text')}
    value={tag?.text ? tag.text : ''}
    onChange={(e) => setTag({ ...tag, text: e.target.value })}/>
    <label htmlFor="price" className="block font-medium text-gray-700 text-md">
    {t('common.fields.description')}
    </label>
    <textarea type="text" name="price" id="price" className="focus:ring-teal-500
    focus:border-teal-500 block  w-full pl-2 pr-12 text-md  text-[#444444]
    rounded-sm" style={{ border: '1px solid #e8e6e6' }} rows={4}
    placeholder={t('common.fields.description')} value={tag?.description ? tag.description : ''}
    onChange={(e) => setTag({ ...tag, description: e.target.value })}></textarea>
    </div>
    <div className="md:w-full mb-6 md:mb-0 mt-1 relative rounded-sm ">
    <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
    px-2 py-0.5 mt-4 float-right" style={{ cursor: 'pointer' }} onClick={() => handleCreateQuestionTag()}>{t('common.buttons.create')}</div>
    </div>
    </div>
  )
}
