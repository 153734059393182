import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../utils/input'
import TextArea from '../utils/textArea'
import { useGetSurveysQuery } from '../../../../features/surveysCreated/surveysSlice'
import GlobalParams from '../utils/globalParams'
import SelectSurveyInput from '../utils/selectSurveyInput'
import { useModifyTransformMethodGlobalMutation } from '../../../../features/transformQuestion/transformMethodSlice'

function TransformMethodDetails ({ transformMethod }) {
  const { data: surveys } = useGetSurveysQuery()
  const [trsfModel, setTRSFModel] = useState({
    title: '',
    description: '',
    survey: '',
    addParams: true,
    global_parameters: {
      denominator: 20,
      global_formulas:
      {
        global_formula_uuid2: { text: 'formula : f(x)= x', value: 'f(x)= x', type: 'global' }
      }
    },
    disabled: false
  })
  React.useEffect(() => {
    if (transformMethod) {
      setTRSFModel(transformMethod)
    }
  }, [transformMethod])
  const [modifyTransformMethodGlobal, { isSuccess, isError, error }] = useModifyTransformMethodGlobalMutation()
  const { t } = useTranslation()

  const saveModel = async (data = trsfModel) => {
    await modifyTransformMethodGlobal(data)
  }
  
  return (
    <div className="flex flex-col gap-1 w-ful max-h-fit py-2">
      <div className="w-2/3 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm">
        <Input text='admin.survey.transformation_model.create.fields.titre' placeholder='admin.survey.transformation_model.create.fields.titre' disabled={trsfModel?.disabled}
          onBlur = {(val) => saveModel(val)}
          onChange={(val) => {
            setTRSFModel({ ...trsfModel, title: val })
          }}
            value={trsfModel?.title ? trsfModel.title : ''} />
      </div>
      <div className='w-2/3 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm'>
        <SelectSurveyInput onBlur = {() => saveModel()} text='admin.survey.transformation_model.create.fields.choose_survey' disabled={true} value={trsfModel?.survey} data={surveys || []}
          onChange={(val) => {
            setTRSFModel({ ...trsfModel, survey: val })
            saveModel({ ...trsfModel, survey: val })
          }} />
      </div>
      <div className='w-full px-3 mb-6 md:mb-0 relative rounded-sm'>
        <h5 htmlFor="price" className="inline-block text-gray-800 max-w-fit">
              {t('admin.survey.transformation_model.create.fields.global_parameters')}
          </h5>
          <div className="w-full px- px-3 mb-6 md:mb-0 relative rounded-sm">
            <GlobalParams onBlur={(val) => saveModel({ ...trsfModel, global_parameters: val })}
            params={trsfModel?.global_parameters} onChange={async (val) => {
              setTRSFModel({ ...trsfModel, global_parameters: val })
            }} />
          </div>
      </div>
      <div className="w-full px- px-3 mb-6 md:mb-0 relative rounded-sm">
        <TextArea text='admin.survey.transformation_model.create.fields.description' disabled={trsfModel?.disabled} placeholder='admin.survey.transformation_model.create.fields.description'
          onChange={(val) => setTRSFModel({ ...trsfModel, description: val })} rows={3} onBlur = {() => saveModel()}
          value={trsfModel?.description ? trsfModel.description : ''} />
      </div>
    </div>
  )
}

export default TransformMethodDetails
