import React from 'react'
import OptionAdd from '../../../features/questions/optionAdd'
import TagAdd from '../../../features/questions/tagAdd'
import OptionFormAdd from '../../../features/questions/optionFormAdd'
import QuestionAdd from '../../../features/questions/questionAdd'

function QuestionAddComponent () {
  const [option, setOption] = React.useState({ tregger: true, option: '' })

  return (
    <div className="w-full ">
      <div className='w-full md:w-2/3'>
        <QuestionAdd setOption={setOption} option={option}/>
      </div>
    <div className='w-full md:w-2/3'>
      {option.option &&
        <>
          {option.option === 'option' && <OptionAdd optionType={true}/>}
          {option.option === 'tag' && <TagAdd/> }
          {option.option === 'model' && <OptionFormAdd/>}
        </>}
    </div>
    </div>
  )
}

export default QuestionAddComponent
