import React from 'react'
import { selectCurrentUser } from '../auth/authSlice'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createDataTable } from '../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../appComponents/datatable/utils/filter'
import { template2 } from '../../appComponents/datatable/utils/paginators'
import { ActionTemplate } from './components/userListAction'
import { FilterMatchMode } from 'primereact/api'

function UsersList ({ usersList }) {
  const { t } = useTranslation()
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')

  const currentUser = useSelector(selectCurrentUser)

  const [filters, setFilters] = React.useState({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } })
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const changeType = (e) => {
    let Type = e.type
    if (Type === 'CLIENT') {
      Type = 'CONSULTANT'
    }
    return Type
  }

  const usersStatus = (e) => {
    return t(`admin.users.list.users_status.${e.status_user}`)
  }
  const tableConfigActions = {
    action: (e) => ActionTemplate({ e, currentUser })
  }
  const tableConfig = {
    value: usersList,
    stateKey: 'dt-state-users-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    filters,
    header,
    globalFilterFields: ['code', 'name', 'activity_sector'],
    emptyMessage: t('admin.users.list.user_list_noFound'),
    stateStorage: 'session',
    columns: [
      { field: 'name', sortable: true, header: t('admin.users.list.nom_prenom') },
      { field: 'email', sortable: true, header: t('admin.users.list.email_address') },
      { field: 'type', sortable: true, header: t('admin.users.list.type'), body: changeType },
      { field: 'status_user', sortable: true, header: t('admin.users.list.status'), body: usersStatus },
      { body: tableConfigActions.action, header: t('common.table.action') }
    ]
  }

  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300 ">
          {DataTableComponent}
        </div>
    </div>

  )
}
export default UsersList
