import React from 'react'
import GenralInfo from './components/genralInfo'
import SetUserList from './components/setUserList'
import FinalizeCampain from './components/finalizeCampain'
import { useParams } from 'react-router-dom'
import { useGetEntityQuery } from '../../features/entities/entitiesSlice'
import { useTranslation } from 'react-i18next'

function StepBull ({ step, number, text, onChangeStep }) {
  return (
        <div className="mt-5 min-w-fit" style={{ cursor: 'pointer' }} onClick={() => onChangeStep(step)}>
             <div >
                <h5 className={number === step
                  ? 'bg-[#086e7c] mx-auto grid place-items-center  p-1 rounded-full font-bold text-white text-xl'
                  : 'bg-[#3fabb8] mx-auto grid place-items-center  p-1 rounded-full font-bold text-white text-xl'} style={{ width: '35px' }} >{number}</h5>
            </div>
            <h5 className={number === step
              ? 'text-[#086e7c]  mx-auto text-center text-lg font-medium min-w-fit'
              : 'text-[#3fabb8] mx-auto text-center text-lg font-medium min-w-fit'}>{text}</h5>
        </div>
  )
}

function CampaignAdd () {
  const { idEntity } = useParams()
  const { data: entity } = useGetEntityQuery(idEntity)
  const [step, setStep] = React.useState(1)
  const [campaign, seCampaign] = React.useState({ description: '', allow_creator: false, users_list: [] })
  const { t } = useTranslation()
  const changeStep = (stp) => {
    switch (stp) {
      case 1:
        setStep(stp)
        break
      case 2:
        if (campaign.name && campaign.survey_trees.length !== 0 && campaign.time_deadLine) { setStep(stp) } else {
          if (!campaign.name) {
            alert(t('consultant.campaign.create.errors.form_name'))
            break
          }
          if (campaign.survey_trees.length === 0) {
            alert(t('consultant.campaign.create.errors.form_survey_tree'))
            break
          }
          if (!campaign.time_deadLine) {
            alert(t('consultant.campaign.create.errors.form_ddl'))
            break
          }
        }
        break
      case 3:
        if ((campaign.users_list).length !== 0 || campaign.allow_creator) { setStep(stp) } else { alert(t('campaign.create.errors.form_uncompleted')) }
        break
      default:
    }
  }
  return (
    <>
        <div className="mb-18 mt-5 w-1000 flex flex-col justify-center ">
          <h5 className="text-2xl font-medium text-[#444444]">{t('consultant.campaign.create.text')}</h5>
        <div className="text-lg font-semibold bg-[#C4A130]"></div>
        <div className="bg-white md:w-1000 lg:w-1100 px-2 pt-1 mt-2 shadow-sm pl-4 h-full pb-14">
        <div className="mx-auto h-16 w-1/2 flex justify-between " >
          <StepBull text={t('consultant.campaign.create.stepper.generalInformation')} number={1} step={step} onChangeStep={() => changeStep(1)}/>
          <StepBull text={t('consultant.campaign.create.stepper.usersList')} number={2} step={step} onChangeStep={() => changeStep(2)}/>
          <StepBull text={t('consultant.campaign.create.stepper.finalize')} number={3} step={step} onChangeStep={() => changeStep(3)}/>
        </div>
        <div>
            {step === 1 && <GenralInfo onChangeCampaign ={seCampaign} campaign ={campaign} onChangeStep={changeStep}/>}
            {step === 2 && <SetUserList entity={entity} onChangeCampaign ={seCampaign} campaign ={campaign} onChangeStep={changeStep}/>}
            {step === 3 && <FinalizeCampain onChangeCampaign ={seCampaign} campaign ={campaign} onChangeStep={changeStep}/>}
        </div>
        </div>
    </div>
    </>
  )
}

export default CampaignAdd
