import React from 'react'
import './DataTableDemo.css'
import { useTranslation } from 'react-i18next'

import { FilterMatchMode } from 'primereact/api'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'

const ImportQuestionsTable = ({ data }) => {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: data,
    stateKey: 'dt-state-import-questions-list',
    onFilter: (e) => setFilters(e.filters),
    header,
    globalFilterFields: ['QUESTION_CODE', 'TEXT', 'TYPE', 'MODEL_UUID', 'MODEL_NAME',
      'ANSWER_1', 'ANSWER_2', 'ANSWER_3', 'ANSWER_4', 'ANSWER_5',
      'TAG_1', 'TAG_2', 'TAG_3', 'TAG_4', 'TAG_5'],
    filters,
    style: { fontSize: '13px' },
    dataKey: 'type',
    emptyMessage: t('admin.survey.import_export.list_question_notFound'),
    columns: [
      { field: 'UUID', sortable: true, header: t('admin.survey.import_export.UUID') },
      { field: 'QUESTION_CODE', sortable: true, header: t('admin.survey.import_export.QUESTION_CODE') },
      { field: 'TEXT', sortable: true, header: t('admin.survey.import_export.TEXT') },
      { field: 'TYPE', sortable: true, header: t('admin.survey.import_export.TYPE') },
      { field: 'MODEL_UUID', sortable: true, header: t('admin.survey.import_export.MODEL_UUID') },
      { field: 'MODEL_NAME', sortable: true, header: t('admin.survey.import_export.MODEL_NAME') },
      { field: 'ANSWER_1', sortable: true, header: t('admin.survey.import_export.ANSWER_1') },
      { field: 'ANSWER_2', sortable: true, header: t('admin.survey.import_export.ANSWER_2') },
      { field: 'ANSWER_3', sortable: true, header: t('admin.survey.import_export.ANSWER_3') },
      { field: 'ANSWER_4', sortable: true, header: t('admin.survey.import_export.ANSWER_4') },
      { field: 'ANSWER_5', sortable: true, header: t('admin.survey.import_export.ANSWER_5') },
      { field: 'TAG_1', sortable: true, header: t('admin.survey.import_export.TAG_1') },
      { field: 'TAG_2', sortable: true, header: t('admin.survey.import_export.TAG_2') },
      { field: 'TAG_3', sortable: true, header: t('admin.survey.import_export.TAG_3') },
      { field: 'TAG_4', sortable: true, header: t('admin.survey.import_export.TAG_4') },
      { field: 'TAG_5', sortable: true, header: t('admin.survey.import_export.TAG_5') }
    ]
  }

  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div className="datatable-rowgroup-demo max-h-500 overflow-auto border-1 border-gray-200 mb-2">
      {DataTableComponent}
    </div>
  )
}
export default ImportQuestionsTable
