import React from 'react'
import BookmarkIcon from '@mui/icons-material/Bookmark'
function CapitalCard (props) {
  return (
      <div className="bg-white rounded-md shadow-sm p-2 flex justify-between w-full" style={{ cursor: 'pointer' }}>
        <div className="flex justify-between">
            <BookmarkIcon sx={{ color: '#ebe59d' }}/>
            <h5 className="text-[#444444] text-md">{props.category.text}</h5>
        </div>

      </div>
  )
}

export default CapitalCard
