import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const postsAdapter = createEntityAdapter({
})
const initialState = postsAdapter.getInitialState({
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const entityMembersApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'entityMembersSlice',
  endpoints: builder => ({
    getEntityMembers: builder.query({
      query: (id) => `/entity/${id}/entity_members`,
      transformResponse: responseData => {
        return responseData
      }
    })

  })
})

export const {
  useGetEntityMembersQuery
} = entityMembersApiSlice

export const selectEntityMembersResult = entityMembersApiSlice.endpoints.getEntity.select()

export const selectEntityMembersData = createSelector(
  selectEntityMembersResult,
  entityData => entityData?.data ?? {}
)

export const {
  selectAll: selectEntityMembers
} = postsAdapter.getSelectors(state => selectEntityMembersData(state) ?? initialState)
