import React from 'react'
import PropTypes from 'prop-types'

const OneComment = ({ comment }) => {
  return (
    <div className="px-3 mr-4">
      <p className="text-sm pl-4 text-gray-800 ">{comment?.name ? comment.name : '' } <small>({comment?.email ? comment.email : '' })</small></p>
      <p className="text-xs pl-4 text-gray-500 ">{comment?.updated_at ? comment.updated_at : '' } </p>
      <div className="rounded-md border border-gray-200 px-3 mr-4 bg-gray-100 ml-10 py-2 shadow-sm hover:shadow-md transition duration-300 w-full">
        <div>
          <p className="text-md text-gray-800">{comment?.comment_text ? comment.comment_text : null }</p>
        </div>
      </div>
  </div>
  )
}

OneComment.propTypes = {
  comment: PropTypes.object,
  commentUser: PropTypes.string
}
export default OneComment
