import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const transformMethodAdapter = createEntityAdapter({
  selectId: (entity) => entity.id
})
const initialState = transformMethodAdapter.getInitialState({
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const transformMethodApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'transformMethodSlice',
  tagTypes: ['TransformMethod'],
  endpoints: builder => ({
    getTransformMethods: builder.query({
      query: () => '/evaluation/transformMethod/get_all',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['TransformMethod']
    }),
    getSurveyQuestion: builder.query({
      query: (id) => `/question/transformResponse/${id}/questions`,
      transformResponse: responseData => {
        return responseData
      }
    }),
    getSurveyNodesTransform: builder.query({
      query: (id) => `/question/evaluationMethod/${id}/nodes`,
      transformResponse: responseData => {
        return responseData
      }
    }),
    modifyTransformMethodGlobal: builder.mutation({
      query: (data) => ({
        url: '/evaluation/transformMethod/modify_global',
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['TransformMethod']
    }),
    getSingleTansformMethod: builder.query({
      query: (id) => `/evaluation/transformMethod/get/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['TransformMethod']
    }),
    deleteTransformMethod: builder.mutation({
      query: (id) => ({
        url: `/evaluation/transformMethod/delete/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['TransformMethod']
    }),
    registerQuestion: builder.mutation({
      query: (data) => ({
        url: `/evaluation/transformMethod/add_question/${data.id}`,
        method: 'POST',
        body: data.questionInfo
      }),
      invalidatesTags: ['TransformMethod']
    }),
    createTransformMethod: builder.mutation({
      query: (data) => ({
        url: '/evaluation/transformMethod/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['TransformMethod']
    }),
    setNoAnswer: builder.mutation({
      query: (data) => ({
        url: '/response/no_answer',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['TransformMethod']
    })
  })
})

export const {
  useGetTransformMethodsQuery,
  useGetSurveyQuestionQuery,
  useGetSurveyNodesTransformQuery,
  useRegisterQuestionMutation,
  useModifyTransformMethodGlobalMutation,
  useDeleteTransformMethodMutation,
  useGetSingleTansformMethodQuery,
  useCreateTransformMethodMutation
} = transformMethodApiSlice

export const transformMethodResult = transformMethodApiSlice.endpoints.getTransformMethods.select()

export const selectTransformMethodsData = createSelector(
  transformMethodResult,
  entityData => entityData?.data ?? {}
)

export const {
  selectAll: selectResponses,
  selectById: selectSingleResponse
  // Pass in a selector that returns the posts slice of state
} = transformMethodAdapter.getSelectors(state => selectTransformMethodsData(state) ?? initialState)
