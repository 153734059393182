import React, { useState } from 'react'
import ModifyGeneralInfo from './modifyGeneralInfo'
import EditEntityLoader from './editEntityLoader'
import { selectCurrentUser } from '../../../../features/auth/authSlice'
import { useGetEntitiesAdminQuery } from '../../../../features/entities/entitiesSlice'
import { Spinner } from '../../../../components/loadData/spinner'
import SetMembersList from './setMembersList'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useGetEntityMembersQuery } from '../../../../features/entityMembers/entityMembersSlice'

function ModifyEntity ({ id }) {
  const { t } = useTranslation()
  const user = useSelector(selectCurrentUser)
  const idUser = user.id
  const { data: entities, isSuccess, isLoading, isError, Error } = useGetEntitiesAdminQuery(idUser)
  const [entity, setEntity] = useState(undefined)
  const { data: entityMembers } = useGetEntityMembersQuery(entity?.entity_members)

  React.useEffect(() => {
    if (entities && isSuccess) {
      const entity = entities.entities[id]
      setEntity({
        entity_members: entity.entity_members,
        id: entity.id,
        name: entity.name,
        employee_Number: entity.employee_Number,
        activity_sector: entity.activity_sector
      })
    }
    if (entityMembers) {
      setEntity({ ...entity, users: entityMembers?.members ? entityMembers.members : [] })
    }
  }, [entities, isSuccess, entityMembers])
  const disable = false

  if (isLoading) {
    return (
  <div className='flex justify-center'>
  <Spinner/>
  </div>)
  }
  if (isSuccess && entity) {
    return (
<section className="flex flex-col  flex-wrap  pb-4 ">
      <div className="pl-2">
        <h5 className="block text-2xl font-medium text-[#065863] ">{t('consultant.organisation.edit.generalInformation')}</h5>
        <ModifyGeneralInfo entity={entity} user="consultant" changeEntity={setEntity}/>
      </div>
      <div className="pl-2">
        <h5 className="block text-2xl font-medium text-[#065863] ">{t('consultant.organisation.edit.orgMember')}</h5>
        <SetMembersList onChangeEntity ={setEntity} entity={entity} members={entityMembers?.members ? entityMembers.members : []}/>
      </div>
      <div className="mr-8">
      <EditEntityLoader entity={entity}>
          <button className={!disable
            ? 'bg-teal-600 mx-2 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1'
            : 'bg-teal-600 mx-2 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1 opacity-50' } disabled={disable}>
              <h5 className="text-white px-1">{t('common.button.modify')}</h5>
          </button>
      </EditEntityLoader>
      </div>
    </section>
    )
  }
  if (isError) {
    return <div> {Error} </div>
  }
}
export default ModifyEntity
