import React from 'react'
import DynamicNavBar from '../../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions, transformModel } from '../../DynamicNavbarOptions'
import { useGetSingleTansformMethodQuery, useGetSurveyQuestionQuery } from '../../../../features/transformQuestion/transformMethodSlice'
import QuestionTransformList from '../tables/questionTransformList'
import { useTranslation } from 'react-i18next'
import TransformMethodDetails from './transformMethodDetails'
import QuestionDetails from './questionDetails'
import { useParams } from 'react-router-dom'
import CheckBox from '../utils/checkBox'

function TransformModel () {
  const { t } = useTranslation()
  const { idTransform } = useParams()
  const [question, setQuestion] = React.useState()
  const [questionInfo, setQuestionInfo] = React.useState({})
  const [showModèleInfo, setShowModèleInfo] = React.useState(false)
  const { data: transformMethod } = useGetSingleTansformMethodQuery(idTransform || 'vburebv')
  const { data: questions } = useGetSurveyQuestionQuery(transformMethod?.survey ? transformMethod.survey : 'test')
  React.useEffect(() => {
    if (question && transformMethod) {
      if (question.id) {
        setQuestionInfo(transformMethod.questions[question.id])
      }
    }
  }, [question, transformMethod])
  return (
    <div className="w-full pb-16">
      <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
      <DynamicNavBar option="navbar.surveyOptions.tranformation_model" options={surveyOptions}/>
      <DynamicNavBar option="navbar.transformModel.transformation_model_list" options={transformModel}/>
        <div className="w-full flex flex-col items-center  static mt-4" >
          <div className="w-full bg-wite pb-4">
            <h5 className="text-3xl font-medium text-[#444444] w-max-fit px-3">{t('admin.survey.transformation_model.title.modify_transformationQuestion')}</h5>
              <div className="flex gap-1 mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-2">
                  <div className='w-1/2 p-3'>
                    <CheckBox text='admin.survey.transformation_model.create.fields.show_transformationModelInfo' value={showModèleInfo} onChange={(val) => setShowModèleInfo(val)} disabled={false}/>
                    {showModèleInfo &&
                    <TransformMethodDetails transformMethod={transformMethod || {}}/>}
                    <h5 className="text-xl font-medium text-[#444444] w-max-fit py-1">{t('admin.survey.transformation_model.create.fields.question_list')}</h5>
                    <QuestionTransformList data={questions || []}
                  onChangeQuestion={(val) => setQuestion(val)}/></div>
                  {question &&
                  <div className='w-1/2'>
                    <QuestionDetails globalFormulas ={transformMethod?.global_parameters?.global_formulas || {}} question={question || {}} questionFormulas={questionInfo?.formulas || []}/>
                  </div>
                }
              </div>
          </div>
        </div>
    </div>
  )
}
export default TransformModel
