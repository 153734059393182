// ImportAdditionalParamsTable.js
import React from 'react'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { useTranslation } from 'react-i18next'
import { FilterMatchMode } from 'primereact/api'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'

const ImportAdditionalParamsTable = ({ data }) => {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: data,
    stateKey: 'dt-state-import-additional-list',
    onFilter: (e) => setFilters(e.filters),
    header,
    globalFilterFields: ['UUID', 'QUESTION_UUID', 'QUESTION_REFERENCE', 'UNIT', 'MAX', 'MIN', 'DENOMINATOR'],
    filters,
    style: { fontSize: '13px' },
    dataKey: 'type',
    emptyMessage: t('admin.survey.import_export.parametres_list_notFound'),
    columns: [
      { field: 'UUID', sortable: true, header: t('admin.survey.import_export.UUID') },
      { field: 'QUESTION_UUID', sortable: true, header: t('admin.survey.import_export.QUESTION_UUID') },
      { field: 'QUESTION_REFERENCE', sortable: true, header: t('admin.survey.import_export.QUESTION_REFERENCE') },
      { field: 'UNIT', sortable: true, header: t('admin.survey.import_export.UNIT') },
      { field: 'MAX', sortable: true, header: t('admin.survey.import_export.MAX') },
      { field: 'MIN', sortable: true, header: t('admin.survey.import_export.MIN') },
      { field: 'DENOMINATOR', sortable: true, header: t('admin.survey.import_export.DENOMINATOR') }
    ]
  }

  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div className="datatable-rowgroup-demo max-h-500 overflow-auto border-1 border-gray-200 mb-2">
      {DataTableComponent}
    </div>
  )
}

export default ImportAdditionalParamsTable
