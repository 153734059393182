import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { useTranslation } from 'react-i18next'

export const template2 = {
  layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
  RowsPerPageDropdown: (options) => {
    const dropdownOptions = [
      { label: 30, value: 30 },
      { label: 50, value: 50 },
      { label: 100, value: 100 }
    ]
    const { t } = useTranslation()
    return (
          <React.Fragment>
              <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>{t('common.table.elements_by_page')} </span>
              <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
          </React.Fragment>
    )
  },
  CurrentPageReport: (options) => {
    const { t } = useTranslation()
    return (
          <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
              {options.first} - {options.last} {t('common.table.elements_of')} {options.totalRecords}
          </span>
    )
  }
}
