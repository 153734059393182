import React from 'react'
import EntityListTable from './table/entityListTable'
import { useGetEntitiesOwnerQuery } from '../../../features/entitiesClient/entitiesClientSlice'
import { selectCurrentUser } from '../../../features/auth/authSlice'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

function EntityList () {
  const { t } = useTranslation()
  const user = useSelector(selectCurrentUser)
  const { data: entities } = useGetEntitiesOwnerQuery(user.id)

  return (
    <div className="w-full pb-16">
      <div className="w-full flex flex-col items-center gap-8  static mt-4" >
        <div className="w-7/8 sm:w-5/6 md:w-2/3 lg:w-2/3  bg-wite md:mx-8 pb-8 ">
          <h5 className="md:text-2xl sm:text-xl text-md  font-medium text-[#444444] w-min-fit">{t('liste des organisations')}</h5>
            <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4">
              <div>
                  <EntityListTable entities={entities?.entities ? Object.values(entities?.entities) : []}/>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default EntityList
