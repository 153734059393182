import React, { useLayoutEffect } from 'react'
import { OrgChart } from 'd3-org-chart'
import * as d3 from 'd3'

const OrgChartComponent = (props, ref) => {
  const d3Container = React.createRef(null)
  let dataOrg = []
  dataOrg = [...props.data]
  let chart = null

  function addNode (node) {
    chart.addNode(node)
  }
  props.setClick(addNode)

  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (props.data && d3Container.current) {
      if (!chart) {
        chart = new OrgChart()
      }
      chart
        .container(d3Container.current)
        .data(dataOrg)
        .nodeHeight((d) => 90)
        .nodeWidth((d) => {
          return 250
        })
        .childrenMargin((d) => 50)
        .compactMarginBetween((d) => 25)
        .compactMarginPair((d) => 50)
        .neightbourMargin((a, b) => 25)
        .siblingsMargin((d) => 25)
        .nodeContent(function (d, i, arr, state) {
          const color = '#FFFFFF'
          let colorSelect = '#E4E2E9'
          if (d.data.id == props.chosenNode.node) {
            colorSelect = '#a7d1a7'
          }
          return `
            <div style="font-family: 'Inter', sans-serif;background-color:${color};margin-top:-1px; 
            margin-left:-1px;width:${d.width}px;height:${d.height}px;border-radius:10px;border: 1px solid ${colorSelect}">       
              <div style="color:#08011E;position:absolute;right:20px;top:17px;font-size:10px;"><i class="fas fa-ellipsis-h"></i></div>
              <div style="font-size:15px;color:#08011E;margin-left:20px;margin-top:18px"> ${
                d.data.title
              } </div>
              <div style="display: flex;gap:5px;flex-direction: row; margin-top:5px">
              <div style="color:#716E7B;margin-left:20px;margin-top:3px;font-size:10px;"> ${
                d.data.questions_number ? d.data.questions_number : 0
              }  questions  (${d.data.question ? d.data.question.length : 0}) </div> 
              <div style="color:#716E7B;margin-left:20px;margin-top:3px;font-size:10px;"> ${
                d.data.nodes_number ? d.data.nodes_number : 0
              }  noeuds </div> 
              </div> 
           </div>`
        })
        .onNodeClick((d, i, arr) => {
          props.setChosedNode({ ...props.chosenNode, node: d })
        })
        .buttonContent(({ node, state }) => {
          return `<div style="color:#716E7B;border-radius:5px;padding:2px;font-size:10px;margin:auto auto;background-color:white;
          border: 1px solid #E4E2E9"> <span style="font-size:9px">${
              node.children
                ? '</br><'
                : '</br>>'
            }</span> ${node.data._directSubordinates}  </div>`
        })
        .linkUpdate(function (d, i, arr) {
          d3.select(this)
            .attr('stroke', (d) =>
              d.data._upToTheRootHighlighted ? '#152785' : '#E4E2E9'
            )
            .attr('stroke-width', (d) =>
              d.data._upToTheRootHighlighted ? 5 : 1
            )

          if (d.data._upToTheRootHighlighted) {
            d3.select(this).raise()
          }
        })
        .render()
    }
  }, [props.data, d3Container.current, props.chosenNode])

  return (
    <div>
      <div ref={d3Container} />
    </div>
  )
}
export default OrgChartComponent
