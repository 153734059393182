// utilsLibrary.js
import React from 'react'

export const GenerateBodyTemplate = (path, idKey, titleKey) => (rowData) => {
  const id = rowData[idKey]
  const title = rowData[titleKey]

  return (
    <div
      className='min-h-16 w-full'
      onClick={() => navigate(`${path}/${id}/edit`)}
      style={{ cursor: 'pointer' }}
    >
      <React.Fragment>
        <span className="image-text">{title}</span>
      </React.Fragment>
    </div>
  )
}
