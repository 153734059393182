import React from 'react'
import { getFormat } from '../components/prepareOutoutResult'
import OrgChartTree from './orgChartTree'
import { Spinner } from '../../../../components/loadData/spinner'
import { useTranslation } from 'react-i18next'

function EvaluationTreeCharts ({ evaluation, isLoading, isSuccess }) {
  const { t } = useTranslation()
  const [evaluationData, setEvaluationData] = React.useState()
  const [selectedCategory, setSelectedCategory] = React.useState()
  const [evaluationTree, setEvaluationTree] = React.useState()

  React.useEffect(() => {
    if (evaluation) {
      setEvaluationData(evaluation)
    }
  }, [evaluation])

  React.useEffect(() => {
    if (evaluationData) {
      setEvaluationTree(getFormat(evaluationData))
    }
  }, [evaluationData])

  if (isLoading) {
    return (
      <div className="mt-8 ">
        <Spinner/>
      </div>
    )
  }
  const inputData = ({ title, text }) => {
    return (
      <div className="w-4/5 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
      <label htmlFor="nom"
        className="block text-md font-medium text-gray-800">
          {title}
      </label>
      <input type="text" name="nom" id="nom"
        className="focus:ring-teal-500
      focus:border-teal-500   w-full pl-2 pr-12 text-lg  text-[#444444]
      rounded-sm" style={{ border: '1px solid #e8e6e6' }}
      disabled={true} value={text}
      />
    </div>
    )
  }

  const Score = ({ score }) => {
    const { numerator, denominator } = score
    const scoreNumber = (numerator / denominator) * 20

    if (scoreNumber >= 15) {
      return (
      <span className="bg-green-300 text-gray-50 w-16 text-center rounded-md p-1">{scoreNumber + ' / 20'}</span>)
    }
    if (scoreNumber < 15 && scoreNumber > 10) {
      return (
      <span className="bg-yellow-300 text-gray-50 w-16 text-center rounded-md p-1">{scoreNumber + ' / 20'}</span>)
    }
    if (scoreNumber < 15) {
      return (
      <span className= "bg-red-300 text-gray-50 w-16 text-center rounded-md p-1">{scoreNumber + ' / 20'}</span>)
    }
  }
  if (isSuccess && evaluationTree) {
    return (
    <div className="flex justify-center w-full mt-16 gap-2 h-500">
      <div className="bg-white rounded-md justify-between p-1 h-full w-1/3 ">
        <div className='flex'>
        <div className="flex flex-col w-1/2">
        <h5 className="block text-xl font-medium text-gray-800">{t('consultant.evaluation.result.evaluationDetail')}</h5>
        <div className="flex gap-1 flex-col  w-full pb-4">
          <inputData title={'Nom de réferance'} text={evaluation.name && evaluation.name} />
          <inputData title={'Date de création'} text={evaluation.add_date && evaluation.add_date} />
          <inputData title={'Méthode évaluation'} text={evaluation?.report_data?.evaluationMethod && evaluation.report_data.evaluationMethod} />
          <div className="pl-3">
            <h5 className="block text-md font-medium text-gray-800">{t('consultant.evaluation.result.score')}</h5>
            <Score score= {evaluation?.properties?.score}/>
          </div>
        </div>
        </div>
        {selectedCategory
          ? <div className="flex flex-col justify-start float-left">
          <h5 className="block text-xl font-medium text-gray-800">{t('consultant.evaluation.result.categoryDetail')}</h5>
          <h5 className="block text-md font-medium text-gray-800">{t('consultant.evaluation.result.categoryName')}</h5>
          <input type="text" name="nom" id="nom"
            className="focus:ring-teal-500
          focus:border-teal-500   w-3/4 pl-2 pr-12 text-lg  text-[#444444]
          rounded-sm" style={{ border: '1px solid #e8e6e6' }}
          disabled={true} value={selectedCategory?.name}
           />
            <h5 className="block text-md font-medium text-gray-800">{t('consultant.evaluation.result.score')}</h5>
            <Score score= {selectedCategory?.properties?.score}/>
        </div>
          : undefined
      }
      </div>
      </div>
      <div className="mx-auto w-2/3 h-full float-right flex justify-center" >
      <OrgChartTree data={evaluationTree} onChangeCategory={(val) => setSelectedCategory(val)}/>
      </div>
    </div>
    )
  }
}
export default EvaluationTreeCharts
