import React from 'react'
import { useTranslation } from 'react-i18next'

const Input = ({ placeholder, text, value, onChange, disabled, onBlur }) => {
  const { t } = useTranslation()
  return (
        <div className=''>
            <label
                htmlFor="price"
                className="block text-lg font-medium text-gray-800">
                {t(text)}
            </label>
            <input
                placeholder={t(placeholder)} type="text" name="price" id="price"
                className="focus:ring-teal-500 focus:border-teal-500 block
                w-full pl-2 pr-12 tsext-lg  text-[#444444] rounded-sm"
                style={{ border: '1px solid #e8e6e6' }} disabled={ disabled }
                value={value || ''} onChange={(e) => onChange(e.target.value)}
                onBlur={() => onBlur()}
            />
        </div>
  )
}

export default Input

// 'transformQuestion.titre'
