import React from 'react'
import ModifyGeneralInfo from './components/modifyEntity/modifyGeneralInfo'
import ModifySurveys from './components/modifyEntity/modifySurveys'
import ModifymainCustomer from './components/modifyEntity/modifymainCustomer'
import EditEntityLoader from './components/modifyEntity/editEntityLoader'
import { selectCurrentUser } from '../../features/auth/authSlice'
import { useGetEntitiesAdminQuery } from './entitiesSlice'
import { Spinner } from '../../components/loadData/spinner'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectAllSurveys, useGetAllSurveysQuery } from '../surveys/surveysSlice'

function ModifyEntity ({ id }) {
  const { t } = useTranslation()
  const user = useSelector(selectCurrentUser)
  const idUser = user.id
  const { data: entities, isSuccess, isLoading, isError, Error } = useGetEntitiesAdminQuery(idUser)

  const [entity, setEntity] = React.useState(undefined)
  React.useEffect(() => {
    if (entities && isSuccess) {
      setEntity(entities.entities[id])
    }
  }, [entities, isSuccess])

  const disable = false
  const [surveys, setSurveys] = React.useState()
  const { data } = useGetAllSurveysQuery()
  const surveys_trees = useSelector(selectAllSurveys)
  React.useEffect(() => {
    if (surveys_trees) {
      setSurveys(surveys_trees)
    }
  }, [surveys_trees])

  if (isLoading) {
    return (
  <div className='flex justify-center'>
  <Spinner text="hell"/>
  </div>)
  }
  if (isSuccess && entity) {
    return (
<section className="flex flex-col  flex-wrap gap-1 pb-4 ">
      <div className="pl-2">
        <h5 className="block text-2xl font-medium text-[#065863] ">{t('admin.organisation.edit.generalInformation')}</h5>
        <ModifyGeneralInfo entity={entity} changeEntity={setEntity}/>
      </div>
      <div className="pl-2">
        <h5 className="block text-2xl font-medium text-[#065863] ">{t('admin.organisation.edit.survey')}</h5>
        <ModifySurveys entity={entity} surveys={surveys} changeEntity={setEntity}/>
      </div>
      <div className="pl-2">
        <h5 className="block text-2xl font-medium text-[#065863] ">{t('admin.organisation.edit.consultant_principal')}</h5>
        <ModifymainCustomer entity={entity} changeEntity={setEntity}/>
      </div>
      <div className="mr-8">
        {<EditEntityLoader entity={entity}>
          <button className={!disable
            ? 'bg-[#086e7c] rounded-sm mt-2 float-right p-1 px-2'
            : 'bg-[#086e7c] rounded-sm mt-8 float-right p-1 opacity-50 px-2' } disabled={disable}>
              <h5 className="text-white px-1">{t('common.buttons.modify')}</h5>
          </button>
        </EditEntityLoader>}
      </div>
    </section>
    )
  }
  if (isError) {
    return <div> {Error} </div>
  }
}
export default ModifyEntity
