import React from 'react'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions } from '../DynamicNavbarOptions'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CreateNewSurvey from './export/createNewSurvey'
function ExportSurvey () {
  const { t } = useTranslation()
  const { idSurvey } = useParams()

  return (
    <div className="w-full pb-16">
    <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
    <DynamicNavBar option="navbar.surveyOptions.suveys_list" options={surveyOptions}/>
      <div className="w-full flex flex-col items-center gap-8  static mt-4" >
        <div className="md:w-1/2 sm:w-2/3 bg-wite md:mx-8 pb-8">
          <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('admin.survey.export_surey.export_survey')}</h5>
            <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4">
                <div className="flex justify-between w-full">
                  <CreateNewSurvey idSurvey={idSurvey || null}/>
                </div>
            </div>
          </div>
      </div>
  </div>
  )
}

export default ExportSurvey
