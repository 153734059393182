import React from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import './style.css'
import BeatLoader from 'react-spinners/BeatLoader'
import { useModifyAdminMutation } from '../usersSlice'
import { useNavigate } from 'react-router-dom'
import IndexAlert from '../../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'

const ModifierUser = ({ children, user }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [modifyAdmin, { isLoading, isSuccess, isError, error }] = useModifyAdminMutation()

  const HandleAddCampaign = async () => {
    if (user.type === 'CONSULTANT') {
      user = { ...user, type: 'CLIENT' }
    }
    await modifyAdmin(user)
  }
  const ref = React.useRef()
  const closeTooltip = () => {
    ref.current.close()
    if (isSuccess) { navigate('/admin/users') }
  }

  return (
    <div>
    <Popup
        closeOnDocumentClick={false}
        trigger={children}
        onOpen={() => HandleAddCampaign()}
        contentStyle={{ width: '400px', heigh: '300px' }}
        modal
        ref={ref}
    >
      {close => (
        <div className="modal">
            <div className="content flex flex-col justify-center items-center ">
                <h5 className="#444 text-lg ">{t('common.popup.modifyUser')}</h5>
                <div className="mt-5">
                  {isLoading && <BeatLoader color="#086e7c"/>}
                  {isSuccess && <div className="flex flex-col gap-1">
                    <IndexAlert type="success" text={t('common.popup.modifyUserSuccess')}/>
                  </div>}
                  {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)}/> </div>}
                </div>
            </div>
          <div className="actions">
          <button className="p-1 px-2 text-lg bg-gray-300 rounded-sm" onClick={() => closeTooltip()} style={{ cursor: 'pointer' }}>{t('common.popup.close')}</button>
          </div>
        </div>
      )}
    </Popup>
    </div>
  )
}
export default ModifierUser
