import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import BaseQuestion from './baseQuestion'

const CheckboxQ = (props) => {
  const question = { questions: ['something', 'something'] }
  return (
    <BaseQuestion>

      <h5 className="text-xl">{props.question.text}</h5>
          {question.questions.map((q) =>
          <div className="flex justify-between " key={q}>
            <h5 className="pt-2">{q}</h5>
            <Checkbox/>
          </div>)}
    </BaseQuestion>
  )
}

export default CheckboxQ
