import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteAdmin from './deleteAdmin'
import LockIcon from '@mui/icons-material/Lock';
import BlockAdmin from './blockAdmin'
import UnBlockAdmin from './unBlockAdmin'
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom'

export const ActionTemplate = ({ e, currentUser }) => {
  const navigate = useNavigate()
  return (
      <div className="flex gap-1">
        {currentUser.type === 'SUPERADMIN'
          ? e.status_user === 'ALLOWED'
            ? <>
            <EditIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/admin/users/edit/${e.id}`)} />
            <DeleteAdmin idAdmin={e.id}><DeleteIcon sx={{ color: '#f87171', cursor: 'pointer', fontSize: 25 }}/></DeleteAdmin>
            <BlockAdmin idAdmin={e.id}><LockIcon sx={{ color: '#f87171', cursor: 'pointer' }}/></BlockAdmin>
          </>
            : <>
            <EditIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/admin/users/edit/${e.id}`)} />
            <DeleteAdmin idAdmin={e.id}><DeleteIcon sx={{ color: '#f87171', cursor: 'pointer', fontSize: 25 }}/></DeleteAdmin>
            <UnBlockAdmin idAdmin={e.id}><LockOpenIcon sx={{ color: '#0d9488', cursor: 'pointer' }}/></UnBlockAdmin>
          </>
          : undefined}
        {currentUser.type === 'ADMIN'
          ? e.status_user === 'ALLOWED' && e.type === 'CLIENT'
            ? <>
          <EditIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/admin/user/edit/${e.id}`)} />
          <DeleteAdmin idAdmin={e.id}><DeleteIcon sx={{ color: '#f87171', cursor: 'pointer', fontSize: 25 }}/></DeleteAdmin>
          <BlockAdmin idAdmin={e.id}><LockIcon sx={{ color: '#f87171', cursor: 'pointer' }}/></BlockAdmin>
        </>
            : <>
          <EditIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/admin/user/edit/${e.id}`)} />
          <DeleteAdmin idAdmin={e.id}><DeleteIcon sx={{ color: '#f87171', cursor: 'pointer', fontSize: 25 }}/></DeleteAdmin>
          <UnBlockAdmin idAdmin={e.id}><LockOpenIcon sx={{ color: '#0d9488', cursor: 'pointer' }}/></UnBlockAdmin>
        </>
          : undefined}
      </div>
  )
}
