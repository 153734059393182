import React from 'react'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import 'primeicons/primeicons.css'
import { Dropdown } from 'primereact/dropdown'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.css'
import 'primeflex/primeflex.css'
import { FilterMatchMode } from 'primereact/api'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'

function EntitiesList ({ userList, changeUserList, setSelectedData, selectedData }) {
  const { t } = useTranslation()
  const template2 = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',

    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 }
      ]

      return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>{t('common.table.elements_by_page')}</span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
      )
    },
    CurrentPageReport: (options) => {
      return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} {t('common.table.elements_of')} {options.totalRecords}
                </span>
      )
    }
  }

  const actionTemplate = (e) => {
    return (
          <div className="flex gap-2">
            <DeleteIcon sx={{ color: 'red', cursor: 'pointer' }} onClick={() => changeUserList(userList.filter((user) => user.id !== e.id))}/>
          </div>
    )
  }
  const navigate = useNavigate()
  const [first2, setFirst2] = React.useState(0)
  const [rows2, setRows2] = React.useState(5)
  const [filters1, setFilters1] = React.useState(null)
  const [userListData, setUserListData] = React.useState()

  React.useEffect(() => {
    initFilters1()
  },
  [])

  React.useEffect(() => {
    setUserListData(userList)
  }, [userList])

  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    const _filters1 = { ...filters1 }
    _filters1.global.value = value

    setFilters1(_filters1)
    setGlobalFilterValue(value)
  }

  const initFilters1 = () => {
    setFilters1({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } })
  }

  const renderHeader2 = () => {
    return (
        <div className="flex justify-content-end">
            <span className="p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t('common.table.search')} className="h-8" />
            </span>
        </div>
    )
  }
  const onRowEditComplete = (e) => {
    const _userListData = [...userListData]
    const { newData, index } = e

    _userListData[index] = newData

    changeUserList(_userListData)
  }
  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
  }
  const Header = renderHeader2()
  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300  ">
          <DataTable value={userListData} responsiveLayout="scroll" paginator paginatorTemplate={template2} dataKey="id" editMode="row"
           onRowEditComplete={onRowEditComplete} emptyMessage={t('admin.organisation.list.member_list_noFound')}
                first={first2} rows={rows2} header={Header} selectionMode="checkbox" filters={filters1}
                 selection={selectedData} onSelectionChange={e => setSelectedData(e.value)} globalFilterFields={['name', 'first_name', 'last_name', 'email', 'post']}>
                <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                <Column field="last_name" sortable header={t('admin.organisation.create.tables.last_name')} editor={(options) => textEditor(options)} style={{ width: '10%' }}></Column>
                <Column field="first_name" sortable header={t('admin.organisation.create.tables.first_name')} editor={(options) => textEditor(options)} style={{ width: '10%' }}></Column>
                <Column field="email" sortable header={t('admin.organisation.create.tables.adresse_email')} editor={(options) => textEditor(options)} style={{ width: '30%' }}></Column>
                <Column field="post" sortable header={t('admin.organisation.create.tables.post')} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                <Column body={(e) => actionTemplate(e)} sortable header={t('common.table.action')}></Column>
                <Column rowEditor headerStyle={{ width: '5%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
          </DataTable>
        </div>
    </div>
  )
}
export default EntitiesList
