import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const commentsAdapter = createEntityAdapter({
})
const initialState = commentsAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const CommentsApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'customersSlice',
  endpoints: builder => ({
    getComments: builder.query({
      query: (data) => `/comment/get_campaign_user_comments/${data.campaign}/${data.user}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['SurveyComments']
    }),
    getQuestionsComments: builder.query({
      query: (data) => `/comment/get_question_comments/${data.campaign}/${data.question}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['SurveyComments']
    }),
    createComment: builder.mutation({
      query: (data) => ({
        url: '/comment/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['SurveyComments']
    }),
    updateComment: builder.mutation({
      query: (data) => ({
        url: `/comment/update/${data.id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['SurveyComments']
    }),
    deleteComment: builder.mutation({
      query: (id) => ({
        url: `/comment/delete/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['SurveyComments']
    }),
    getEvaluationComments: builder.query({
      query: (id) => `/comment/evaluation_comments/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['SurveyComments']
    })
  })
})

export const {
  useGetQuestionsCommentsQuery,
  useGetCommentsQuery,
  useGetEvaluationCommentsQuery,
  useCreateCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation
} = CommentsApiSlice

export const selectCommentResult = CommentsApiSlice.endpoints.getComments.select()

export const selectCommentsData = createSelector(
  selectCommentResult,
  commentsResult => commentsResult.data
)

export const {
  selectAll: selectAllComments,
  selectById: selectSingleComments
} = commentsAdapter.getSelectors(state => selectCommentsData(state) ?? initialState)
