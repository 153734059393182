import React from 'react'
import ChatIcon from '@mui/icons-material/Chat'
import InfoIcon from '@mui/icons-material/Info'
import QuestionComment from './questionComment'
import CommentsList from './commentsList'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useGetQuestionsCommentsQuery } from '../../features/SurveyComments/surveyCommentsSlice'
import DraftsIcon from '@mui/icons-material/Drafts'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
import DeleteIcon from '@mui/icons-material/Delete'
import IndexAlert from '../../components/alerts/indexAlert'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import { useTranslation } from 'react-i18next'

function BaseQuestion (props) {
  const { idCampaign, idUser } = useParams()
  const [showComment, setShowComment] = React.useState(false)
  const { t } = useTranslation()
  const [showHelp, setShowHelp] = React.useState(false)
  const [allowComments] = React.useState(props.showComments)
  const [reponse, setReponse] = React.useState(props.reponse)
  const { data: comments } = useGetQuestionsCommentsQuery({ campaign: idCampaign, question: props.question?.uuid ? props.question.uuid : null })
  const handleComment = () => {
    setShowComment(!showComment)
  }
  const handleHelpText = () => {
    setShowHelp(!showHelp)
  }
  React.useEffect(() => {
    setReponse(props.reponse)
  }, [props.reponse])

  return (
    <div className="bg-white rounded-lg p-2  flex flex-col " style={{ border: '1px solid #ededed' }}>
      <div className='flex justify-between'>
        <div className='flex flex-col w-full'>
          <div>
            {props.children}
          </div>
          <div className='m-3'>
          {props.displayComments &&
            <CommentsList commentUser={props.commentUser} question={props.question?.uuid ? props.question.uuid : null} />
          }
          {showComment &&
            <div className='pt-1 mt-1'>
              <QuestionComment commentUser={props.commentUser} comment={props.comment}
                origin_question={props.question?.question_uuid ? props.question.question_uuid : null}
                question={props.question?.uuid ? props.question.uuid : null} />
            </div>
          }
           {showHelp && props.questionHelpText
             ? <div className='pt-1 mt-1'><IndexAlert type="info" text={props.questionHelpText} /> </div>
             : undefined}
        </div>
        </div>
        <div className='flex flex-col items-end  justify-end min-w-fit'>
          <div className='flex'>
            {props.questionHelpText &&
              <div>
                <InfoIcon style={{ cursor: 'pointer', fill: '#228FCD' }} onClick={() => handleHelpText()} />
              </div>
            }

            {(!props.disabled && props?.reponse?.state !== 'validated') && <SkipNextIcon style={{ fill: reponse?.state !== 'ignored' ? '#C7EB87' : '#FFC300' }} onClick={() => props.sendData(reponse?.state !== 'ignored' ? 'ignored' : 'draft')} />}
            {(!props.disabled && props?.reponse?.state !== 'validated') && <DeleteIcon style={{ fill: reponse?.state !== 'delete' ? '#C7EB87' : '#EF685F' }} onClick={() => props.sendData('delete')} />}

            <div className="relative ">
              <div className="absolute bottom-auto left-auto right-1 top-1 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0
          skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-[#de8c8c] px-1.5 py-1 text-center align-baseline text-xs
          font-bold leading-none text-white">
                {!comments
                  ? 0
                  : comments.length}
              </div>
              <ChatIcon style={{ cursor: 'pointer', fill: comments?.length === 0 ? '#444' : '#72C17C' }} onClick={() => handleComment()} />
            </div>

          </div>
          <div className='flex items-end justify-end h-full pt-5 '>
            {renderContent(props?.reponse?.state || 'unanswered')}
          </div>
        </div>

      </div>

    </div>
  )
}
BaseQuestion.propTypes = {
  children: PropTypes.node,
  question: PropTypes.object,
  displayComments: PropTypes.bool,
  commentUser: PropTypes.string,
  comment: PropTypes.object,
  showComments: PropTypes.bool,
  disabled: PropTypes.bool,
  questionHelpText: PropTypes.string,
  reponse: PropTypes.object,
  sendData: PropTypes.func
}

export default BaseQuestion

const renderContent = (caseInput) => {
  const { t } = useTranslation()
  switch (caseInput) {
    case 'validated':
      return <div className='flex '><CheckCircleOutlineIcon style={{ fill: '#16AD56' }} /> <p className='text-[#16AD56] pt-1'>{t('survey.states.validated')}</p></div>
    case 'draft':
      return <div className='flex '><DraftsIcon style={{ fill: '#228FCD' }} /><p className='text-[#228FCD] pt-1'>{t('survey.states.draft')}</p></div>
    case 'unanswered':
      return <div className='flex'><DoNotDisturbOnIcon style={{ fill: '#B8B8B8' }} /><p className='text-[#B8B8B8] pt-1'>{t('survey.states.unanswered')}</p></div>
    case 'delete':
      return <div className='flex'><DoNotDisturbOnIcon style={{ fill: '#EF685F' }} /><p className='text-[#EF685F] pt-1'>{t('survey.states.delete')}</p></div>
    case 'ignored':
      return <div className='flex'><SkipNextIcon style={{ fill: '#FFC300' }} /><p className='text-[#FFC300] pt-1'>{t('survey.states.ignored')}</p></div>
    default:
      return null
  }
}
