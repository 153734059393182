import React from 'react'
import { useTranslation } from 'react-i18next'

const CheckBox = ({ text, value, onChange, disabled }) => {
  const { t } = useTranslation()
  return (
    <div>
        <input className="form-check-input   border border-gray-300 rounded-sm
          bg-white checked:bg-teal-600 checked:border-teal-600 focus:outline-none transition duration-200 mt-1
          align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" style={{ width: '19px', height: '19px' }}
          value={value} id="flexCheckChecked" disabled= { disabled }
          onChange={(e) => onChange(!value)}/>
          <label className="form-check-label inline-block text-gray-800 text-lg" htmlFor="flexCheckChecked" style={{ cursor: 'pointer' }}>
            {t(text)}
          </label>
    </div>
  )
}

export default CheckBox
