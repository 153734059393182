import React from 'react'
import { useTranslation } from 'react-i18next'

function EntityFields ({ entityInfos, addSiretCode, onChangeEntity }) {
  const { t } = useTranslation()
  const [entityInfoData, setEntityInfoData] = React.useState({})
  React.useEffect(() => {
    if (entityInfos) {
      setEntityInfoData(entityInfos)
    } else {
      setEntityInfoData({})
    }
  }, [entityInfos])
  const handleChangeEntity = (key, value) => {
    console.log(key, value)
    if (!addSiretCode) {
      onChangeEntity({ ...entityInfos, [key]: value })
    }
  }
 
  return (
    <div>
        <div className="md:w-1/2 w-4/5 px-3 mb-3 md:mb-0 mt-1 relative rounded-sm ">
          <label htmlFor="price" className="block text-lg font-medium text-gray-800">
            {t('admin.organisation.create.fields.entity_name')}
          </label>
          <input type="text" name="price" value={entityInfoData?.entityName || '' } onChange={(e) => handleChangeEntity('entityName', e.target.value)} id="price" className="focus:ring-teal-500
          focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}
          disabled={addSiretCode}/>
        </div>
        <div className="md:w-1/2 w-4/5 px-3 mb-3 md:mb-0 mt-1 relative rounded-sm ">
          <label htmlFor="price" className="block text-lg font-medium text-gray-800">
            {t('admin.organisation.create.fields.nafCode')}
          </label>
          <input type="text" name="price" value={entityInfoData?.nafCode || '' } onChange={(e) => handleChangeEntity('nafCode', e.target.value)} id="price" className="focus:ring-teal-500
          focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}
          disabled={addSiretCode}/>
        </div>
        <div className="md:w-1/2 w-4/5 px-3 mb-3 md:mb-0 mt-1 relative rounded-sm ">
          <label htmlFor="price" className="block text-lg font-medium text-gray-800">
            {t('admin.organisation.create.fields.entityAdress')}
          </label>
          <input type="text" name="price" value={entityInfoData?.adress || '' } onChange={(e) => handleChangeEntity('adress', e.target.value)} id="price" className="focus:ring-teal-500
          focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}
          disabled={addSiretCode}/>
        </div>
        <div className="md:w-1/2 w-4/5 px-3 mb-3 md:mb-0 mt-1 relative rounded-sm ">
          <label htmlFor="price" className="block text-lg font-medium text-gray-800">
            {t('admin.organisation.create.fields.nic')}
          </label>
          <input type="text" name="price" value={entityInfoData?.nic || ''} onChange={(e) => handleChangeEntity('nic', e.target.value)}id="price" className="focus:ring-teal-500
          focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}
          disabled={addSiretCode}/>
        </div>
        <div className="md:w-1/2 w-4/5 px-3 mb-3 md:mb-0 mt-1 relative rounded-sm ">
          <label htmlFor="price" className="block text-lg font-medium text-gray-800">
            {t('admin.organisation.create.fields.siret_Siret')}
          </label>
          <input type="text" name="price" value={entityInfoData?.siret || ''} onChange={(e) => handleChangeEntity('siret', e.target.value)}id="price" className="focus:ring-teal-500
          focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}
          disabled={addSiretCode}/>
        </div>
        <div className="md:w-1/2 w-4/5 px-3 mb-3 md:mb-0 mt-1 relative rounded-sm ">
          <label htmlFor="price" className="block text-lg font-medium text-gray-800">
            {t('admin.organisation.create.fields.siren_Number')}
          </label>
          <input type="text" name="price" value={entityInfoData?.siren || ''} onChange={(e) => handleChangeEntity('siren', e.target.value)}id="price" className="focus:ring-teal-500
          focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}
          disabled={addSiretCode}/>
        </div>
        <div className="md:w-1/2 w-4/5 px-3 mb-1 md:mb-0 mt-1 relative rounded-sm ">
          <label htmlFor="price" className="block text-lg font-medium text-gray-800">
            {t('admin.organisation.create.fields.activitysector')}
          </label>
          <input type="text" name="price" value={entityInfoData?.activitysector || ''} onChange={(e) => handleChangeEntity('activitysector', e.target.value)}id="price" className="focus:ring-teal-500
          focus:border-teal-500 block w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}
          disabled={addSiretCode}/>
        </div>
    </div>
  )
}

export default EntityFields
