import React from 'react'
import { useGetCustomersQuery, selectAllCustomers } from '../../../features/customers/customersSlice'
import { useSelector } from 'react-redux'
import { Spinner } from '../../../components/loadData/spinner'
import { useTranslation } from 'react-i18next'

function SelectUser ({ onChangeEntity, entity, customers }) {
  const { t } = useTranslation()
  return (
    <div>
      <h5 htmlFor="price" className="inline-block text-gray-800 max-w-fit">
        {t('admin.organisation.create.fields.choose_consultant_account')}
      </h5>
      <select className="focus:ring-teal-500 focus:border-teal-500 block border-1 text-xl border-[#e8e6e6] h-8 w-full pl-1 pr-2 text-md  text-[#444444]
          rounded-sm" value ={entity?.customer?.id ? entity.customer.id : undefined }
        onChange={(e) => onChangeEntity({ ...entity, customer: { ...entity.customer, id: e.target.value } })}>
        <option></option>
          {customers.map((customer) => <option value={customer.id}>{customer.name}</option>)}
      </select>
    </div>
  )
}
function CreateUser ({ onChangeEntity, entity }) {
  const { t } = useTranslation()
  return (
    <>
    <div className="w-full">
      <h5 className="block font-medium text-gray-700 text-lg pt-1">{t('admin.organisation.create.fields.add_mainConsultant')}</h5>
        <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
          <label htmlFor="price" className="block font-medium text-gray-700 text-md">
            {t('admin.organisation.create.fields.prenom')}
          </label>
          <input type="text" name="price" id="price" className="focus:ring-teal-500
          focus:border-teal-500 h-8 block  w-full pl-2 pr-12 text-md  text-[#444444]
          rounded-sm" style={{ border: '1px solid #e8e6e6' }}
            value={entity?.customer?.first_name ? entity.customer.first_name : ''}
            onChange={(e) => onChangeEntity({ ...entity, customer: { ...entity.customer, first_name: e.target.value } })}/>
        </div>
        <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
          <label htmlFor="price" className="block font-medium text-gray-700 text-md">
            {t('admin.organisation.create.fields.name')}
          </label>
          <input type="text" name="price" id="price" className="focus:ring-teal-500
          focus:border-teal-500 h-8 block  w-full pl-2 pr-12 text-md  text-[#444444]
          rounded-sm" style={{ border: '1px solid #e8e6e6' }}
            value={entity?.customer?.last_name ? entity.customer.last_name : ''}
            onChange={(e) => onChangeEntity({ ...entity, customer: { ...entity.customer, last_name: e.target.value } })}/>
        </div>
        <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
          <label htmlFor="price" className="block font-medium text-gray-700 text-md">
            {t('admin.organisation.create.fields.adresse_email')}
          </label>
          <input type="email" name="price" id="price" className="focus:ring-teal-500
          focus:border-teal-500 h-8 block  w-full pl-2 pr-12 text-md  text-[#444444]
          rounded-sm" style={{ border: '1px solid #e8e6e6' }}
            value={entity?.customer?.email ? entity.customer.email : ''}
            onChange={(e) => onChangeEntity({ ...entity, customer: { ...entity.customer, email: e.target.value } })}/>
        </div>
    </div>
    </>
  )
}

function SetMainCustomer ({ onChangeEntity, entity, onChangeStep }) {
  const { t } = useTranslation()
  const { isLoading, isSuccess, isError, Error } = useGetCustomersQuery()
  const customers = useSelector(selectAllCustomers)
  React.useEffect(() => {
    if (!entity?.customer) { onChangeEntity({ ...entity, customer: { exist: false } }) }
  }, [])
  const setCustomers = () => {
    if (isLoading) {
      return <Spinner/>
    }
    if (isSuccess) {
      if (entity?.customer?.exist) {
        return (
        <div className="md:w-2/5 mt-5">
          <SelectUser onChangeEntity={onChangeEntity} entity={entity} customers={customers}/>
        </div>
        )
      } else { return <CreateUser onChangeEntity={onChangeEntity} entity={entity}/> }
    }

    if (isError) {
      return <h5>{Error}</h5>
    }
  }
  return (
  <section className="w-full mt-4 pb-3">
    <div className="flex flex-col w-full">
    <div className="pl-4 pt-2">
      <div className="form-check">
          <input className="form-check-input   border border-gray-300 rounded-sm
          bg-white checked:bg-teal-600 checked:border-teal-600 focus:outline-none transition duration-200 mt-1
          align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" style={{ width: '19px', height: '19px' }}
          checked={!!entity?.customer?.exist} id="flexCheckChecked"
          onChange={(e) => onChangeEntity({ ...entity, customer: { exist: e.target.checked } })}/>
          <label className="form-check-label inline-block text-gray-800 text-lg" htmlFor="flexCheckChecked" style={{ cursor: 'pointer' }}>
            {t('admin.organisation.create.fields.consultant_exist')}
          </label>
        </div>
        {setCustomers()}
      </div>
      <div className="w-4/5">
          <button className="bg-teal-600 mx-2 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1">
            <h5 className="text-white w-min-fit" onClick={(e) => onChangeStep(6)}>
              {t('common.buttons.next')}
            </h5>
          </button>
          <button className="bg-gray-300 mx-2 px-2 py-0.5 rounded-md mb-1 text-md font-medium float-right p-1">
          <h5 className="text-[#444444] w-min-fit" onClick={() => onChangeStep(4)}>{t('common.buttons.post')}</h5>
        </button>
        </div>
      </div>
    </section>
  )
}
export default SetMainCustomer
