import React from 'react'
import UserAnswers from './userAnswers'
function DetailUser () {
  return (
    <>
       <UserAnswers userType={'admin'}/>
    </>

  )
}

export default DetailUser
