/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function DropDownLanguage () {
  const lngs = [
    { lang: 'en', nativeName: 'English' },
    { lang: 'fr', nativeName: 'Francais' }
  ]

  const { i18n } = useTranslation()
  const changeLanguages = (lng) => {
    i18n.changeLanguage(lng)
  }
  return (
    <div className="flex justify-center">
  <div className="w-16">
    <select className="form-select appearance-none block w-full pl-1  text-base font-normal  text-gray-700  bg-gray-100 bg-clip-padding
    bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white
    focus:border-teal-700 focus:outline-none" aria-label="Default select example" value={i18n.resolvedLanguage} onChange={(e) => changeLanguages(e.target.value)}>
     { lngs.map((lng, index) =>
        <option key={index} value={lng.lang}>{lng.lang}</option>)}
    </select>
  </div>
</div>
  )
}
