import React from 'react'
import Skeleton from '@mui/material/Skeleton'

function SimpleSkelton () {
  return (
    <div className="bg-white rounded-lg p-4   " style={{ border: '1px solid #ededed' }}>

      <div>
        <Skeleton variant="rectangular" width="100%" height="45px">
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
      </div>
    </div>
  )
}

export default SimpleSkelton
