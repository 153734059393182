import React from 'react'
import IndexAlert from '../../../../components/alerts/indexAlert'
import { useSelector } from 'react-redux'
import { useGetCustomersQuery, selectAllCustomers } from '../../../customers/customersSlice'
import { useTranslation } from 'react-i18next'

function ModifymainCustomer ({ changeEntity, entity }) {
  const { t } = useTranslation()
  const [modify, setModify] = React.useState(false)
  const { isLoading, isSuccess, isError, Error } = useGetCustomersQuery()
  const customers = useSelector(selectAllCustomers)

  React.useEffect(() => {
    if (!modify) {
      changeEntity({ ...entity, customer: { new: false, id: entity?.client_owner ? entity.client_owner : null } })
    }
  }, [modify])
  return (
    <>
      <section className="w-full  pb-5">
        <div className="flex flex-col gap-2 w-full">
          <div className="md:w-2/3 sm:w-1/2 pl-2 mt-2">
            <IndexAlert type="danger" text={t('admin.organisation.edit.contrainte_modif_consultant')} />
          </div>
          <div className="flex gap-2 md:w-2/3 sm:w-1/2 pl-2 mt-2">
            <input type="text" name="price" id="price"
              className="focus:ring-teal-500 focus:border-teal-500 block text-lg w-full pl-2
         text-[#444444] rounded-md" disabled style={{ border: '1px solid #e8e6e6' }}
              value={(customers && entity?.client_owner)
                ? customers.find((customer) => customer.id === entity.client_owner) &&
                customers.find((customer) => customer.id === entity.client_owner).name
                : 'hi'} checked={!!entity?.customer?.exist}
              onChange={(e) => changeEntity({ ...entity, customer: { exist: e.target.checked } })} />
            <button className=" text-[#b3890c] font-medium rounded-md text-md bg-white border-2
        px-3 border-[#f2f2f2] h-8 pt-1" style={{ cursor: 'pointer' }} onClick={() => setModify(!modify)} >{t('common.buttons.modify')}</button>
          </div>
        </div>
        {modify &&
          <>
            <div className="flex mt-1 pl-2">
              <input className="  form-check-input   border border-gray-300 rounded-sm bg-white checked:bg-teal-600 checked:border-teal-600
      focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" style={{ width: '19px', height: '19px' }}
                type="checkbox"
                checked={!!entity?.customer?.new}
                onChange={(e) => changeEntity({ ...entity, customer: { new: e.target.checked } })} />
              <h5 className="w-5 text-[#444444] pt-1">{t('admin.organisation.edit.new_consultant')}</h5>
            </div>

            {entity?.customer?.new
              ? <div className="md:w-1/2 w-full mt-2 pl-3">
                <CreateUser changeEntity={changeEntity} entity={entity} />
              </div>
              : <div className="md:w-1/2 w-full mt-2 pl-3">
                <SelectUser changeEntity={changeEntity} entity={entity} customers={customers} />
              </div>
            }

          </>
        }
      </section>

    </>
  )
}

export default ModifymainCustomer

function SelectUser ({ changeEntity, entity, customers }) {
  const { t } = useTranslation()
  return (
    <>
      <h5 htmlFor="price" className="inline-block text-gray-800 text-md ">
      {t('admin.organisation.edit.choose_consultant_account')}
      </h5>
      <select className="focus:ring-teal-500 focus:border-teal-500 block border-1 text-xl  border-[#e8e6e6] h-8 w-300 mr-2 pl-1 pr-2 text-md text-[#444444]
            rounded-sm" value={entity?.customer?.id ? entity.customer.id : undefined}
        onChange={(e) => changeEntity({ ...entity, customer: { new: false, id: e.target.value } })}>
        <option></option>
        {customers.map((customer) => <option value={customer.id}>{customer.name}</option>)}
      </select>
    </>
  )
}
function CreateUser ({ changeEntity, entity }) {
  const { t } = useTranslation()
  return (
    <>
      <div className="w-full">
        <h5 className="block font-medium text-gray-800 text-lg pt-1">{t('entity.edit.Add_new_consultant')}</h5>
        <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
          <label htmlFor="price" className="block font-medium text-gray-800 text-md">
            {t('prenom')}
          </label>
          <input type="text" name="price" id="price" className="focus:ring-teal-500
            focus:border-teal-500 h-8 block  w-full pl-2 pr-12 text-md  text-[#444444]
            rounded-sm" style={{ border: '1px solid #e8e6e6' }} placeholder="First name"
            value={entity?.customer?.first_name ? entity.customer.first_name : ''}
            onChange={(e) => changeEntity({ ...entity, customer: { ...entity.customer, first_name: e.target.value } })} />
        </div>
        <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
          <label htmlFor="price" className="block font-medium text-gray-800 text-md">
            {t('name')}
          </label>
          <input type="text" name="price" id="price" className="focus:ring-teal-500
            focus:border-teal-500 h-8 block  w-full pl-2 pr-12 text-md  text-[#444444]
            rounded-sm" style={{ border: '1px solid #e8e6e6' }} placeholder="Last name"
            value={entity?.customer?.last_name ? entity.customer.last_name : ''}
            onChange={(e) => changeEntity({ ...entity, customer: { ...entity.customer, last_name: e.target.value } })} />
        </div>
        <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
          <label htmlFor="price" className="block font-medium text-gray-800 text-md">
            {t('Adresse_email')}
          </label>
          <input type="email" name="price" id="price" className="focus:ring-teal-500
            focus:border-teal-500 h-8 block  w-full pl-2 pr-12 text-md  text-[#444444]
            rounded-sm" style={{ border: '1px solid #e8e6e6' }} placeholder="Example@gmail.com"
            value={entity?.customer?.email ? entity.customer.email : ''}
            onChange={(e) => changeEntity({ ...entity, customer: { ...entity.customer, email: e.target.value } })} />
        </div>
      </div>
    </>
  )
}
