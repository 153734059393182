import React from 'react'
import { useSelector } from 'react-redux'
import { useGetActivitiesQuery, selectAllActivities } from '../../../../features/activitiesSector/activitiesSlice'
import { useTranslation } from 'react-i18next'

function ModifyGeneralInfo ({ entity, changeEntity }) {
  const handleChange = event => {
    const min = 0
    const max = 999999
    const value = Math.max(min, Math.min(max, Number(event.target.value)))
    changeEntity(entity = { ...entity, employee_Number: parseInt(value) })
  }

  // Retreive activity sectors to display
  const activitySectorsSelector = useSelector(selectAllActivities)
  const activitySectors = {}
  const { as } = useGetActivitiesQuery()
  activitySectorsSelector.map((sector) => activitySectors[sector.id] = sector)

  const { t } = useTranslation()
  return (
    <div>
    <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm">
    <label
        htmlFor="price"
        className="block text-md font-medium text-gray-700">
        {t('consultant.organisation.edit.organisationName')}
      </label>
      <input type="text" name="price" id="price"
        className="focus:ring-teal-500
      focus:border-teal-500 block  w-full pl-2 pr-12 text-lg  text-[#444444]
      rounded-sm" disabled={true}
        style={{ border: '1px solid #e8e6e6' }}
        value={entity?.name ? entity.name : ''}/>
    </div>
    <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm ">
    <label
        htmlFor="employee_number"
        className="block text-md font-medium text-gray-700">
          {t('consultant.organisation.edit.nbr_employee')}
      </label>
      <input type="text" name="employee_number" id="employee_number"
      style={{ border: '1px solid #e8e6e6' }}
      onKeyPress={(event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault()
        }
      }}
        className="focus:ring-teal-500
      focus:border-teal-500   w-full pl-2 pr-12 text-lg  text-[#444444]
      rounded-sm"
        value={entity?.employee_Number ? entity.employee_Number : ''}
        onChange = {(e) => handleChange(e)}/>
    </div>
    <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm">
      <label
        htmlFor="price" className="block text-md font-medium text-gray-700">
        {t('consultant.organisation.edit.activity_sector')}
      </label>
      <input type="text" name="employee_number" id="employee_number"
        className="focus:ring-teal-500
      focus:border-teal-500   w-full pl-2 pr-12 text-lg  text-[#444444]
      rounded-sm" style={{ border: '1px solid #e8e6e6' }} disabled={true}
      value ={entity?.activity_sector ? activitySectors[entity.activity_sector] ? activitySectors[entity.activity_sector].name : '' : undefined}/>

    </div>
    </div>
  )
}

export default ModifyGeneralInfo
