import React from 'react'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import ModifyEntity from '../../../features/entities/modifyEntity'
import { generalOptions, entityOptions } from '../DynamicNavbarOptions'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function EditEntity () {
  const { t } = useTranslation()
  const { id } = useParams()
  return (
<div className="w-full pb-16">
  <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
  <DynamicNavBar option="" options={entityOptions}/>
        <div className="w-full flex flex-col items-center gap-8  static mt-2" >
          <div className=" md:w-1/2 lg:w-1/2 sm:w-2/3 md:mx-8 pb-8">
            <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('admin.organisation.details.modify_organisation')}</h5>
            <div className="bg-[#ffffff] w-full py-2 mt-1 shadow-sm rounded-md">
              <ModifyEntity id={id}/>
            </div>
          </div>
    </div>
</div>
  )
}
export default EditEntity
