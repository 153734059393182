import React from 'react'
import { FilterMatchMode } from 'primereact/api'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import EvaluateMaterialityMatrix from '../components/evaluateMaterialityMatrix'
import FolderIcon from '@mui/icons-material/Folder';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

function MaterialityMatrixTable ({ materialityMatrixList }) {
  const { t } = useTranslation()
  const { idEntity } = useParams()
  console.log('materialityMatrixList',materialityMatrixList)
  const navigate = useNavigate()
  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        {// <FolderIcon sx={{ color: '#14b8a6', cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${idEntity}/materiality_matrix/details/${e.id}`)}/>
        }
        {!e.evaluated &&
        <EvaluateMaterialityMatrix id ={e.id}>
          <CalculateOutlinedIcon sx={{ color: '#db7618', cursor: 'pointer' }}/>
        </EvaluateMaterialityMatrix>
        }
        {e.evaluated &&
          <QueryStatsIcon sx={{ color: '#e0be12', cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${idEntity}/materiality_matrix/evaluation/${e.id}`)}/>
        }
      </div>
    )
  }
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: materialityMatrixList,
    stateKey: 'dt-state-evaluation-hub-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    filters,
    header,
    globalFilterFields: ['name', 'add_date', 'report_data.evaluationMethod'],
    emptyMessage: t('consultant.evaluation.table.evaluation_methods_listNotFound'),
    columns: [
      { field: 'name', sortable: true, header: t('common.table.name') },
      { field: 'creation_date', sortable: true, header: t('common.table.creation_date') },
      { field: 'intern_campaign.name', sortable: true, header: t('common.table.intern_campaign') },
      { field: 'extern_campaign.name', sortable: true, header: t('common.table.extern_campaign') },
      { field: 'evaluated', sortable: true, header: t('common.table.evaluated') },
      { body: actionTemplate, header: t('common.table.action') }]
  }

  const DataTableComponent = createDataTable(tableConfig)
  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300 ">
          {DataTableComponent}
        </div>
    </div>

  )
}
export default MaterialityMatrixTable
