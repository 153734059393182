import React from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import './style.css'
import BeatLoader from 'react-spinners/BeatLoader'
import { useEvaluateMaterialityMatrixMutation } from '../../../../features/materialityMatrix/materialityMatrixSlice'
import IndexAlert from '../../../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'
import { useNavigate,useParams } from 'react-router-dom'
import PropTypes from 'prop-types'


const EvaluateMaterialityMatrix = ({ children, id }) => {
  const navigate = useNavigate()
  const { idEntity } = useParams()
  const { t } = useTranslation()
  const [evaluateMaterialityMatrix, { data: materialitMatrix, isLoading, isSuccess, isError, error }] = useEvaluateMaterialityMatrixMutation()
  const HandleAddEntity = async (e) => {
    await evaluateMaterialityMatrix(id)
  }
  const ref = React.useRef()
  const closeTooltip = () => {
    ref.current.close()
    if (isSuccess) {
      navigate(`/hub/entity/${idEntity}/materiality_matrix`)
    }
  }
  if (!id) {
    return (
    <div>
    <Popup
        closeOnDocumentClick={false}
        trigger={children}
        contentStyle={{ width: '400px', heigh: '300px' }}
        modal
        ref={ref}>
      {close => (
        <div className="modal">
            <div className="content flex flex-col justify-center items-center ">
                <h5 className="#444 text-lg ">{t('common.popup.createEvaluation')}</h5>
                <div className="mt-2">
                  <div>
                  <IndexAlert type="danger" text="Veuillez remplir tous les cases du formulaire"/>
                </div>
              </div>

            </div>
          <div className="actions">
          <botton className="text-lg bg-gray-200 rouned-md px-2 py-0.5" onClick={() => closeTooltip()} style={{ cursor: 'pointer' }}>{t('common.popup.close')}</botton>
          </div>
        </div>
      )}
    </Popup>
    </div>

    )
  } else {
    return (
    <div>
    <Popup
        closeOnDocumentClick={false}
        trigger={children}
        contentStyle={{ width: '400px', heigh: '300px' }}
        onOpen={(e) => HandleAddEntity(e)}
        modal
        ref={ref}>
      {close => (
        <div className="modal">
            <div className="content flex flex-col justify-center items-center ">
                <h5 className="#444 text-lg ">{t('common.popup.createEvaluation')}</h5>
                <div className="mt-2">

                  {isLoading && <BeatLoader color="#086e7c"/>}
                  {isSuccess && <div><IndexAlert type="success" text={t('consultant.materiality_matrix.popup.calcule_success')}/></div>}
                  {isError && <div><IndexAlert type="danger" text={JSON.stringify(error?.data?.error)}/> </div>}
                </div>
            </div>
          <div className="actions">
          <botton className="text-lg bg-gray-200 rouned-md px-2 py-0.5" onClick={() => closeTooltip()} style={{ cursor: 'pointer' }}>{t('common.popup.close')}</botton>
          </div>
        </div>
      )}
    </Popup>
    </div>
    )
  }
}
EvaluateMaterialityMatrix.propTypes = {
  children: PropTypes.object,
  evaluation: PropTypes.object,
  id: PropTypes.string
}
export default EvaluateMaterialityMatrix
