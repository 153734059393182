import React from 'react'
import { InputText } from 'primereact/inputtext'

export const renderHeader = (globalFilterValue, filters, setFilters, setGlobalFilterValue, label) => {
  const value = filters.global ? filters.global.value : ''
  return (
    <div className="flex justify-content-end">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText className='h-8' value={value || ''} onChange={(e) => onGlobalFilterChange(e, filters, setFilters, setGlobalFilterValue)} placeholder={label} />
      </span>
    </div>
  )
}

const onGlobalFilterChange = (e, filters, setFilters, setGlobalFilterValue) => {
  const value = e.target.value
  const _filters = { ...filters }
  _filters.global.value = value
  setFilters(_filters)
  setGlobalFilterValue(value)
}
