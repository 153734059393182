import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const surveycampaignsAdapter = createEntityAdapter({
})
const initialState = surveycampaignsAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const surveyCampaignsApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'userCampaignResponse',
  endpoints: builder => ({
    getUserCampaignResponse: builder.query({
      query: ({ idCampaign, idUser }) => `/campaign/userCampaignResponse/${idCampaign}/${idUser}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['userCampaignResponse']
    }),
    getUserCampaignResponses: builder.query({
      query: (idCampaign) => `/campaign/userCampaignResponseList/${idCampaign}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['userCampaignResponse']
    }),
    submitCampaign: builder.mutation({
      query: (data) => ({
        url: `/campaign/userCampaignResponse/${data.idCampaign}/${data.idUser}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['userCampaignResponse'],
      onSuccess: (data, variables, api, store) => {
        // Refetch data from the query endpoint
        api.endpoints.getUserCampaignResponse(variables).refetch()
      }
    })
  })
})
export const {
  useGetUserCampaignResponseQuery,
  useGetUserCampaignResponsesQuery,
  useSubmitCampaignMutation
} = surveyCampaignsApiSlice

export const selectSurveyCampaignsResult = surveyCampaignsApiSlice.endpoints.getUserCampaignResponse.select()

export const selectsurveyCampainsData = createSelector(
  selectSurveyCampaignsResult,
  campaignsResult => campaignsResult.data
)

export const {
  selectAll: selectAllCampaigns,
  selectById: selectSingleCampaign
} = surveycampaignsAdapter.getSelectors(state => selectsurveyCampainsData(state) ?? initialState)
