import React from 'react'
import DynamicNavBar from '../../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions, transformModel } from '../../DynamicNavbarOptions'
import { useGetEvaluationMethodQuery } from '../../../../features/evaluationMethod/evaluationMethodSlice'
import QuestionTransformList from '../tables/questionTransformList'
import { useTranslation } from 'react-i18next'
import TransformMethodDetails from './transformMethodDetails'
import { useParams } from 'react-router-dom'
import CheckBox from '../utils/checkBox'
import { useGetSurveyNodesTransformQuery } from '../../../../features/transformQuestion/transformMethodSlice'

function EvaluationMethod () {
  const { t } = useTranslation()
  const { idModel } = useParams()
  const [question, setQuestion] = React.useState()
  const [questionInfo, setQuestionInfo] = React.useState({})
  const [showModèleInfo, setShowModèleInfo] = React.useState(false)
  const { data: evaluationMethod } = useGetEvaluationMethodQuery(idModel || null)
  const { data: nodes } = useGetSurveyNodesTransformQuery(evaluationMethod?.survey_tree?.uuid)


  React.useEffect(() => {
    if (question && evaluationMethod) {
      if (question.id) {
        setQuestionInfo(evaluationMethod.questions[question.id])
      }
    }
  }, [question, evaluationMethod])
  return (
    <div className="w-full pb-16">
      <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
      <DynamicNavBar option="navbar.surveyOptions.tranformation_model" options={surveyOptions}/>
      <DynamicNavBar option="navbar.transformModel.evaluation_method_list" options={transformModel}/>
        <div className="w-full flex flex-col items-center static mt-4">
          <div className="w-full bg-wite pb-4">
            <h5 className="text-3xl font-medium text-[#444444] w-max-fit px-3">{t('admin.survey.transformation_model.title.modify_transformationQuestion')}</h5>
              <div className="flex gap-1 mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-2">
                  <div className='w-1/2 p-3'>
                    <CheckBox text={t('admin.survey.transformation_model.create.fields.show_transformationModelInfo')} value={showModèleInfo} onChange={(val) => setShowModèleInfo(val)} disabled={false}/>
                    {showModèleInfo &&
                    <TransformMethodDetails transformMethod={evaluationMethod || {}}/>}
                    <h5 className="text-xl font-medium text-[#444444] w-max-fit py-1">{t('admin.survey.transformation_model.create.fields.question_list')}</h5>
                    <QuestionTransformList data={nodes || []}
                  onChangeQuestion={(val) => setQuestion(val)}/></div>
                  {question &&
                  <div className='w-1/2'>
                    {// <QuestionDetails globalFormulas ={transformMethod?.global_parameters?.global_formulas || {}} question={question || {}} questionFormulas={questionInfo?.formulas || []}/>
                    }
                  </div>
                }
              </div>
          </div>
        </div>
    </div>
  )
}
export default EvaluationMethod
