import React from 'react'
import { useTranslation } from 'react-i18next'

const SelectInput = ({ text, value, onChange, data, disabled }) => {
  const { t } = useTranslation()
  return (
    <div>
        <h5 htmlFor="price" className="inline-block text-gray-800 max-w-fit">
              {t(text)}
            </h5>
            <select className="focus:ring-teal-500 focus:border-teal-500 block border-1 text-xl border-[#e8e6e6] h-8 w-full pl-1 pr-2 text-md  text-[#444444]
            rounded-sm" value={value}
              onChange={(e) => onChange(e.target.value)} disabled={disabled}>
              <option></option>
              {data &&
                 data.map((instance) => <option value={instance.id} key={instance.id}>{instance.title}</option>)}
            </select>
    </div>
  )
}

export default SelectInput
