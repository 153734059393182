import React, { useState, useRef, useEffect } from 'react'
import Header from './components/header'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useLoginMutation, useGetUserCredentialsMutation } from '../../features/auth/authApiSlice'
import { setCredentials, setUser } from '../../features/auth/authSlice'
import IndexAlert from '../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'

export default function LoginPage () {
  const { t } = useTranslation()

  function redirectUser (type) {
    switch (type) {
      case 'ADMIN':
        navigate('/admin/entity')
        break
      case 'SUPERADMIN':
        navigate('/admin/entity')
        break
      case 'CLIENT':
        navigate('/hub/entity')
        break
      default:
        navigate('/auth/login')
    }
  }

  const [LoginInfo, setLoginInfo] = React.useState({ username: '', password: '' })

  const userRef = useRef()
  const errRef = useRef()
  const [errMsg, setErrMsg] = useState('')
  const [login, { isSusccess: isSusccessLogin, isError }] = useLoginMutation()
  const [getUser] = useGetUserCredentialsMutation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    userRef.current.focus()
  }, [])
  useEffect(() => {
  }, [login])
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const LoginData = await login(LoginInfo)
      dispatch(setCredentials({ ...LoginData.data }))
      if (LoginData?.data?.access_token) {
        const UserData = await getUser(LoginData.data.access_token)
        redirectUser(UserData.data.type)
        dispatch(setUser(UserData.data))
        if (UserData.data.type === 'ADMIN') {
          navigate('/admin/entity')
        }
        if (UserData.data.type === 'SUPERADMIN') {
          navigate('/admin/entity')
        }
        if (UserData.data.type === 'CLIENT') {
          navigate('/hub/entity/list')
        }
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response')
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or password')
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized')
      } else {
        setErrMsg('Login Failed')
      }
      errRef.current.focus()
    }
  }

  return (
        <section className="mt-100">
            <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'}></p>
            <Header
                heading={t('login.heading')}
                /* paragraph="Vous n'avez pas encore de compte ?" */
                // linkName="Contactez nous"
                linkUrl="/signup" />
            <div className="flex justify-center">
            <div>
                {isError &&
                <div className="">
                    <IndexAlert type="danger" text={t('login.errors.general')}/>
                </div>}
                {isSusccessLogin &&
                <div className="">
                    <IndexAlert type="success" text={t('login.connection_success')}/>
                </div>}
            <form onSubmit={handleSubmit}>
                <div className="space-y-4 w-400">
                    <div className="-space-y-px ">
                        <div className="my-5 flex flex-col">
                            <label htmlFor="email" className="text-[#444444] text-md font-semibold ">
                            {t('login.email_address')}
                            </label>
                            <input
                                ref={userRef}
                                onChange={(e) => setLoginInfo({ ...LoginInfo, username: e.target.value })}
                                value={LoginInfo.username}
                                required={true}
                                className="border-2 border-[#f7f7f7] rounded-sm pl-2 text-md h-8"
                                placeholder={t('login.email_address')}
                            />
                        </div>
                        <div className="my-5 flex flex-col w-full">
                            <label htmlFor="email" className="text-[#444444] text-md font-semibold">
                            {t('login.password')}
                            </label>
                            <input
                                onChange={(e) => setLoginInfo({ ...LoginInfo, password: e.target.value })}
                                value={LoginInfo.password}
                                required={true}
                                type="password"
                                className="border-2 border-[#f7f7f7] rounded-sm pl-2 text-md h-8"
                                placeholder={t('login.password')}
                            />
                        </div>
                    </div>
                        <div className="flex flex-col ">
                        <input className="p-1 px-2 text-white bg-[#086E7C] w-22 mx-auto" type="submit" style={{ cursor: 'pointer' }} value={t('login.login_button')} />
                    </div>
                </div>
            </form>
            </div>
            </div>
            <div className="text-sm mt-4 flex justify-center">
                <bottom className="font-semibold text-md text-[#086E7C] hover:text-[#086E7C]" style={{ cursor: 'pointer' }} onClick={() => navigate('/auth/forget_password')}>
                {t('login.forgotten_password')}
                </bottom>
            </div>

        </section>
  )
}
