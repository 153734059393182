import React from 'react'
import Alert from '@mui/material/Alert'
import PropTypes from 'prop-types'
function DangerSuccess (props) {
  return (
    <Alert className="max-w-fit items-center text:md md:text-lg" severity="success">{props.text}</Alert>
  )
}

DangerSuccess.propTypes = {
  text: PropTypes.string.isRequired
}

export default DangerSuccess
