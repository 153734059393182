import React from 'react'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions, parametersOptions } from '../DynamicNavbarOptions'
import { useGetOptionsTemplatesDetailsQuery } from '../../../features/questions/questionSlice'
import OptionFormAdd from '../../../features/questions/optionFormAdd'
import { useTranslation } from 'react-i18next'
import GroupTypeTable from './tables/groupTypeTable'

function ResponseType () {
  const { t } = useTranslation()
  const { data: questionTemplates } = useGetOptionsTemplatesDetailsQuery()
  return (
    <div className="w-full pb-16">
      <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
      <DynamicNavBar option="navbar.surveyOptions.question_settions" options={surveyOptions}/>
      <DynamicNavBar option="navbar.parametersOptions.reponse_model" options={parametersOptions}/>
        <div className="w-full flex flex-col items-center  static mt-4" >
          <div className="w-2/3 bg-wite pb-4">
            <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('admin.survey.questions.respond_model_list')}</h5>
              <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-2 py-4 flex flex-col gap-4">
                <div className=''>
                  <GroupTypeTable data={questionTemplates || []}/>
                </div>
                  <OptionFormAdd add={false}/>
              </div>
          </div>
        </div>
    </div>
  )
}
export default ResponseType
