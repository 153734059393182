import React from 'react'
import catUnauthorized from '../../assets/cat_unauthorized.png'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function NotFound () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div>
    <div className="h-screen w-screen bg-gray-100 flex items-center">
    <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
    <div className="max-w-md">
    <div className="text-5xl font-dark font-bold">404</div>
    <p className="text-2xl md:text-3xl font-light leading-normal">{t('pageNotFound')}</p>
    <p className="mb-8">{t('redirectNotFound')}</p>
    <button className="px-2 inline py-1 text-md font-medium leading-5
    shadow text-white transition-colors duration-150 border border-transparent
    rounded-lg focus:outline-none focus:shadow-outline-teal bg-teal-600 active:bg-teal-600
    hover:bg-teal-700"
    onClick={ () => navigate('/auth/login')}>{t('backWelcome')}</button>
    </div>
    <div className="max-w-lg">
    <img src={catUnauthorized} alt="unauthorized_picture"/>
    </div>
    </div>
    </div>
    </div>
  )
}

export default NotFound
