import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../features/auth/authSlice'

function CheckAdminList ({ allowedRole }) {
  const user = useSelector(selectCurrentUser)
  const location = useLocation()
  return (
    allowedRole.includes(user?.type)
      ? <Navigate to="/hub/entity/list/admin" state={{ from: location }} replace />
      : <Outlet/>
  )
}

export default CheckAdminList
