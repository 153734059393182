import React from 'react'
import CampaignAdd from '../../../features/campaign/CampaignAdd'

function CreateCampaign () {
  return (
    <div className="w-full flex justify-center">
        <CampaignAdd/>
    </div>
  )
}

export default CreateCampaign
