import React from 'react'
import InputNumber from './inputNumber'
import Formulas from './formulas'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'

const GlobalParams = ({ params, onChange, onBlur }) => {
  const FORMULAS_OPTIONS = ['python', 'global']
  const { t } = useTranslation()
  const handleAddFormula = () => {
    const FormulaUuid = uuidv4()
    const newFormula = { text: 'formula-' + FormulaUuid.split('-')[0], value: '', id: FormulaUuid, type: 'global' }
    const parameters = { ...params, global_formulas: { ...params.global_formulas, [FormulaUuid]: newFormula } }
    onBlur(parameters)
  }
  return (
    <>
      <div className='mt-2'>
        <InputNumber text={t('admin.survey.transformation_model.create.fields.denominator')} value={params?.denominator ? params.denominator : ''}
          onChange={(val) => onChange({ ...params, denominator: val })} onBlur={() => onBlur()} />
      </div>
      <div>
        <div className='flex'>
          <label className="form-check-label inline-block text-gray-800 text-lg"
            htmlFor="flexCheckChecked" style={{ cursor: 'pointer' }}>
            {t('admin.survey.transformation_model.create.fields.formulas')}
          </label>
          {params?.global_formulas && !Object.keys(params?.global_formulas)?.length &&
            <div style={{ cursor: 'pointer' }} onClick={() => handleAddFormula()}>
              <AddIcon style={{ color: '#00796B' }}/>
            </div>
          }
        </div>
        <Formulas onBlur={(val) => onBlur({ ...params, global_formulas: val })} FORMULAS_OPTIONS={FORMULAS_OPTIONS} formulas={params?.global_formulas}
          onChange={(val) => onChange({ ...params, global_formulas: val })} />
      </div>
    </>
  )
}
GlobalParams.propTypes = {
  params: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}
export default GlobalParams
