import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const customersAdapter = createEntityAdapter({
})
const initialState = customersAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const customersApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'customersSlice',
  endpoints: builder => ({
    getCustomers: builder.query({
      query: () => '/user/clients/all',
      transformResponse: responseData => {
        const finalEntities = responseData
        return customersAdapter.setAll(initialState, finalEntities)
      },
      providesTags: ['Customers']
    })
  })
})

export const {
  useGetCustomersQuery
} = customersApiSlice

export const selectCustomersResult = customersApiSlice.endpoints.getCustomers.select()

export const selectCustomersData = createSelector(
  selectCustomersResult,
  customersResult => customersResult.data
)

export const {
  selectAll: selectAllCustomers,
  selectById: selectSingleCustomer
} = customersAdapter.getSelectors(state => selectCustomersData(state) ?? initialState)
