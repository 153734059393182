import React from 'react'
import { useTranslation } from 'react-i18next'

const ColorPalet = () => {
  const { t } = useTranslation()
  return (
    <ul className='flex gap-2 w-full'>
    <li className='flex gap-2 min-w-fit'>
      <div className='bg-[#c2c2c2] h-4 w-1 '></div>
      <p className='w-fit break-normal'>{t('consultant.evaluation.result.No_Answer')}</p>
    </li>
    <li className='flex gap-2'>
      <div className='bg-[#e38f8f] h-4 w-1 '></div>
      <p className='w-fit break-normal'>{t('consultant.evaluation.result.7_Note')}</p>
    </li>
    <li className='flex gap-2'>
      <div className='bg-[#f3f7a6] h-4 w-1 '></div>
      <p className='w-fit break-normal'>{t('consultant.evaluation.result.7-15_Note')}</p>
    </li>
    <li className='flex gap-2'>
      <div className='bg-[#5ac49c] h-4 w-1 '></div>
      <p className='w-fit break-normal'>{t('consultant.evaluation.result.15_Note')}</p>
    </li>
  </ul>
  )
}

export default ColorPalet
