import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import 'primeicons/primeicons.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.css'
import 'primeflex/primeflex.css'
import { FilterMatchMode } from 'primereact/api'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'

function EntitiesList ({ usersList, selectedData, setSelectedData, onChangeEntity, entity }) {
  const { t } = useTranslation()
  const [first2, setFirst2] = React.useState(0)
  const [rows2, setRows2] = React.useState(5)
  const [filters1, setFilters1] = React.useState(null)
  const [userListData, setUserListData] = React.useState()

  const template2 = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',

    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 }
      ]

      return (
          <React.Fragment>
              <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>{t('common.elements_by_page')}</span>
              <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
          </React.Fragment>
      )
    },
    CurrentPageReport: (options) => {
      return (
          <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
              {options.first} - {options.last} {t('common.elements_of')} {options.totalRecords}
          </span>
      )
    }
  }
  React.useEffect(() => {
    initFilters1()
  },
  [])

  React.useEffect(() => {
    setUserListData(usersList)
  }, [usersList])

  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        <DeleteIcon onClick={() => onChangeEntity({ ...entity, users: entity.users.filter((user) => user.id !== e.id) })}
        sx={{ color: 'red', cursor: 'pointer', fontSize: 25 }}/>
      </div>
    )
  }

  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    const _filters1 = { ...filters1 }
    _filters1.global.value = value

    setFilters1(_filters1)
    setGlobalFilterValue(value)
  }

  const initFilters1 = () => {
    setFilters1({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } })
  }

  const renderHeader2 = () => {
    return (
        <div className="flex justify-content-end">
            <span className="p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t('common.search')} className="h-8" />
            </span>0
        </div>
    )
  }
  const onRowEditComplete = (e) => {
    const _userListData = [...userListData]
    const { newData, index } = e
    _userListData[index] = newData
    onChangeEntity({ ...entity, users: _userListData })
  }
  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
  }
  const typeSelector = (options) => {
    const typeSelectorOptions = [
      { label: t('common.internal'), value: "internal" },
      { label: t('common.external'), value: "external" },
      { label: "", value: ""}
    ]
    return <Dropdown value={options.value} options={typeSelectorOptions} onChange={(e) => options.editorCallback(e.target.value)} />
  }
  const Header = renderHeader2()
  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300  ">
          <DataTable value={usersList} responsiveLayout="scroll" paginator paginatorTemplate={template2} dataKey="id" editMode="row" onRowEditComplete={onRowEditComplete}
                first={first2} rows={rows2} header={Header} filters={filters1} globalFilterFields={['email', 'post', 'first_name', 'last_name']}
                selectionMode="checkbox" selection={selectedData} onSelectionChange={e => setSelectedData(e.value)}>
                <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                <Column field="first_name" sortable header={t('components.tables.entity.member_list.first_name')} editor={(options) => textEditor(options)} style={{ width: '10%' }}></Column>
                <Column field="last_name" sortable header={t('components.tables.entity.member_list.last_name')} editor={(options) => textEditor(options)} style={{ width: '10%' }}></Column>
                <Column field="email" sortable header={t('components.tables.entity.member_list.email_address')} editor={(options) => textEditor(options)} style={{ width: '35%' }}></Column>
                <Column field="post" sortable header={t('components.tables.entity.member_list.position')} editor={(options) => textEditor(options)} style={{ width: '5%' }}></Column>
                <Column field="type" sortable header={t('components.tables.entity.member_list.type')} editor={(options) => typeSelector(options)} style={{ width: '5%' }}></Column>
                <Column body={(e) => actionTemplate(e)} header={t('common.action')}></Column>
                <Column rowEditor headerStyle={{ width: '5%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }} style={{ width: '10%' }}></Column>
          </DataTable>
        </div>
    </div>
  )
}
export default EntitiesList
