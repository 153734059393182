import React from 'react'
import Slider from '@mui/material/Slider'
import BaseQuestion from '../baseQuestion'
import PropTypes from 'prop-types'

const SlideBar = ({ displayComments, disabled, saveResponse, fullResponse, reponse, question, comment, commentUser, showComments, questionHelpText }) => {
  const [options, setOptions] = React.useState()
  const [marks, setMarks] = React.useState()
  const [value, setValue] = React.useState()
  const [step, setStep] = React.useState()
  const [reponseFinal, setReponseFinal] = React.useState()
  const [sliderColor, setSliderColor] = React.useState()
  const [questionDetails, setQuestionDetails] = React.useState()

  React.useEffect(() => {
    if (question?.question_uuid) {
      setQuestionDetails(question)
    }
  }, [question])

  React.useEffect(() => {
    setOptions(question.option)
  }, [question])
  React.useEffect(() => {
    if (reponse) {
      setReponseFinal(reponse)
    }
    if (options) {
      const long = options.length
      setStep(100 / (long * 20))
      if (long >= 1) {
        const list = options.map((op, index) => {
          if (op) {
            const step = (100 - 10) / (long - 1)
            const mark = {
              value: 10 + (index * step),
              label: index + 1,
              text: op.text ? op.text : ''
            }
            return mark
          }
        })
        setMarks(list)
      }
    }
  }, [options, reponse])

  const handleSliderChange = (e) => {
    const step = (100 - 10) / (options.length - 1)
    const ch = (e.target.value - 10 + step) / step
    const Index = Number(ch.toFixed(4).slice(0, -2))
    const choiceIndex = (Math.round(ch * 10) / 10)
    const pourcentage = Math.floor((Index - Math.floor(Index)) * 100)
    const clearIndex = Math.floor(Index)
    const option = options[clearIndex - 1]?.text ? options[clearIndex - 1].text : null
    const nOption = options[clearIndex]?.text ? options[clearIndex].text : null
    setReponseFinal({ value: e.target.value, choiceIndex, option, nextOption: nOption, pourcentage, question: question.uuid })
  }

  const sendData = (state) => {
    const { value, choiceIndex, option, nextOption, pourcentage } = reponseFinal || ''

    const response = {
      complexValue: state !== 'delete' ? { value, choiceIndex, option, nextOption, pourcentage } : {},
      question: question.uuid,
      state
    }
    saveResponse(response)
  }
  React.useState(() => {
    if (reponse.value) {
      setValue(reponse.value)
    }
  }, [reponse.value])

  React.useEffect(() => {
    if (reponseFinal) {
      if (reponseFinal.choiceIndex >= 1) {
        setSliderColor('#3096d1')
      } else {
        setSliderColor('#de6868')
      }
    }
  }, [reponseFinal])
  return (
    <BaseQuestion displayComments={displayComments} disabled={disabled} sendData={(state) => sendData(state)} questionHelpText={questionHelpText}
     reponse={fullResponse} showComments={showComments} commentUser ={commentUser} comment={comment} question={question}>

    <div className="py-1">
      <h5 className="text-md">{question.text}</h5>
    </div>
    <div className='w-400 pl-16'>
      <Slider
        disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
        aria-label="Custom marks"
        value={reponseFinal?.value ? reponseFinal.value : 0}
        onChange={handleSliderChange}
        onChangeCommitted={() => sendData('draft')}
        valueLabelFormat={reponseFinal?.choiceIndex ? reponseFinal.choiceIndex : 0}
        step= {step}
        marks={marks}
        sx={{ color: sliderColor, '& .MuiSlider-rail': { color: sliderColor } }}
        valueLabelDisplay="auto"
      />
      <div className='flex flex-col gap-1'>
      {marks
        ? marks.map((op, index) => {
          if (op?.label !== 0) {
            return (
          <div className='flex gap-2' key={index}>
            <span className='px-1 bg-gray-300 rounded-sm max-h-fit'>{op?.label ? op.label : index + 1 }</span>
            <p className='text-gray-800 text-md'>{op.text ? op.text : undefined}</p>
          </div>)
          }
        }
        )
        : undefined
      }
        <div/>
      </div>
    </div>
    </BaseQuestion>
  )
}
SlideBar.propTypes = {
  text: PropTypes.string,
  displayComments: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  responses: PropTypes.array,
  commentUser: PropTypes.string,
  saveResponse: PropTypes.func,
  reponse: PropTypes.string,
  question: PropTypes.object.isRequired,
  comment: PropTypes.object,
  questionHelpText: PropTypes.string,
  showComments: PropTypes.bool,
  fullResponse: PropTypes.object

}
export default SlideBar
