import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentToken, selectCurrentUser } from '../../features/auth/authSlice'
import FirstLogin from './firstlogin'

function RequireAuth ({ allowedRole }) {
  const token = useSelector(selectCurrentToken)
  const user = useSelector(selectCurrentUser)
  const location = useLocation()
  return (
    token
      ? allowedRole.includes(user?.type)
        ? user.first_login
          ? <FirstLogin email={user?.email}/>
          : <Outlet/>
        : <Navigate to="/unauthorized" state={{ from: location }} replace />
      : <Navigate to="/auth/login" state={{ from: location }} replace />
  )
}

export default RequireAuth
