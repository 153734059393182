import React from 'react'
import { useGetChoicesListQuery } from '../../../features/questions/questionSlice'
import UnitSelect from '../utils/unitSelect'
import Input from '../utils/input'
import PropTypes from 'prop-types'

const AdditionalParams = ({ question, ChangeQuestion }) => {
  const { data: choicesList } = useGetChoicesListQuery()
  return (
        <div>
            {['number'].includes(question.question_type) &&
                <div className='md:w-1/2'>
                        <>
                            <UnitSelect text="question.select_unit" value={question?.unit ? question.unit : null}
                                onChange={(val) => ChangeQuestion({ ...question, unit: val })} data={choicesList || []} disabled={question?.question_type === 'slider'} />
                            {question.question_type === 'number' &&
                                <>
                                    {question?.unit === 'Fraction' &&
                                        <Input text="question.additional_denominator" type="Number"
                                            value={question?.params?.denominator ? question?.params.denominator : null}
                                            onChange={(val) => ChangeQuestion({ ...question, params: { ...question.params, denominator: val } })} />
                                    }
                                    <div className='flex pt-2 gap-2'>
                                        <Input text="question.number_min" type="Number"
                                            value={question?.params?.min ? question.params.min : null}
                                            onChange={(val) => ChangeQuestion({ ...question, params: { ...question.params, min: val } })} />
                                        <Input text="question.number_max" type="Number"
                                            value={question?.params?.max ? question.params.max : null}
                                            onChange={(val) => ChangeQuestion({ ...question, params: { ...question.params, max: val } })} />
                                    </div>
                                </>
                            }
                        </>
                </div>}
        </div>
  )
}
AdditionalParams.propTypes = {
  question: PropTypes.object,
  ChangeQuestion: PropTypes.func
}
export default AdditionalParams
