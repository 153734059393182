import React from 'react'
import UsersList from '../../../features/users/usersList'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, usersOptions } from '../DynamicNavbarOptions'
import { selectAllUsers } from '../../../features/users/usersSlice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function formatUsersDataPieChart (usersList) {
  let data = {}
  usersList.map((user) => {
    const keyData = user.type
    data = data[keyData] ? { ...data, [keyData]: data[keyData] + 1 } : { ...data, [keyData]: 1 }
  }
  )
  return data
}
function UsersTracking (props) {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const [pieData, setPieData] = React.useState({})
  const usersList = useSelector(selectAllUsers)
  React.useEffect(() => {
    if (props.isSuccess && usersList) {
      const data = formatUsersDataPieChart(usersList)
      setPieData({ labels: Object.keys(data), data: Object.values(data) })
    }
  }, [props.isSuccess, usersList])

  return (
    <div className="w-full pb-16">
      <DynamicNavBar option="navbar.generalOptions.users" options={generalOptions}/>
      <DynamicNavBar option="navbar.usersOptions.users_list" options={usersOptions}/>
        <div className="w-full flex flex-col items-center gap-8  static mt-4" >
          <div className=" md:w-2/3 lg:w-4/6 sm:w-2/3 bg-wite md:mx-8 pb-8">
              <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4">
              {
              // commit pichart not needed for te moment
              /* usersList &&
                <div className="grid grid-cols-3 max-h-fit items-center">
                  <div className="w-full flex justify-center"><UsersSectorPieChart data={pieData || undefined}/></div>
                </div>
              */}
                <div>
                <div className="flex mb-5 justify-between w-full">
                <h5 className="text-2xl text-[#444444] w-max-fit">{t('admin.users.fields.users_list')}</h5>
                  <div className="bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium"
                  style={{ cursor: 'pointer' }} onClick={() => navigate('/admin/users/create')}>{t('admin.users.fields.user_create')}</div>
                  </div>
                  <UsersList usersList={usersList}/>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}
export default UsersTracking
