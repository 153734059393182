
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function ResponseStatisticsNoLabels({ responsesStatistics }) {
  const { t } = useTranslation()
  const [satistics, setSatistics] = React.useState()

  React.useEffect(() => {
    setSatistics(responsesStatistics)
  }, [responsesStatistics])

  if (satistics && satistics?.progress_bar) {
    return (

      <div className="overflow-hidden text-xs flex rounded bg-gray-100 border border-gray-200 w-5/6">
        {Object.values(satistics?.progress_bar).sort((a, b) => a.display_order - b.display_order).map(({ percentage, color }, index) => (
          <div key={index} style={{ width: `${percentage}%`, background: color }}
            className={'shadow-none flex flex-col text-center  whitespace-nowrap text-white justify-center '}>{`${percentage}%`}</div>
        ))}
      </div>
    )
  }
}

ResponseStatisticsNoLabels.propTypes = {
  responsesStatistics: PropTypes.object
}
export default ResponseStatisticsNoLabels
