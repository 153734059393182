import React from 'react'
import { useExportSurveyMutation } from '../../../../features/surveys/surveysSlice'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Spinner } from '../../../../components/loadData/spinner'
import IndexAlert from '../../../../components/alerts/indexAlert'
import ChooseSurvey from './chooseSurvey'

function CreateNewSurvey ({ idSurvey }) {
  const [survey, setSurvey] = React.useState({ id: idSurvey, name: '', description: '', survey: null, action: '' })
  const { t } = useTranslation()
  const hello = (evt) => {
    evt.preventDefault()
    exportSurvey(survey)
  }
  const [exportSurvey, { isLoading, isSuccess: createSuccess, isError: createError, error }] = useExportSurveyMutation()
  React.useEffect(() => {
    if (createSuccess) {
      setSurvey({ ...survey, name: '', description: '' })
    }
  }, [createSuccess])

  return (
    <div className='w-full'>
      {isLoading && <Spinner />}
      {createError &&
        <IndexAlert type="danger" text={JSON.stringify(error.data.error)} />}
      {createSuccess &&
        <IndexAlert type="success" text={t('admin.survey.export_surey.survey_exported')} />}
      <form className="w-full" onSubmit={hello}>
      <div className='flex  px-3  flex-col gap-1  mb-2' onChange={(e) => setSurvey({ ...survey, action: e.target.value })}>
            <label
              htmlFor="price"
              className="block text-xl font-medium text-gray-800">
              {t('admin.survey.export_surey.action_type')}
            </label>
            <div onChange={(e) => setSurvey({ ...survey, action: e.target.value })}>
              <div>
                <input className='form-check-input  border  text-white
                border-gray-300 rounded-sm bg-white checked:bg-teal-600 checked:border-teal-600
                focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain
                float-left mr-2 cursor-pointer' type="radio" value="update" name="gender"
                id="update" style={{ width: '14px', height: '14px' }}/>
                <label htmlFor="update" style={{ cursor: 'pointer' }}>{t('admin.survey.export_surey.update_survey')}</label>
              </div>
              <div>
                <input className='form-check-input  border  text-white
                border-gray-300 rounded-sm bg-white checked:bg-teal-600 checked:border-teal-600
                focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain
                float-left mr-2 cursor-pointer' type="radio" value="create" name="gender"
                id="create" style={{ width: '14px', height: '14px' }}/>
                <label htmlFor="create" style={{ cursor: 'pointer' }}>{t('admin.survey.export_surey.create_new')}</label>
              </div>
            </div>
          </div>
        <div className=" flex-col gap-1 w-full  max-h-fit ">
        {survey.action === 'create' &&
          <div className="form-check-input w-4/5 px-3 mb-2 mt-1 relative rounded-sm">
            <label
              htmlFor="price"
              className="block text-xl font-medium text-gray-800">
              {t('admin.survey.export_surey.survey_titre')}
            </label>
            <input
              placeholder={t('admin.survey.export_surey.survey_titre')} type="text" name="price" id="price"
              className='ocus:ring-teal-500 focus:border-teal-500  block w-full pl-2 pr-12 tsext-lg  text-[#444444] rounded-sm' style={{ border: '1px solid #e8e6e6' }}
              value={survey?.name ? survey.name : ''} required={true}
              onChange={(e) => {
                setSurvey({ ...survey, name: e.target.value })
              }} />
          </div>
        }
        {survey.action === 'update' && <ChooseSurvey survey={survey} setSurvey={setSurvey} />}

          <div className="w-full px-3 mb-6 md:mb-0 relative rounded-sm ">
            <label htmlFor="price" className="block font-medium text-gray-700 text-xl">
              {t('common.fields.description')}
            </label>
            <textarea type="text" name="price" id="price" className="focus:ring-teal-500
                    focus:border-teal-500 block  w-full pl-2 pr-12 text-md  text-[#444444]
                    rounded-sm" style={{ border: '1px solid #e8e6e6' }} rows={6}
              placeholder="Question text" value={survey?.description ? survey.description : ''}
              onChange={(e) => setSurvey({ ...survey, description: e.target.value })}></textarea>
          </div>

        </div>
        <input type="submit" className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
            px-2 py-0.5 mt-4 float-right" value={t('common.buttons.create')} style={{ cursor: 'pointer' }} />
      </form>
    </div>
  )
}

// proptypes
CreateNewSurvey.propTypes = {
  idSurvey: PropTypes.string.isRequired
}

export default CreateNewSurvey
