import React from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import './style.css'
import { useTranslation } from 'react-i18next'

const CreateActivity = ({ activity, children }) => {
  const { t } = useTranslation()
  const ref = React.useRef()
  const closeTooltip = () => ref.current.close()

  const handleAddActivity = (e) => {
    e.preventDefault()

    if (activity?.name && activity?.description) {
      closeTooltip()
    } else { alert(t('userExists')) }
  }

  return (
        <div>
    <Popup
        closeOnDocumentClick={false}
        trigger={children}
        onOpen={() => handleAddActivity()}

        contentStyle={{ width: '400px', heigh: '300px' }}
        modal
        ref={ref}
    >
      {close => (
        <div className="modal">
            <div className="content flex flex-col justify-center items-center ">
                <h5 className="#444 text-lg ">{t('common.popup.create_campaign')}</h5>
            </div>
          <div className="actions">
          <button className="p-1 px-2 text-md bg-gray-300 rounded-sm" onClick={() => closeTooltip()} style={{ cursor: 'pointer' }}>{t('common.popup.close')}</button>
          </div>
        </div>
      )}
    </Popup>
    </div>
  )
}
export default CreateActivity
