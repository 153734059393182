import React from 'react'
import valueanco from '../../assets/valueanco.png'
import DropDownLanguage from './dropDownLanguage'

function NavBarLogin () {
  return (
    <div className="h-10 bg-[#24292F] w-full">
      <div className="h-full  w-4/5 flex justify-between mx-auto">
        <img src={valueanco} alt="valueAndCo logo" className="h-8 w-22 pt-1 pl-2" />
        <div className="mt-2">
          <DropDownLanguage/>
        </div>
      </div>
    </div>
  )
}
export default NavBarLogin
