import React from 'react'
import { useTranslation } from 'react-i18next'

function InputInfo ({ input_value, text, type, disabled }) {
  const { t } = useTranslation()
  return (
        <div>
            <label className="form-check-label inline-block text-gray-800 text-lg " htmlFor="flexCheckChecked" style={{ cursor: 'pointer' }}>
                {t(text)}
            </label>
            <input className="ml-2 pl-2 form-number appearance-none block w-200 py-0.5 text-base font-normal text-gray-700 bg-white bg-clip-padding
              bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white
             focus:border-teal-500 focus:border-1" value={input_value || input_value} type={type} disabled={disabled} />
        </div>
  )
}

export default InputInfo
