import React from 'react'
import { useTranslation } from 'react-i18next'

const InputData = ({ title, text }) => {
  return (
    <div className="w-4/5 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
    <label htmlFor="nom"
      className="block text-md font-medium text-gray-800">
        {title}
    </label>
    <input type="text" name="nom" id="nom"
      className="focus:ring-teal-500
    focus:border-teal-500   w-full pl-2 pr-12 text-lg  text-[#444444]
    rounded-sm" style={{ border: '1px solid #e8e6e6' }}
    disabled={true} value={text}
    />
  </div>
  )
}
const Score = ({ score }) => {
  const { numerator, denominator } = score
  const scoreNumber = (numerator / denominator) * 20

  if (scoreNumber >= 15) {
    return (
    <span className="bg-green-300 text-gray-50 w-16 text-center rounded-md p-1">{scoreNumber + ' / 20'}</span>)
  }
  if (scoreNumber < 15 && scoreNumber > 10) {
    return (
    <span className="bg-yellow-300 text-gray-50 w-16 text-center rounded-md p-1">{scoreNumber + ' / 20'}</span>)
  }
  if (scoreNumber < 15) {
    return (
    <span className= "bg-red-300 text-gray-50 w-16 text-center rounded-md p-1">{scoreNumber + ' / 20'}</span>)
  }
}
function EvaluationDetail ({ evaluation }) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col  w-3/5">
    <h5 className="block text-xl font-medium text-gray-800">{t('consultant.evaluation.result.evaluationDetail')}</h5>
    <div className="flex gap-1 flex-col  w-full pb-4">
      <InputData title={'Nom de réferance'} text={evaluation.name && evaluation.name} />
      <InputData title={'Date de création'} text={evaluation.add_date && evaluation.add_date} />
      <InputData title={'Méthode évaluation'} text={evaluation?.report_data?.evaluationMethod && evaluation.report_data.evaluationMethod} />
      <div className="pl-3">
        <h5 className="block text-md font-medium text-gray-800">{t('Score')}</h5>
        <Score score= {evaluation?.report_data?.results?.properties?.score}/>
      </div>
      <div className="pl-3">
        <h5 className="block text-md font-medium text-gray-800">{t('Taux_confiance')}</h5>
        <Score score= {evaluation?.report_data?.results?.properties?.confidence}/>
      </div>
    </div>
    </div>
  )
}

export default EvaluationDetail
