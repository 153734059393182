import React from 'react'
import { useGetQuestionsCommentsQuery } from '../../features/SurveyComments/surveyCommentsSlice'
import { useParams } from 'react-router-dom'
import OneComment from './onComment'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useTranslation } from 'react-i18next'
const CommentsList = ({ commentUser, question }) => {
  const { t } = useTranslation()
  const { idCampaign } = useParams()
  const [comments, setComments] = React.useState([])
  const { data, isloading, isSuccess } = useGetQuestionsCommentsQuery({ campaign: idCampaign, question })
  const [showComments, setShowComments] = React.useState(true)

  React.useEffect(() => {

  }, [])

  React.useEffect(() => {
    if (data) {
      setComments(data)
    }
  }, [data])
  return (
    <div>
        {comments.length > 0 &&
        <>
        {showComments
          ? <div className='flex' style={{ cursor: 'pointer' }} onClick={() => setShowComments(false)}>
                <KeyboardArrowUpIcon style={{ fill: '#b3b1b1' }}/>
                <p className='text-md text-gray-500'>{t('survey_comment.comments')}</p>
            </div>
          : <div className='flex' style={{ cursor: 'pointer' }} onClick={() => setShowComments(true)}>
                <KeyboardArrowDownIcon style={{ fill: '#b3b1b1' }}/>
                <p className='text-md text-gray-500'>{t('survey_comment.comments')}</p>
            </div>
             }
             <div className='flex flex-col gap-1 max-h-300 overflow-auto mb-1'>
              {showComments ? comments.map((comment, index) => <OneComment key={index} comment={comment}/>) : undefined}
            </div>
         </>
         }

    </div>
  )
}

export default CommentsList
