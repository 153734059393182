import React from 'react'
import FormulasDetailQuestion from '../utils/formulasDetailQuestion'
import { useRegisterQuestionMutation } from '../../../../features/transformQuestion/transformMethodSlice'
import AddIcon from '@mui/icons-material/Add'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

const QuestionDetails = ({ question, questionFormulas, globalFormulas }) => {
  const FORMULAS_OPTIONS = ['python', 'local', 'global']
  const { t } = useTranslation()
  const { idTransform } = useParams()
  const [questionInfo, setQuestionInfo] = React.useState({
    uuid: question.id,
    transformMethod: true,
    question_info: {
      type: question.question_type
    },
    formulas: []
  })
  React.useEffect(() => {
    if (questionFormulas) {
      setQuestionInfo({ ...questionInfo, formulas: questionFormulas })
    }
  }, [questionFormulas])

  React.useEffect(() => {
    if (question) {
      setQuestionInfo({
        ...questionInfo,
        uuid: question.id,
        transformMethod: true,
        question_info: {
          ...questionInfo.question_info,
          type: question.question_type,
          denominator: question?.params?.params?.denominator && question.params.params.denominator,
          unit: question?.params?.unit && question.params.unit
        }
      })
    }
  }, [question])
  const [registerQuestion, { isSuccess }] = useRegisterQuestionMutation()
  const handleAddFormula = () => {
    const FormulaUuid = uuidv4()
    const newFormula = { text: 'formula-' + FormulaUuid.split('-')[0], value: '', id: FormulaUuid, type: 'python' }
    setQuestionInfo({ ...questionInfo, formulas: [...questionInfo.formulas, newFormula] })
  }
  const register = async () => {
    const result = await registerQuestion({ questionInfo, id: idTransform })
  }

  

  return (
    <div className='p-2'>
        <div className='w-4/5'>
          <h2 className=' w-full pl-2 pr-12 text-lg  rounded-md'>{t('admin.survey.transformation_model.create.fields.reference')}</h2>
            <h2 className='bg-gray-200 mx-3  w-full pl-2 pr-12 text-md  rounded-md'>{question.text}</h2>
        </div>
        <div className='w-1/2'>
            <h2 className='block w-full pl-2 pr-12 text-lg  rounded-md'>{t('admin.survey.transformation_model.create.fields.question_text')}</h2>
            <h2 className='mx-3 bg-gray-200 block max-w-fit px-2 tsext-md  rounded-md'>{question.reference}</h2>
        </div>
        <div className='w-1/2'>
            <h2 className='block w-full pl-2 pr-12 text-lg  rounded-md'>{t('admin.survey.transformation_model.create.fields.type')}</h2>
            <h2 className='mx-3 bg-gray-200 block max-w-fit px-2 tsext-md  rounded-md'>{question.question_type}</h2>
        </div>
        <div className='flex mt-3'>
            <label className="form-check-label inline-block text-gray-800 text-lg"
            htmlFor="flexCheckChecked" style={{ cursor: 'pointer' }}>
                    {t('admin.survey.transformation_model.create.fields.formulas')}
            </label>
            {questionInfo?.formulas?.length === 0 &&
            <div style={{ cursor: 'pointer' }} onClick={() => handleAddFormula()}>
                <AddIcon style={{ color: '#00796B' }} />
            </div>
            }
            </div>
        <div className='max-h-600 overflow-auto'>
        {questionInfo.formulas &&
            <FormulasDetailQuestion globalFormulas={globalFormulas} FORMULAS_OPTIONS={FORMULAS_OPTIONS} formulas={questionInfo.formulas || {}}
            onChange={(val) => setQuestionInfo({ ...questionInfo, formulas: val })}/>
        }
        </div>
        {!questionInfo?.formulas?.length !== 0 &&
        <div className="flex mt-10 justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
            p-2  mt-4 float-right" style={{ cursor: 'pointer' }} onClick={() => register()}>{t('admin.survey.transformation_model.create.fields.save')}
            </div>
        }
    </div>

  )
}
QuestionDetails.propTypes = {
  question: PropTypes.object,
  questionFormulas: PropTypes.object,
  globalFormulas: PropTypes.object
}
export default QuestionDetails
