import React from 'react'
import { useTranslation } from 'react-i18next'
import { FilterMatchMode } from 'primereact/api'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'

const QuestionTransformList = ({ data, onChangeQuestion }) => {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        <RemoveRedEyeIcon sx={{ color: 'green', cursor: 'pointer' }} onClick={() => onChangeQuestion(e)}/>
      </div>
    )
  }

  const tableConfig = {
    value: data,
    stateKey: 'dt-state-question-transform-list',
    onFilter: (e) => setFilters(e.filters),
    rowGroupMode: 'rowspan',
    groupRowsBy: 'title',
    header,
    stateStorage: 'session',
    dataKey: 'title',
    emptyMessage: t('admin.survey.transformation_model.tables.model_list_notFound'),
    filters,
    globalFilterFields: ['reference', 'text'],
    columns: [
      { field: 'reference', sortable: true, header: t('common.table.reference'), style: { width: '15%' } },
      { field: 'text', sortable: true, header: t('common.table.text'), style: { width: '70%' } },
      { header: t('common.table.action'), body: actionTemplate, style: { width: '15%' } }
    ]
  }

  const DataTableComponent = createDataTable(tableConfig)
  return (
        <div className="datatable-rowgroup-demo max-h-700 overflow-auto border-1 border-gray-200">
          {DataTableComponent}
        </div>
  )
}
export default QuestionTransformList
