import React from 'react'
import EntityGenralInfo from './components/entityGenralInfo'
import SetMembersList from './components/setMembersList'
import AddSurveys from './components/addSurveys'
import SetMainCustomer from './components/setMainCustomer'
import FinalizeEntity from './components/finalizeEntity'
import SideBarCreateEntity from '../../components/Bars/sideBarCreateEntity'
import { useTranslation } from 'react-i18next'
import EntityInfo from './components/entityInfo'

function EntityAdd () {
  const [step, setStep] = React.useState(1)
  const { t } = useTranslation()

  const changeStep = (stp) => {
    switch (stp) {
      case 1:
        setStep(stp)
        break
      case 2:
        if (!entity.entityInfo.siren & !entity.entityInfo.siret & !entity.entityInfo.nic & !entity.entityInfo.adress & !entity.entityInfo.entityName) {
          alert(t('admin.organisation.create.error.entity_info_missing'))
        } else {
          setStep(stp)
        }
        break
      case 3:
        setStep(stp)
        if (entity.name && entity.employee_Number && entity.activity_sector) { setStep(stp) } else {
          if (!entity.name) {
            alert(t('admin.organisation.create.error.forme_name'))
            break
          }
          if (!entity.employee_Number) {
            alert(t('admin.organisation.create.error.employee_Number'))
            break
          }
          if (!entity.activity_sector) {
            alert(t('admin.organisation.create.error.activity_sector'))
            break
          }
        }
        break
      case 4:
        if (entity.entity_members && entity?.client?.email && entity?.client?.first_name && entity?.client?.last_name) {
          setStep(stp)
        } else {
          alert(t('admin.organisation.create.error.fillUserList'))
        }
        break
      case 5:
        if (!entity.name || !entity.employee_Number || !entity.activity_sector) {
          alert(t('admin.organisation.create.error.general_info'))
          break
        } else {
          setStep(stp)
          break
        }
      case 6:
        if (
          (Boolean(!entity?.customer?.exist && entity.customer?.first_name && entity.customer?.last_name && entity.customer?.email)) ||
            (Boolean(entity.customer?.exist && entity.customer.id))) {
          setStep(stp)
        } else { alert(t('admin.organisation.create.error.principal_user')) }
        break
      default:
    }
  }
  const [entity, setEntity] = React.useState({ survey_trees: [], entityInfo: { siren: '', siret: '', nic: '', adress: '', entityName: '' } })

  const pageTitle = { 1: t('admin.organisation.create.title.organisation_infos'), 2: t('admin.organisation.create.title.create_organisation'), 3: t('admin.organisation.create.title.add_entity_members'), 4: t('admin.organisation.create.title.add_surveys'), 5: t('admin.organisation.create.title.set_main_customer'), 6: t('admin.organisation.create.title.finalize') }
  return (
    <>
        <div className="w-full mx-auto   flex  items-center gap-4  static">
          <div className="w-400">
            <SideBarCreateEntity step={step} onChangeStep={changeStep}/>
          </div>
        <div className="w-full min-h-screen">
        <div className="w-full  mb-18 mt-5">
          <h5 className="text-2xl font-medium text-[#444444]">{pageTitle[step]}</h5>
          <div className="bg-[#ffffff] z-40 md:w-5/6 lg:w-4/6 px-2 pt-1 mt-2 shadow-sm pl-4 h-full pb-14">
            {step === 1 && <EntityInfo onChangeEntity ={setEntity} entity ={entity} onChangeStep={changeStep}/>}
            {step === 2 && <EntityGenralInfo onChangeEntity ={setEntity} entity ={entity} onChangeStep={changeStep}/>}
            {step === 3 && <SetMembersList onChangeEntity ={setEntity} entity={entity} onChangeStep={changeStep}/>}
            {step === 4 && <AddSurveys onChangeEntity ={setEntity} entity ={entity} onChangeStep={changeStep}/>}
            {step === 5 && <SetMainCustomer onChangeEntity ={setEntity} entity ={entity} onChangeStep={changeStep}/>}
            {step === 6 && <FinalizeEntity onChangeEntity ={setEntity} entity ={entity} onChangeStep={changeStep}/>}
          <div>
        </div>
        </div>
    </div>
    </div>
    </div>
    </>
  )
}
export default EntityAdd
