import React from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import CreateEntity from './createEntity'
import EditEntity from './editEntity'
import EntitiesTracking from './entitiesTracking'
import DetailsEntity from './detailsEntity'
import { useGetEntitiesAdminQuery } from '../../../features/entities/entitiesSlice'
import { selectCurrentUser } from '../../../features/auth/authSlice'
import { useSelector } from 'react-redux'

function IndexEntity () {
  const user = useSelector(selectCurrentUser)
  const id = user.id
  const { data: entities, isSuccess: isSuccessEntities } = useGetEntitiesAdminQuery(id)

  return (
    <>
        <Outlet/>
        <Routes>
            <Route path="create" exact element={<CreateEntity/>}></Route>
            <Route path="edit/:id" exact element={<EditEntity/>}></Route>
            <Route path="details/:id" exact element={<DetailsEntity entities={entities}/>}></Route>
            <Route path="" exact element={<EntitiesTracking isSuccess={isSuccessEntities} entities={entities} />}></Route>
        </Routes>
    </>
  )
}

export default IndexEntity
