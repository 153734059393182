import React from 'react'
import ModifierUser from './components/modifierUser'
import { useGetUserQuery } from './usersSlice'
import SimpleSkelton from '../../components/loadData/simpleSkelton'
import { useTranslation } from 'react-i18next'

function UserEdit ({ id, userType }) {
  const { t } = useTranslation()
  const [user, setUser] = React.useState({})

  const { data: userModif, isSuccess, isLoading, isError } = useGetUserQuery(id)
  React.useEffect(() => {
    if (userModif) {
      setUser(userModif)
    }
  }, [userModif])

  if (isLoading) {
    return <SimpleSkelton/>
  }
  if (isError) {
    return undefined
  }

  if (isSuccess && user) {
    return (
    <div className="w-full">
      <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
        <label htmlFor="price" className="block font-medium text-gray-800 text-md">
          {t('admin.users.create.fields.name')}
        </label>
        <input type="text" name="price" id="price" className="focus:ring-teal-500
        focus:border-teal-500 h-8 block  w-full pl-2 pr-12 text-md  text-[#444444]
        rounded-sm" style={{ border: '2px solid #e8e6e6' }} placeholder={t('name')}
          value={user?.last_name ? user.last_name : ''}
          onChange={(e) => setUser({ ...user, last_name: e.target.value })}/>
      </div>
      <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
        <label htmlFor="price" className="block font-medium text-gray-800 text-md">
          {t('admin.users.create.fields.prenom')}
        </label>
        <input type="text" name="price" id="price" className="focus:ring-teal-500
        focus:border-teal-500 h-8 block  w-full pl-2 pr-12 text-md  text-[#444444]
        rounded-sm" style={{ border: '2px solid #e8e6e6' }} placeholder={t('prenom')}
          value={user?.first_name ? user.first_name : ''}
          onChange={(e) => setUser({ ...user, first_name: e.target.value })}/>
      </div>
      <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
        <label htmlFor="price" className="block font-medium text-gray-800 text-md">
        {t('admin.users.create.fields.adresse_email')}
        </label>
        <input type="text" name="price" id="price" className="focus:ring-teal-500
        focus:border-teal-500 h-8 block  w-full pl-2 pr-12 text-md  text-[#444444]
        rounded-sm" style={{ border: '2px solid #e8e6e6' }} placeholder={t('Adresse_email')}
          value={user?.email ? user.email : ''}
          onChange={(e) => setUser({ ...user, email: e.target.value })}/>
      </div>
      {userModif.type === 'ADMIN' || userModif.type === 'SUPERADMIN'
        ? <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
      <label htmlFor="price" className="block font-medium text-gray-800 text-md">
      {t('admin.users.edit.fields.type_utilisateur')}
        </label>
      <select className="focus:ring-teal-500 focus:border-teal-500 block border-2  border-[#e8e6e6] h-8 w-full pl-1 pr-2 text-lg  text-[#444444]
          rounded-sm" value ={user?.type ? user.type : undefined }
        onChange={(e) => setUser({ ...user, type: e.target.value })}>
            <option></option>
            {Object.keys(userType).map((type, index) => { return <option key={index} value={type}>{type}</option> })}
      </select>
  </div>
        : undefined}
      <div className="md:w-3/5  px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
      {user?.type && user.type !== 'CLIENT'
        ? <div className="w-400 bg-gray-200 p-1 mt-2">{t(userType[user.type])}</div>
        : undefined}
      <ModifierUser user={user}>
        <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
        px-2 py-0.5 mt-4 float-right" style={{ cursor: 'pointer' }}>{t('common.buttons.modify')}
        </div>
      </ModifierUser>
      </div>
  </div>
    )
  }
}

export default UserEdit
