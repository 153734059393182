import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const surveycampaignsAdapter = createEntityAdapter({
})
const initialState = surveycampaignsAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const surveyCampaignsApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'surveycampaignsSlice',
  endpoints: builder => ({
    getsurveyCampaign: builder.query({
      query: (id) => `/campaign/shrink/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['SingleCampain']
    })
  })
})
export const {
  useGetsurveyCampaignQuery
} = surveyCampaignsApiSlice

export const selectSurveyCampaignsResult = surveyCampaignsApiSlice.endpoints.getsurveyCampaign.select()

export const selectsurveyCampainsData = createSelector(
  selectSurveyCampaignsResult,
  campaignsResult => campaignsResult.data
)

export const {
  selectAll: selectAllCampaigns,
  selectById: selectSingleCampaign
} = surveycampaignsAdapter.getSelectors(state => selectsurveyCampainsData(state) ?? initialState)
