import React from 'react'
import { FilterMatchMode } from 'primereact/api'
import { Link } from 'react-router-dom'
import { useGetQuestionsQuery } from '../../../../features/questions/questionSlice'
import { useTranslation } from 'react-i18next'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import EditIcon from '@mui/icons-material/Edit'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'

function QuestionList ({ setNodeSurvey, nodeSurvey }) {
  const { t } = useTranslation()
  const { data: questions, isSuccess, isLoading, isError, error } = useGetQuestionsQuery()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  React.useEffect(() => {
    if (questions) {
      setNodeSurvey({ ...nodeSurvey, question: questions.filter((qst) => nodeSurvey.question.includes(qst.id)) })
    }
  }, [questions])
  const actionTemplate = (e) => {
    return (
      <div className="flex flex-col gap-1">
        <Link to={`/admin/survey/question/${e.id}/details`} target="_blank"> <RemoveRedEyeIcon sx={{ color: 'green', cursor: 'pointer' }}/></Link>
        <Link to={`/admin/survey/question/${e.id}/edit`} target="_blank"><EditIcon sx={{ color: 'orange', cursor: 'pointer' }}/></Link>
      </div>
    )
  }

  const statusBodyTemplate = (rowData) => {
    if (rowData) {
      return <span className={`product-badge status-${rowData.question_type.toLowerCase()}`}>{rowData.question_type}</span>
    }
  }
  const textBodyTemplate = (rowData) => {
    return <>{rowData.text.length > 45 ? rowData.text.substring(0, 45) + '...' : rowData.text}</>
  }

  const tableActions = {
    setNode: (e) => setNodeSurvey({ ...nodeSurvey, question: e.value })
  }

  const tableConfig = {
    value: questions,
    scrollable: true,
    scrollHeight: '250px',
    dataKey: 'id',
    filters,
    header,
    globalFilterFields: ['text', 'type', 'reference'],
    emptyMessage: t('admin.survey.surveys.create.list_question_notFound'),
    selection: nodeSurvey.question,
    onSelectionChange: tableActions.setNode,
    columns: [
      { selectionMode: 'multiple', sortable: true, style: { maxWidth: '5%' } },
      { header: t('common.table.reference'), field: 'reference', sortable: true, style: { maxWidth: '15%' } },
      { header: t('common.table.type'), field: 'type', body: statusBodyTemplate, sortable: true, style: { maxWidth: '10%' } },
      { field: 'text', header: t('common.table.text'), body: textBodyTemplate, sortable: true, style: { maxWidth: '60%' } },
      { body: actionTemplate, header: t('common.table.action'), style: { maxWidth: '12%' } }
    ]
  }

  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div>
        <div className="card w-full border-1 border-gray-300">
          {DataTableComponent}
        </div>
    </div>

  )
}
export default QuestionList
