// ImportResponseModelTable2.js
import React from 'react'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { useTranslation } from 'react-i18next'
import { FilterMatchMode } from 'primereact/api'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'

const ImportResponseModelTable2 = ({ data }) => {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: data,
    stateKey: 'dt-state-import-response-model-list',
    onFilter: (e) => setFilters(e.filters),
    header,
    style: { fontSize: '14px' },
    globalFilterFields: ['NAME', 'ANSWER_1', 'ANSWER_2', 'ANSWER_3', 'ANSWER_4', 'ANSWER_5', 'ANSWER_6'],
    filters,
    dataKey: 'type',
    emptyMessage: t('admin.survey.import_export.import_repsonseModel_notFound'),
    columns: [
      { field: 'UUID', sortable: true, header: t('admin.survey.import_export.UUID') },
      { field: 'NAME', sortable: true, header: t('admin.survey.import_export.NAME') },
      { field: 'ANSWER_1', sortable: true, header: t('admin.survey.import_export.ANSWER_1') },
      { field: 'ANSWER_2', sortable: true, header: t('admin.survey.import_export.ANSWER_2') },
      { field: 'ANSWER_3', sortable: true, header: t('admin.survey.import_export.ANSWER_3') },
      { field: 'ANSWER_4', sortable: true, header: t('admin.survey.import_export.ANSWER_4') },
      { field: 'ANSWER_5', sortable: true, header: t('admin.survey.import_export.ANSWER_5') },
      { field: 'ANSWER_6', sortable: true, header: t('admin.survey.import_export.ANSWER_6') }

    ]
  }

  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div className='datatable-rowgroup-demo max-h-500 overflow-auto border-1 border-gray-200 mb-2'>
      {DataTableComponent}
    </div>
  )
}

export default ImportResponseModelTable2
