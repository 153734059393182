import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { useTranslation } from 'react-i18next'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'

const UserListGrid = ({ data, setUsersList, usersList }) => {
  const { t } = useTranslation()

  const [filters, setFilters] = React.useState(null)
  const [selectedData, setSelectedData] = useState(null)
  const [rowData, setRowData] = useState()
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')

  React.useEffect(() => {
    if (data) {
      setRowData(data)
    }
  }, [data])

  React.useEffect(() => {
    if (usersList) {
      setSelectedData(usersList)
    }
  }, [usersList])

  const initFilters = () => {
    setFilters({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } })
  }
  React.useEffect(() => {
    initFilters()
  },
  [])

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    const _filters = { ...filters }
    _filters.global.value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const ReloadData = () => {
    setUsersList([])
  }
  const DeleteRows = () => {
    setUsersList(rowData.filter((data) => !selectedData.includes(data)))
  }

  const renderHeader2 = () => {
    return (
      <div className="flex justify-content-start gap-1 ">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" className="h-8" />
        </span>
        <div className="flex">
          <button
            style={{ marginLeft: '20px' }}
            className="flex gap-1 min-w-fit px-1 bg-gray-300 items-center text-md rounded-md p-1"
            onClick={() => ReloadData()}>
            <RotateLeftIcon sx={{ color: '#0303a3', fontSize: 20 }} />
            <h5 className="h-full font-medium px-1 text-md">Reset</h5>
          </button>
        </div>
      </div>
    )
  }
  const header = renderHeader2()

  const tableActions = {
    UsersList: (e) => setUsersList(e.value)

  }

  const tableConfig = {
    value: rowData,
    stateKey: 'dt-state-entities-list',
    onFilter: (e) => setFilters(e.filters),
    filters,
    selectionMode: 'checkbox',
    selectionPageOnly: true,
    selection: selectedData,
    onSelectionChange: tableActions.UsersList,
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    header,
    globalFilterFields: ['first_name', 'last_name', 'email', 'type'],
    columns: [
      { selectionMode: 'multiple', headerStyle: { width: '3em' } },
      { field: 'last_name', sortable: true, header: t('common.fields.last_name'), style: { width: '10%' } },
      { field: 'first_name', sortable: true, header: t('common.fields.first_name'), style: { width: '10%' } },
      { field: 'email', sortable: true, header: t('common.fields.email_address'), style: { width: '30%' } },
      { field: 'post', sortable: true, header: t('common.fields.position') },
      { field: 'type', sortable: true, header: t('common.fields.type') }
    ]
  }
  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div className="w-full h-max-fit">
      <div className=" mx-auto w-full border-1 border-gray-300 ">
        {DataTableComponent}
      </div>
    </div>
  )
}

export default UserListGrid
