import React from 'react'
import { useGetQuestionTagsQuery, useGetOptionsQuery, useGetOptionsTemplatesQuery } from './questionSlice'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import IndexAlert from '../../components/alerts/indexAlert'
import ShowAdditionalParams from './components/showAdditionalParams'
import { MultiSelect } from 'primereact/multiselect'
import PropTypes from 'prop-types'

export function QuestionDetails ({ data }) {
  const { t } = useTranslation()
  const { data: questionTags } = useGetQuestionTagsQuery()
  const { data: questionTemplates } = useGetOptionsTemplatesQuery()
  const { data: options } = useGetOptionsQuery()
  const navigate = useNavigate()

  const [question, setQuestion] = React.useState({
    text: '',
    question_type: '',
    select_options: [],
    question_tag: [],
    options_model: ''
  })

  React.useEffect(() => {
    if (data) {
      setQuestion({
        ...question,
        id: data.id,
        text: data.text,
        question_type: data.question_type,
        select_options: data.select_options,
        options_model: data.options_model,
        question_help_text: data.question_help_text,
        question_tag: questionTags?.filter((tag) => data?.question_tag?.includes(tag.id)),
        showParams: false,
        params: data?.params?.params ? data.params.params : null,
        unit: data?.params?.unit ? data.params.unit : null
      })
    }
  }, [data])
  const questionType = ['select', 'number', 'boolean', 'multiselect', 'text', 'slider', 'double_slider']

  return (
    <div className="w-full">
      <div className="md:w-full px-0.5 mb-6 md:mb-0 relative rounded-sm ">
        <label htmlFor="price" className="block mt-2 mb-1 font-medium text-gray-700 text-lg">
          {t('question.text')}
        </label>
        <textarea type="text" name="price" id="price" className="focus:ring-teal-500
    focus:border-teal-500 block  w-full pl-2 pr-12 text-lg  text-[#444444]
    rounded-sm" style={{ border: '1px solid #e8e6e6' }} rows={6} disabled = {true}
          placeholder="Question text" value={question?.text ? question.text : ''}></textarea>
      </div>
      <label htmlFor="price" className="block font-medium text-gray-700 text-lg">
          {t('question.question_help_text')}
        </label>
        <textarea type="text" name="price" id="price" className="focus:ring-teal-500
        focus:border-teal-500 block  w-full pl-2 pr-12 text-lg  text-[#444444] rounded-sm" rows={4}
        style={{ border: '1px solid #e8e6e6' }} placeholder={t('question.question_help_text_placeholder')}
        disabled = {true} value={question?.question_help_text ? question.question_help_text : ''}></textarea>
      <div className="md:w-full mb-6 md:mb-0 mt-1 relative rounded-sm ">
        <label htmlFor="price" className="block mt-2 mb-1 font-medium text-gray-700 text-lg">
          {t('question.questionType')}
        </label>
        <select className="focus:ring-teal-500 focus:border-teal-500 block border-2  border-[#e8e6e6] h-8 w-full pl-1 pr-2 text-lg  text-[#444444]
    rounded-sm" value={question?.question_type ? question.question_type : undefined} disabled = {true}>
          <option></option>
          {questionType.map((qst, index) => <option key={index} value={qst}>{qst}</option>)}
        </select>
        {question?.params
          ? <ShowAdditionalParams question={question || null} ChangeQuestion={(val) => setQuestion(val)} />
          : <div className='pt-1'>
            <IndexAlert text={t('question.no_additional_params')} type="warning" />
          </div>}
        {['select', 'multiselect', 'slider', 'double_slider'].includes(question.question_type) &&
          <div className="w-full flex gap-4">
            <div className='w-full'>
              <label htmlFor="price" className="block mt-2 mb-1 font-medium text-gray-700 text-lg">
                {t('question.questions_options')}
              </label>
              <div className="w-full flex jusify-between gap-2">
                <select className="w-full focus:ring-teal-500 focus:border-teal-500 block border-2
                  border-[#e8e6e6] pl-1 pr-2 text-lg  text-[#444444] max-w-100
                  rounded-sm" disabled={true} value={question?.select_options ? question.select_options : []} multiple={true}>
                  {options ? options.map((op, index) => <option key={index} value={op.id}>{op.text}</option>) : undefined}
                </select>

                <div className='rounded-full bg-teal-700 max-h-fit align-middle' style={{ cursor: 'pointer' }}>
                  <AddIcon onClick={() => navigate('/admin/survey/questions/parametres')} style={{ fill: '#fff' }} />
                </div>
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="price" className="block mt-2 mb-1 font-medium text-gray-700 text-lg">
                {t('question.respond_model')}
              </label>
              <div className="w-full flex jusify-between gap-2">
                <select className="w-full focus:ring-teal-500 focus:border-teal-500 block border-2
                    border-[#e8e6e6] pl-1 pr-2 text-lg  text-[#444444] max-w-100
                    rounded-sm" disabled={true}
                  value={question?.options_model ? question.options_model : ''}>
                  <option></option>
                  {questionTemplates ? questionTemplates.map((op, index) => <option key={index} value={op.id}>{op.title}</option>) : undefined}
                </select>
                <div className='rounded-full bg-teal-700 max-h-fit align-middle' style={{ cursor: 'pointer' }}>
                  <AddIcon onClick={() => navigate('/admin/survey/questions/parametres')} style={{ fill: '#fff' }} />
                </div>
              </div>
            </div>
          </div>
        }
        <label htmlFor="price" className="block mt-2 mb-1 font-medium text-gray-700 text-lg">
          {t('question.question_tags')}
        </label>

        <div className="card flex md:w-3/4 sm:w-1/2">
            <MultiSelect value={question?.question_tag || []} disabled options={questionTags}
            optionLabel="text" display="chip" filter placeholder={t('question.question_tags')} maxSelectedLabels={15} className="w-full rounded-sm" />
        </div>
      </div>
    </div>
  )
}

QuestionDetails.propTypes = {
  data: PropTypes.object
}
export default QuestionDetails
