
export const dfAllowCreator = (selectedList, membersList, allow, client) => {
  let clientData = getClientData(client, membersList)
  let finalList = selectedList
  if (allow) {
    if (clientData) {
      finalList.push(clientData)
    } else {
      clientData = { id: membersList.length, email: client.email, name: client.name }
      finalList.push(clientData)
    }
  } else {
    finalList = finalList.filter(member => member.email !== client.email)
  }
  return finalList
}

const getClientData = (client, membersList) => {
  return membersList.find(member => member.email === client.email)
}
