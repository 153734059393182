import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetEvaluationResultsQuery, useGetEvaluationChartQuery, useGetEvaluationQuestionChartQuery } from '../../../features/evaluation/evaluationSlice'
import RadioButton from './components/radioButton'
import { useTranslation } from 'react-i18next'
import EvaluationResultCharts from './charts/evaluationResultCharts'
import EvaluationInfo from './components/evaluationInfo'
import EvaluationTreeCharts from './charts/evaluationTreeCharts'
import NestedAccordion from './charts/nestedAccordion'
import CheckBox from './components/checkBox'
import { useGetEvaluationCommentsQuery } from '../../../features/SurveyComments/surveyCommentsSlice'

const EvaluationNotes = () => {
  const { evaluationId } = useParams()
  const { data: evaluation, isLoading, isSuccess } = useGetEvaluationChartQuery(evaluationId)
  const { t } = useTranslation()
  const VISUALISATIONOPTION = [t('consultant.evaluation.result.Sunburst'), t('consultant.evaluation.result.simple_tree')]
  const [diagramType, setDiagramType] = React.useState(VISUALISATIONOPTION[1])
  const [evaluationResult, setEvaluationResult] = React.useState()
  const [showEvaluationInfo, setShowEvaluationInfo] = React.useState()
  const [showEvaluationDetails, setShowEvaluationDetails] = React.useState(false)
  const { data: nodesEvaluation } = useGetEvaluationResultsQuery(evaluationId)
  const { data: evaluationQuestionChart } = useGetEvaluationQuestionChartQuery(evaluationId)
  const { data: comments } = useGetEvaluationCommentsQuery(evaluationId)

  React.useEffect(() => {
    if (nodesEvaluation) {
      setEvaluationResult(
        {
          name: nodesEvaluation?.name ? nodesEvaluation.name : '',
          nodesResults: nodesEvaluation?.evaluation_record ? Object.values(nodesEvaluation.evaluation_record) : []
        }
      )
    }
  }, [nodesEvaluation])

  return (
    <div className='w-full px-4 py-4'>
      <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('consultant.evaluation.result.node_result')}</h5>
      <div className='flex flex-col'>
        <CheckBox text={t('consultant.evaluation.result.show_evaluation_info')} value={showEvaluationInfo} onChange={(val) => setShowEvaluationInfo(val)} disabled={false}/>
        { showEvaluationInfo && <EvaluationInfo evaluation={evaluation}/> }
      </div>
      <RadioButton dataList={VISUALISATIONOPTION} value={diagramType} onChangeRadio = {(val) => setDiagramType(val)}/>
      <div className='flex justify-center'>
        {diagramType === VISUALISATIONOPTION[0] && <EvaluationResultCharts evaluation ={evaluation} isLoading={isLoading} isSuccess={isSuccess}/>}
        {diagramType === VISUALISATIONOPTION[1] && <EvaluationTreeCharts evaluation ={evaluation} isLoading={isLoading} isSuccess={isSuccess}/>}
      </div>
      <div className='pb-12'>
        <CheckBox text={t('consultant.evaluation.result.show_evaluation_details')} value={showEvaluationDetails} onChange={(val) => setShowEvaluationDetails(val)} disabled={false}/>
        {showEvaluationDetails && <NestedAccordion evaluation ={evaluationQuestionChart}
        comments= {comments} isLoading={isLoading} isSuccess={isSuccess}/>}
        </div>
    {// <EvaluationResults evaluationResult={evaluationResult?.nodesResults || []}/>
    }
    </div>
  )
}

export default EvaluationNotes
