import React, { useState } from 'react'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions } from '../DynamicNavbarOptions'
import { useTranslation } from 'react-i18next'
import IndexAlert from '../../../components/alerts/indexAlert'
import { Spinner } from '../../../components/loadData/spinner'
import { useCreateSurveyMutation } from '../../../features/surveysCreated/surveysSlice'
import { useNavigate } from 'react-router-dom'

function StartCreate () {
  const [survey, setSurvey] = useState({ title: '', description: '' })
  const [surveyResult, setSurveyResult] = useState({})
  const [CreateSurvey, { isSuccess, isLoading, isError, error }] = useCreateSurveyMutation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleCreateSurvey = async () => {
    const surveyCreated = await CreateSurvey(survey)
    setSurveyResult(surveyCreated.data)
  }
  return (
  <div className="w-full">
    <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
    <DynamicNavBar option="navbar.surveyOptions.create_survey" options={surveyOptions}/>
    <div className="mx-auto mt-2 w-1/3" >
    {isSuccess && <IndexAlert type="success" text={t('question.survey_created')}/>}
    {isLoading && <Spinner/>}
    {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)}/> </div>}

      <h5 className="text-center text-2xl font-medium text-[#444444] mb-3 w-full ">{t('admin.survey.surveys.create.create_survey')}</h5>
      <div className="flex flex-col gap-1 w-full bg-gray-50 max-h-fit">
        <div className="w-2/3 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
            <label
              htmlFor="price"
              className="block text-lg font-medium text-gray-800">
              {t('admin.survey.surveys.create.survey_titre')}
            </label>
            <input
            placeholder={t('admin.survey.surveys.create.survey_titre')} type="text" name="price" id="price"
            className="focus:ring-teal-500 focus:border-teal-500 block
              w-full pl-2 pr-12 tsext-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}
              value={survey?.title ? survey.title : ''}
              onChange={(e) =>
                setSurvey({ ...survey, title: e.target.value })}
            />
        </div>
        <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm ">
          <label
            htmlFor="price"
            className="block text-lg font-medium text-gray-800">
            {t('admin.organisation.create.fields.survey_tag')}
          </label>
          <select className="focus:ring-teal-500 focus:border-teal-500 block border-1  border-[#e8e6e6] w-full pl-1 pr-2 text-cxl py-1  text-[#444444]
          rounded-sm" value ={survey?.tag || undefined }
        onChange={(e) => setSurvey({ ...survey, tag: e.target.value })}>
        <option></option>
        <option value={'materiality_matrix'}>{t('common.materiality_matrix')}</option>
        <option value={'survey'}>{t('common.survey')}</option>
      </select>
        </div>
          <div className="w-full px- px-3 mb-6 md:mb-0 relative rounded-sm ">
              <label htmlFor="price" className="block font-medium text-gray-700 text-lg">
                {t('common.fields.description')}
              </label>
              <textarea type="text" name="price" id="price" className="focus:ring-teal-500
              focus:border-teal-500 block  w-full pl-2 pr-12 text-md  text-[#444444]
              rounded-sm" style={{ border: '1px solid #e8e6e6' }} rows={6}
              placeholder={t('common.fields.description')} value={survey?.description ? survey.description : ''}
                onChange={(e) => setSurvey({ ...survey, description: e.target.value })}></textarea>
            </div>
        </div>

            {isSuccess
              ? <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
            px-2 py-0.5 mt-4 float-right" style={{ cursor: 'pointer' }} onClick={() => navigate(`/admin/survey/create/${surveyResult.id}`)}>{t('question.next')}
            </div>
              : <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
            px-2 py-0.5 mt-4 float-right" style={{ cursor: 'pointer' }} onClick={() => handleCreateSurvey()}>{t('common.buttons.create')}
            </div>}

          </div>

  </div>
  )
}

export default StartCreate
