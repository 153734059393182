import React from 'react'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions } from '../DynamicNavbarOptions'
import QuestionGeneralList from './components/questionGeneralList'
import { useGetQuestionsQuery } from '../../../features/questions/questionSlice'
import { useTranslation } from 'react-i18next'
import QuestionAddComponent from './questionAddComponent'

function QuestionTracking () {
  const { t } = useTranslation()
  const { data: questions } = useGetQuestionsQuery()
  return (
    <div className="w-full pb-16">
      <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
      <DynamicNavBar option="navbar.surveyOptions.question_list" options={surveyOptions}/>
        <div className="w-full flex flex-col items-center gap-8  static mt-4" >
          <div className=" md:w-2/3 lg:w-4/6 sm:w-2/3 bg-wite md:mx-8 pb-8">
            <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('admin.survey.questions.question_Information')}</h5>
              <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4">
                <div>
                  <div className="flex justify-between w-full">
                  </div>
                  <QuestionGeneralList questionData={questions || []}/>
                </div>
                <QuestionAddComponent/>
              </div>
            </div>
        </div>
    </div>
  )
}
export default QuestionTracking
