
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function ResponseStatistics ({ responsesStatistics }) {
  const { t } = useTranslation()
  const [satistics, setSatistics] = React.useState()

  React.useEffect(() => {
    setSatistics(responsesStatistics)
  }, [responsesStatistics])

  if (satistics && satistics?.progress_bar) {
    return (
            <div>
                <div className="relative pt-1 mx-5">
                    <div className="mb-4 flex  gap-2">
                        {Object.values(satistics?.progress_bar).sort((a, b) => a.display_order - b.display_order).map(({ label, color }, index) => (
                            <div key={index} className={'p-0.5 rounded flex '} style={{ background: color }}>
                                <p className='text-gray-50' >{t(`survey.states.${label}`)}</p>
                            </div>
                        ))}
                    </div>
                    <div className="overflow-hidden h-4 mb-4 text-xs flex rounded bg-gray-100 border border-gray-200">
                        {Object.values(satistics?.progress_bar).sort((a, b) => a.display_order - b.display_order).map(({ percentage, color }, index) => (
                                <div key={index} style={{ width: `${percentage}%`, background: color }}
                                className={'shadow-none flex flex-col text-center  whitespace-nowrap text-white justify-center '}>{`${percentage}%`}</div>
                        ))}
                    </div>
                </div>
            </div>
    )
  }
}

ResponseStatistics.propTypes = {
  responsesStatistics: PropTypes.object
}
export default ResponseStatistics
