import React from 'react'
import 'primeflex/primeflex.css'
import { FilterMatchMode } from 'primereact/api'
import { useTranslation } from 'react-i18next'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'
function CampaignUserList ({ data }) {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: data,
    stateKey: 'dt-state-compaign-user-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    emptyMessage: t('entity.entity_list_notFound'),
    header,
    filters,
    globalFilterFields: ['last_name', 'first_name', 'email', 'post', 'type'],

    columns: [
      { field: 'last_name', sortable: true, header: t('last_name') },
      { field: 'first_name', sortable: true, header: t('first_name') },
      { field: 'email', sortable: true, header: t('Adresse_email') },
      { field: 'post', sortable: true, header: t('Poste') },
      { field: 'type', sortable: true, header: t('Type') }
    ]
  }

  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div className="p-1">
        <div className="card mx-auto w-full border-1 border-gray-300 ">
          {DataTableComponent}
        </div>
    </div>

  )
}
export default CampaignUserList
