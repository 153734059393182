import React from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import './style.css'
import BeatLoader from 'react-spinners/BeatLoader'
import { useCreateMaterialityMatrixMutation } from '../../../../features/materialityMatrix/materialityMatrixSlice'
import IndexAlert from '../../../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

const CreateMaterialityMatrix = ({ children, materialityMatrix }) => {
  const navigate = useNavigate()
  const { idEntity } = useParams()
  const { t } = useTranslation()
  const [createMaterialityMatrix, { data: materialitMatrix, isLoading, isSuccess, isError, error }] = useCreateMaterialityMatrixMutation()
  const HandleAddEntity = async (e) => {
    await createMaterialityMatrix(materialityMatrix)
  }
  const ref = React.useRef()
  const closeTooltip = () => {
    ref.current.close()
    if (isSuccess) {
      navigate(`/hub/entity/${idEntity}/materiality_matrix`)
    }
  }
  if (materialityMatrix?.name === '' || materialityMatrix?.intern_campaign === '' || materialityMatrix?.extern_campaign === '') {
    return (
    <div>
    <Popup
        closeOnDocumentClick={false}
        trigger={children}
        contentStyle={{ width: '400px', heigh: '300px' }}
        modal
        ref={ref}>
      {close => (
        <div className="modal">
            <div className="content flex flex-col justify-center items-center ">
                <h5 className="#444 text-lg ">{t('common.popup.createEvaluation')}</h5>
                <div className="mt-2">
                  <div>
                  <IndexAlert type="danger" text="Veuillez remplir tous les cases du formulaire"/>
                </div>
              </div>

            </div>
          <div className="actions">
          <botton className="text-lg bg-gray-200 rouned-md px-2 py-0.5" onClick={() => closeTooltip()} style={{ cursor: 'pointer' }}>{t('common.popup.close')}</botton>
          </div>
        </div>
      )}
    </Popup>
    </div>

    )
  } else {
    return (
    <div>
    <Popup
        closeOnDocumentClick={false}
        trigger={children}
        contentStyle={{ width: '400px', heigh: '300px' }}
        onOpen={(e) => HandleAddEntity(e)}
        modal
        ref={ref}>
      {close => (
        <div className="modal">
            <div className="content flex flex-col justify-center items-center ">
                <h5 className="#444 text-lg ">{t('common.popup.createEvaluation')}</h5>
                <div className="mt-2">
                  {isLoading && <BeatLoader color="#086e7c"/>}
                  {isSuccess && <div className="flex flex-col gap-1">
                    <IndexAlert type="success" text="Félicitation votre les notes ont été transformés"/></div>}
                  {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)}/> </div>}
                </div>
            </div>
          <div className="actions">
          <botton className="text-lg bg-gray-200 rouned-md px-2 py-0.5" onClick={() => closeTooltip()} style={{ cursor: 'pointer' }}>{t('common.popup.close')}</botton>
          </div>
        </div>
      )}
    </Popup>
    </div>
    )
  }
}
CreateMaterialityMatrix.propTypes = {
  children: PropTypes.object,
  evaluation: PropTypes.object
}
export default CreateMaterialityMatrix
