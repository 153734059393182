import React from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import './style.css'
import BeatLoader from 'react-spinners/BeatLoader'
import { ConfirmImportFileMethod } from '../import/axios_sendFiles'
import { useNavigate } from 'react-router-dom'
import IndexAlert from '../../../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const ConfirmImportPop = ({ children, file, confirmation }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [result, setResult] = React.useState()
  const [isLoading, setIsLoading] = React.useState()

  const HandleConfirmImport = async () => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('user_response', confirmation)

    try {
      setIsLoading(true)
      const data = await ConfirmImportFileMethod(formData)
      setIsLoading(false)
      setResult(data)
    } catch (error) {
      setIsLoading(false)
      setResult(error)
    }
  }
  const ref = React.useRef()
  const closeTooltip = () => {
    ref.current.close()
    if (result?.status === 'success') {
      navigate('/admin/survey/transformation_model')
    }
    if (result?.status === 'error') {
      window.history.go(-1)
    }
  }
  return (
    <div>
    <Popup
        closeOnDocumentClick={false}
        trigger={children}
        onOpen={() => HandleConfirmImport()}

        contentStyle={{ width: '400px', heigh: '300px' }}
        modal
        ref={ref}
    >
      {close => (
        <div className="modal">
            <div className="content flex flex-col justify-center items-center ">
                <h5 className="#444 text-lg ">{t('admin.survey.import_export.verification_page.processing_popup_text')}</h5>
                <div className="mt-5">
                    {isLoading && <BeatLoader color="#086e7c"/>}
                   {result?.status === 'success' && <IndexAlert type="success" text={result?.data?.[0]}/>}
                   {result?.status === 'error' && <div><IndexAlert type="danger" text={result?.data?.[0]}/> </div>}
                </div>

            </div>
          <div className="actions">
          <button className="p-1 px-2 text-md bg-gray-300 rounded-sm" onClick={() => closeTooltip()} style={{ cursor: 'pointer' }}>{t('close')}</button>
          </div>
        </div>
      )}
    </Popup>
    </div>
  )
}

ConfirmImportPop.propTypes = {
  campaign: PropTypes.object,
  children: PropTypes.node
}

export default ConfirmImportPop
