import * as React from 'react'
import Rating from '@mui/material/Rating'
import Box from '@mui/material/Box'
import StarIcon from '@mui/icons-material/Star'
import BaseQuestion from '../baseQuestion'

const labels = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+'
}

function getLabelText (value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`
}

export default function RatingStars (props) {
  const [value, setValue] = React.useState(2)
  const [hover, setHover] = React.useState(-1)

  return (
    <BaseQuestion>

    <h5 className="text-xl">{props.question.text}</h5>
    <div className="flex">
        <Rating
        name="hover-feedback"
        value={value}
        precision={0.5}
        getLabelText={getLabelText}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover)
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {value !== null && (
        <Box sx={{ ml: 2, pt: 0.3 }}>{labels[hover !== -1 ? hover : value]}</Box>
      )}
      </div>
    </BaseQuestion>
  )
}
