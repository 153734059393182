import React from 'react'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, entityOptions } from '../DynamicNavbarOptions'
import ListEntityCampaigns from './tables/listEntityCampaigns'
import CampaignDetail from './campaignDetail'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetCampaignsQuery } from '../../../features/campaign/campaignsSlice'
import EntityDetails from './components/entityDetails'
import { useGetEntitiesAdminQuery } from '../../../features/entities/entitiesSlice'
import { useGetEvaluationsQuery } from '../../../features/evaluation/evaluationSlice'
import EvaluationList from './components/evaluationList'
import EvaluationDetail from './evaluationDetail'
import { useTranslation } from 'react-i18next'

function DetailsEntity () {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data: evaluations } = useGetEvaluationsQuery(id)

  const { data: entities } = useGetEntitiesAdminQuery(id)
  const [entity, setEntity] = React.useState()
  React.useEffect(() => {
    if (entities) {
      setEntity(entities.entities[id])
    }
  }, [entities, id])

  const { data: campaignsData } = useGetCampaignsQuery(id)
  const [typeSelected, setTypeSelected] = React.useState()
  const navigate = useNavigate()

  if (entity) {
    return (
      <section className="w-full pb-16">
        <DynamicNavBar option="navbar.generalOptions.organisations" options={generalOptions} />
        <DynamicNavBar option="" options={entityOptions} />
        <div className="w-full grid md:grid-cols-5 grid-cols-1 p-2 mt-5">
          <div className="flex flex-col border-right-1 border-[#dbdbdb] col-span-2 ">
            <div className="mt-2">
              <h5 className="block text-xl font-medium text-[#444] mt-2 pl-4">{t('admin.organisation.details.OrgCampaigns')}</h5>
              <ListEntityCampaigns onChangeDetails={setTypeSelected} campaignsData={campaignsData?.entities ? Object.values(campaignsData.entities) : []} />
              <h5 className="block text-xl font-medium text-[#444] mt-2 pl-4">{t('admin.organisation.details.OrgEvaluations')}</h5>
              <EvaluationList onChangeDetails={setTypeSelected} evaluations={evaluations || []} />
            </div>
          </div>
          <div className="col-span-3 mx-4 max-w-5xl">
            <div className=" flex gap-2 float-right mr-8">
              <bottom className="px-2 py-1 rounded-sm bg-[#086e7c] text-white" style={{ cursor: 'pointer' }} onClick={() => navigate(`/admin/entity/edit/${entity.id}`)}>{t('admin.organisation.details.modify_organisation')}</bottom>
            </div>
            <div className="h-4"></div>
            <EntityDetails entity={entity} />
            <hr />
            <div className="mt-2">
              {typeSelected?.type === 'campaign' && <CampaignDetail data={typeSelected || {}} />}
              {typeSelected?.type === 'evaluation' && <EvaluationDetail evaluation={typeSelected || {}} />}
              {(typeSelected?.type !== 'evaluation' && typeSelected?.type !== 'campaign') && <h5 className="block text-xl font-medium text-[#444] mt-2 w-min-fit mx-auto">{t('admin.organisation.details.selectCamEval')}</h5>}
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return undefined
  }
}
export default DetailsEntity
