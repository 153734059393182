import React from 'react'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions } from '../DynamicNavbarOptions'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CreateOrgChart from './charts/createOrgChart'
import { useGetSurveyNodesQuery } from '../../../features/surveyNodesSlice/surveyNodesSlice'
import CreateSurveyNodeComponent from './charts/createSurveyNodeComponent'
import ModifySurveyNodeComponent from './charts/modifySurveyNodeComponent'
import DeleteSurveyNodeComponent from './charts/deleteSurveyNodeComponent'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'

function CreateSurvey () {
  const { t } = useTranslation()
  const { id } = useParams()
  const [data, setData] = React.useState()
  const [chosenNode, setChosedNode] = React.useState({ node: '', action: '' })
  const { data: surveyNodes, isSuccess, isLoading, isError, error } = useGetSurveyNodesQuery(id)
  const [nodeSurvey, setNodeSurvey] = React.useState({
    title: '',
    specification: '',
    question: [],
    categories: [],
    parent: '',
    node_type: 'category',
    survey: id
  })

  const handlemodify = () => {
    setChosedNode({ ...chosenNode, action: 'modif' })
    setNodeSurvey(surveyNodes.find((node) => node.id === chosenNode.node))
  }
  const handlecreate = () => {
    setChosedNode({ ...chosenNode, action: 'create' })
    setNodeSurvey({
      title: '',
      question: [],
      categories: [],
      parent: '',
      node_type: 'category',
      survey: id
    })
  }
  React.useEffect(() => {
    if (surveyNodes) {
      const nodes = JSON.parse(JSON.stringify(surveyNodes))
      setData(nodes)
    }
  }, [surveyNodes])

  return (
  <div className="w-full">
    <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
    <DynamicNavBar option="navbar.surveyOptions.create_survey" options={surveyOptions}/>
    <div className=" mt-2">
    <h5 className="text-center text-3xl font-medium text-[#444444] w-max-fit mb-3">{t('admin.survey.surveys.create.create_survey')}</h5>
    <div className="grid grid-cols-12 items-center gap-2 mt-1 w-full">
        <div className={chosenNode.action ? 'col-span-7 p-1 w-full h-full bg-[#fff]' : 'col-span-12 p-1 w-full h-full bg-[#fff]'}>
        {chosenNode.node &&
        <div className="flex flex-col gap-1 float-right max-w-fit z-100 ">
              <div className="w-full flex gap-1 " style={{ cursor: 'pointer' }} >
                <AddIcon className='bg-green-300 rounded-full ' style={{ fill: '#ffffff', fontSize: 35 }} onClick={() => handlecreate()}/>
                <AutoFixHighIcon className='bg-[#f7b26d] rounded-full' style={{ fill: '#ffffff', fontSize: 35 }} onClick={() => handlemodify()}/>
              </div>
              <div className="w-full" style={{ cursor: 'pointer' }}>
                <CloseIcon className='rounded-full bg-red-300  p-1 float-right' style={{ fill: '#ffffff', fontSize: 35 }} onClick={() => setChosedNode({ ...chosenNode, action: 'delete' })}/>
              </div>
        </div>}
              {isSuccess && <CreateOrgChart chosenNode={chosenNode} setChosedNode={setChosedNode} dataOrg={data || []}/>}

        </div>

        {chosenNode.node &&
        <>
          {chosenNode.action === 'create' &&
            <div className="col-span-5 p-1 w-full h-full bg-[#fff] ">
              <CreateSurveyNodeComponent surveyNode={chosenNode} nodeSurvey={nodeSurvey} setNodeSurvey={setNodeSurvey} setChosedNode={setChosedNode}/>
             </div>}
          {chosenNode.action === 'modif' &&
            <div className="col-span-5 p-1 w-full h-full bg-[#fff] ">
              <ModifySurveyNodeComponent surveyNode={chosenNode} nodeSurvey={nodeSurvey} setNodeSurvey={setNodeSurvey} setChosedNode={setChosedNode}/>
            </div>}
          {chosenNode.action === 'delete' &&
            <div className="col-span-5 p-1 w-full h-full bg-[#fff] ">
              <DeleteSurveyNodeComponent surveyNode={chosenNode} nodeSurvey={nodeSurvey} setNodeSurvey={setNodeSurvey} setChosedNode={setChosedNode}/>
            </div>}
        </>
          }
        </div>
      </div>
  </div>
  )
}

export default CreateSurvey
