import React from 'react'
import { useTranslation } from 'react-i18next'

const RadioButton = ({ dataList, value, onChangeRadio }) => {
  const { t } = useTranslation()
  return (
    <div className='flex max-w-fit justify-between gap-5'>
      {dataList.map((item) =>
        <div className="flex items-center" key={item}>
          <input checked = {value ? value === item : false} onChange={(e) => onChangeRadio(e.target.value)} id="default-radio-2" type="radio"
          style={{ cursor: 'pointer' }}
          value={item} name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
          <label htmlFor="default-radio-2" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{item}</label>
        </div>
      )}

    </div>
  )
}

export default RadioButton
