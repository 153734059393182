import React from 'react'
import EntitiesList from '../../../features/entities/entitiesList'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, entityOptions } from '../DynamicNavbarOptions'
import { EntitySectorPieChart } from './entityDetailsCharts/entitySectorPieChart' 
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function formatEntitiesDataPieChart (entityList) {
  let data = {}
  entityList.map((entity) => {
    const keyData = entity.activity_sector
    data = data[keyData] ? { ...data, [keyData]: data[keyData] + 1 } : { ...data, [keyData]: 1 }
  }
  )
  return data
}

function EntitiesTracking (props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [pieData, setPieData] = React.useState(undefined)
  React.useEffect(() => {
    if (props.isSuccess && props.entities.entities) {
      const data = formatEntitiesDataPieChart(Object.values(props.entities.entities))
      setPieData({ labels: Object.keys(data), data: Object.values(data) })
    }
  }, [props.isSuccess, props.entities])

  return (
    <div className="w-full pb-16">
      <DynamicNavBar option="navbar.generalOptions.organisations" options={generalOptions} />
      <DynamicNavBar option="navbar.entityOptions.organisation_list" options={entityOptions} />
      <div className="w-full flex flex-col items-center gap-8  static mt-4" >
        <div className=" md:w-2/3 lg:w-2/3 sm:w-2/3 bg-wite md:mx-8 pb-8">
          <div className="mt-1 shadow-sm rounded-md w-full mx-2  bg-white px-4 py-4 flex flex-col gap-4">
          {props.entities?.length > 0 &&
            <div className="grid grid-cols-3 max-h-fit">
              <div className="col-span-1"><EntitySectorPieChart data={pieData || undefined} /></div>
            </div>
            }
            <div>
              <div className="my-5 flex justify-between w-full">
                <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('admin.organisation.list.organisations_list')} </h5> 
                <button className="bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium"
                  style={{ cursor: 'pointer' }} onClick={() => navigate('/admin/entity/create')}> {t('admin.organisation.list.create_organisation')}</button>
              </div>
              <EntitiesList entityList={props?.entities?.entities ? Object.values(props.entities.entities) : []} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

EntitiesTracking.propTypes = {
  isSuccess: PropTypes.bool,
  entities: PropTypes.object
}

export default EntitiesTracking
