export function getFormat (tree) {
  const final_tree = format_children(tree)
  return final_tree
}

function format_children (children, parent) {
  let child = { children: [] }
  if (children?.properties) {
    child = {
      ...child,
      key: children.id,
      properties: children.properties,
      name: children.properties.label,
      title: children.properties.label,
      category: parent,
      rawValue: children.properties.score?.numerator && children.properties.score.numerator,
      value: (children.properties.score?.numerator
        ? children.properties.score.numerator
        : 0 / children.properties.score.denominator) * 100
    }
    if (children.children.length !== 0) {
      const parentId = children?.id ? children.id : ''
      children.children.map((children) => {
        child = { ...child, children: [...child.children, format_children(children, parentId)] }
      })
    }
    if (child.children[0] === undefined) {
      delete child.children
    }
    return child
  }
}

export function getFormatAccordionData (tree) {
  const finalTree = formatAccordionData(tree)
  return finalTree
}
function formatAccordionData (children, parent) {
  let child = { children: [] }
  if (children?.properties) {
    child = {
      ...child,
      key: children?.id ? children.id : children?.uuid && children.uuid,
      properties: children.properties,
      type: children.type,
      reference: children?.reference ? children.reference : undefined,
      text: children?.text ? children.text : undefined,
      name: children.properties.label,
      category: parent,
      rawValue: children.properties.score?.numerator && children.properties.score.numerator
    }
    if (children?.children?.length !== 0) {
      const parentId = children?.id ? children.id : ''
      if (children.children) {
        children.children.map((children) => {
          child = { ...child, children: [...child.children, formatAccordionData(children, parentId)] }
        })
      }
    } else {
      child = { ...child, children: [...child.children] }
    }
    if (child.children[0] === undefined) {
      delete child.children
    }
    return child
  }
}
