import React from 'react'
import { useParams } from 'react-router-dom'
import { useCreateCommentMutation, useUpdateCommentMutation } from '../../features/SurveyComments/surveyCommentsSlice'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const QuestionComment = (props) => {
  const { idCampaign, idUser } = useParams()
  const [comment, setComment] = React.useState({ comment_text: '', user_type: 'org_user', question: props.question, private: false, user: idUser, campaign: idCampaign })
  const [createComment] = useCreateCommentMutation()
  const [updateComment] = useUpdateCommentMutation()
  const { t } = useTranslation()

  React.useEffect(() => {
    if (props.comment) {
      setComment(props.comment)
    }
  }, [props.comment])

  const formatCommentData = () => {
    let data = { ...comment }
    if (props.commentUser !== idUser) {
      setComment({ ...comment, user: props.commentUser, user_type: 'consultant' })
      data = { ...data, origin_question: props.origin_question, user: props.commentUser, user_type: 'consultant' }
    } else {
      setComment({ ...comment, user: props.commentUser, user_type: 'org_user' })
      data = { ...data, origin_question: props.origin_question, user: props.commentUser, user_type: 'org_user' }
    }
    return data
  }

  const handleSaveComment = async (e) => {
    e.preventDefault()
    const data = formatCommentData()
    if (comment?.id) {
      await updateComment(data)
    } else {
      await createComment(data)
    }
  }
  return (
<div className="flex mx-auto items-center justify-center w-full">
   <form className="w-full bg-white rounded-lg pt-2" onSubmit={(e) => handleSaveComment(e)}>
      <div className="flex flex-wrap pb-2">
         <div className="w-full md:w-full px-3 mb-2">
            <textarea value ={comment?.comment_text ? comment.comment_text : ''} onChange={(e) => setComment({ ...comment, comment_text: e.target.value })}
            className="bg-gray-100 rounded-md border border-gray-200 leading-normal resize-none w-full  py-2 px-3
            font-medium placeholder-gray-700 focus:outline-none focus:bg-white" rows={3} name="body" placeholder={t('survey_comment.write_comment')} required></textarea>
         </div>
         <div className="w-full md:w-full flex flex-col items-end md:w-full px-3 ">
            <div className='flex items-center gap-4'>
                <div className='flex items-center ' >
                    <input type='checkbox' checked ={comment.private} onChange = {(e) => setComment({ ...comment, private: e.target.checked })} id="comment_state" style={{ cursor: 'pointer' }} className="bg-white text-gray-700 font-medium py-1  border border-gray-400 rounded-lg tracking-wide mr-1 hover:bg-gray-100" />
                    <label htmlFor="comment_state" className="text-gray-900" style={{ cursor: 'pointer' }}>{t('survey_comment.private')}</label>
                </div>
               {comment?.id
                 ? <input type='submit' style={{ cursor: 'pointer' }} className="bg-white text-gray-700 font-medium py-1 px-3 border border-gray-400 rounded-lg tracking-wide mr-1 hover:bg-gray-100" value='Modifier'/>
                 : <input type='submit' style={{ cursor: 'pointer' }} className="bg-white text-gray-700 font-medium py-1 px-3 border border-gray-400 rounded-lg tracking-wide mr-1 hover:bg-gray-100" value='Publier'/>
                  }
            </div>
         </div>
        </div>
      </form>
   </div>
  )
}

QuestionComment.propTypes = {
  comment: PropTypes.object,
  commentUser: PropTypes.string
}

export default QuestionComment
