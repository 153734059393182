
export const formatEntityInfos = (entityInfos) => {
  const entityInfosFormated = {}
  const etablissement = entityInfos.data.etablissement
  entityInfosFormated.siren = etablissement.siren
  entityInfosFormated.siret = etablissement.siret
  entityInfosFormated.nic = etablissement.nic
  const adress = etablissement.adresseEtablissement.codePostalEtablissement + ' ' + etablissement.adresseEtablissement.libelleCommuneEtablissement
  entityInfosFormated.adress = adress
  entityInfosFormated.entityName = etablissement.uniteLegale.denominationUniteLegale
  entityInfosFormated.activitysector = etablissement.uniteLegale.activitePrincipaleUniteLegale
  entityInfosFormated.nafCode = etablissement.uniteLegale.nomenclatureActivitePrincipaleUniteLegale
  return entityInfosFormated
}
/*
Raison sociale
Siren
# noms dirigeants (DG, DAF...)
adresse
# code NAF (ou équivalent)
# chiffre d'affaires (prévoir sur plusieurs années)
# profitabilité/EBITDA
# conteneur pour statuts
# les éventuels labels (ecovadis, Bcorp,..)
# frais promotion de la marque produit
# frais marque employeur
# part des salariész en situaton de handicap
# nombre salariés ETP
# part du capital détenue par les salariés
# accords de participation
# répartition H/F
# égalité des salaires H/F
# écart de rémunération
# proportion salariés handicapés...
# participation des salariés aux décisions stratégiques (CA, codir, ...)
# nombre accidents du travail
# informations absentéisme
# niveaux de compétences de salariés
# volume et coût des formations
# part des fournisseurs locaux
# montant investissements sociétaux
# montant du buget compensation carbone
# part du CA en mécenat
# ROE
# Risque Beta
# budget philantropie

*/
