import React from 'react'
import ApartmentIcon from '@mui/icons-material/Apartment'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes'
import { useNavigate, useParams } from 'react-router-dom'
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function NavBarHub ({ option }) {
  const { t } = useTranslation()
  const { idEntity } = useParams()
  const navigate = useNavigate()
  return (
    <div className="h-11 w-full " style={{ borderBottom: '2px solid #EBEBEB' }}>
      <nav className="grid place-items-center items-center  h-full items-bottom ">
        <ul className="flex w-max-fit gap-5 justify-between py-0 mt-3 mx-auto">
          <li
            className={
              option === 'entity'
                ? 'text-[#444444] text-lg font-medium font-nano flex gap-1 px-2 border-b-2 border-[#c4a130]'
                : 'text-[#444444] text-lg font-medium font-nano flex gap-1 px-2'
            }
            onClick={() => navigate('/hub/entity/list')}
            style={{ cursor: 'pointer' }}
          >
            <ApartmentIcon />
            <h5>{t('navbar.hub.organisations')}</h5>
          </li>
           <li
            className={
              option === 'campaign'
                ? 'text-[#444444] text-lg font-medium font-nano flex gap-1 px-2 border-b-2 border-[#c4a130]'
                : 'text-[#444444] text-lg font-medium font-nano flex gap-1 px-2'
            }
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(idEntity ? `/hub/entity/${idEntity}/campaigns` : '/hub/entity/list')}
          >
            <SpeakerNotesIcon className="pt-1" /> <h5>{t('navbar.hub.campaigns')}</h5>
          </li>
          <li
            className={
              option === 'materiality_matrix'
                ? 'text-[#444444] text-lg font-medium font-nano flex gap-1 px-2 border-b-2 border-[#c4a130]'
                : 'text-[#444444] text-lg font-medium font-nano flex gap-1 px-2'
            }
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(idEntity ? `/hub/entity/${idEntity}/materiality_matrix` : '/hub/entity/list')}
          >
            <BackupTableOutlinedIcon className="pt-1" /> <h5>{t('navbar.hub.materiality_matrix')}</h5>
          </li>
          <li
            className={
              option === 'evaluation'
                ? 'text-[#444444] text-lg font-medium font-nano flex gap-1 px-2 border-b-2 border-[#c4a130]'
                : 'text-[#444444] text-lg font-medium font-nano flex gap-1 px-2'
            }
            onClick={() => navigate(`/hub/entity/${idEntity}/evaluation`)}
            style={{ cursor: 'pointer' }}
          >
            <QueryStatsIcon />
            <h5>{t('navbar.hub.evaluations')}</h5>
          </li>

        </ul>
      </nav>
    </div>
  )
}
NavBarHub.propTypes = {
  option: PropTypes.string.isRequired
}
export default NavBarHub
