
import React from 'react'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { useNavigate, useParams } from 'react-router-dom'
import { selectCurrentUser } from '../../../../features/auth/authSlice'
import { useSelector } from 'react-redux'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { useTranslation } from 'react-i18next'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'
import { FilterMatchMode } from 'primereact/api'
import ResponseStatisticsNoLabels from '../../../../appComponents/questions/bar/responseStatisticsNoLabels'
import { useGetUserCampaignResponseQuery } from '../../../../features/surveyCampaign/userCampaignResponseSlice'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'

const CampaignDetailUserList = ({ members, setUserStats }) => {
  const { t } = useTranslation()
  const user = useSelector(selectCurrentUser)
  const { idEntity, campaignId } = useParams()
  const navigate = useNavigate()
  const [data, setData] = React.useState(null)
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const showProgerssBar = (e) => {
    const { data: userCampaignResponse } = useGetUserCampaignResponseQuery({ idCampaign: campaignId, idUser: e.id || undefined })
    return <div className='flex w-full justify-center'><ResponseStatisticsNoLabels responsesStatistics={userCampaignResponse?.statistics || {}}/></div>
  }
  const showSubmmittedState = (e) => {
    const { data } = useGetUserCampaignResponseQuery({ idCampaign: campaignId, idUser: e.id || undefined })
    return <h5 className="flex w-full justify-center text-sm font-medium text-[#444444] ">{data?.submmitted_state ? <VerifiedUserIcon style={{ fill: '#12B64E' }}/> : <UnpublishedIcon style={{ fill: '#E44526' }} /> }</h5>
  }

  React.useEffect(() => {
    if (members?.length > 0) {
      setData(members)
    }
  }, [members])
  const actionTemplate = (e) => {
    return (
        <div className="flex gap-2 justify-center ">
          <RemoveRedEyeIcon sx={{ color: '#f5b92f', cursor: 'pointer' }} onClick={() => window.open(`/hub/entity/${idEntity}/campaigns/details/${campaignId}/${e.id}`, '_blank')}/>
          {e.email === user.email &&
          <AutoFixHighIcon sx={{ color: 'green', cursor: 'pointer' }} onClick={() => window.open(`/survey/${campaignId}/${e.id}`, '_blank')}/>}
          <AssessmentIcon sx={{ color: '#1199C4', cursor: 'pointer' }} onClick={() => setUserStats({ id: e.id, email: e.email })}/>

        </div>
    )
  }
  const tableActions = { setData: (e) => setSelectedData(e.value) }

  const tableConfig = {
    value: data,
    stateKey: 'dt-state-compaign-deltails-list',
    onFilter: (e) => setFilters(e.filters),
    scrollable: true,
    scrollHeight: '500px',
    header,
    filters,
    onSelectionChange: tableActions.setData,
    dataKey: 'id',
    emptyMessage: t('admin.organisation.list.member_list_noFound'),
    globalFilterFields: ['email', 'post', 'first_name', 'last_name'],
    columns: [
      { field: 'last_name', header: t('common.table.last_name'), sortable: true, style: { width: '7%' } },
      { field: 'first_name', header: t('common.table.first_name'), sortable: true, style: { width: '8%' } },
      { field: 'post', header: t('common.table.poste'), sortable: true, style: { minWidth: '15%' } },
      { field: 'email', header: t('common.table.adresse_email'), sortable: true, style: { minWidth: '20%' } },
      { header: t('survey.states.reponse_level'), body: showProgerssBar, sortable: true, style: { minWidth: '25%' } },
      { header: t('common.table.status'), sortable: true, body: showSubmmittedState, style: { minWidth: '3%' } },
      { body: actionTemplate, header: t('common.table.action'), style: { minWidth: '15%' } }
    ]
  }
  const DataTableComponent = createDataTable(tableConfig)

  return (
  <div className="card mx-auto w-full border-1 border-gray-300 ">
    {DataTableComponent}
  </div>
  )
}

export default CampaignDetailUserList
