import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const EvaluationMethodAdapter = createEntityAdapter({
  selectId: (entity) => entity.id
})
const initialState = EvaluationMethodAdapter.getInitialState({
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const evaluationMethodApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'evaluationMethodSlice',
  tagTypes: ['EvaluationMethod'],
  endpoints: builder => ({
    getEvaluationMethods: builder.query({
      query: () => '/evaluationMethod/evaluation_methods/',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['EvaluationMethod']
    }),
    createEvaluationMethod: builder.mutation({
      query: (data) => ({
        url: '/evaluationMethod/evaluation_methods/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['EvaluationMethod']
    }),
    getEvaluationMethod: builder.query({
      query: (id) => `/evaluationMethod/evaluation_methods/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['EvaluationMethod']
    }),
    deleteEvaluationMethod: builder.mutation({
      query: (id) => ({
        url: `/evaluationMethod/evaluation_methods/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['EvaluationMethod']
    }),
    modifyEvaluationMethod: builder.mutation({
      query: (data) => ({
        url: `/evaluationMethod/evaluation_methods/${data.id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['EvaluationMethod']
    })
  })
})

export const {
  useGetEvaluationMethodsQuery,
  useCreateEvaluationMethodMutation,
  useGetEvaluationMethodQuery,
  useDeleteEvaluationMethodMutation,
  useModifyEvaluationMethodMutation
} = evaluationMethodApiSlice

export const evaluationMethodResult = evaluationMethodApiSlice.endpoints.getEvaluationMethods.select()

export const EvaluationMethodsData = createSelector(
  evaluationMethodResult,
  entityData => entityData?.data ?? {}
)

export const {
  selectAll: selectEvaluationMethods,
  selectById: selectEvaluationMethod
  // Pass in a selector that returns the posts slice of state
} = EvaluationMethodAdapter.getSelectors(state => EvaluationMethodsData(state) ?? initialState)
