import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentToken, selectCurrentUser } from '../../features/auth/authSlice'

function RedirectAuth () {
  const client = ['CLIENT']
  const admins = ['ADMIN', 'SUPERADMIN', 'CONSULTANT']
  const token = useSelector(selectCurrentToken)
  const user = useSelector(selectCurrentUser)
  const location = useLocation()
  if (!token) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />
  } else {
    return (
      client.includes(user?.type)
        ? <Navigate to="/hub/entity/list" state={{ from: location }} replace />
        : admins.includes(user?.type)
          ? <Navigate to="/admin/entity" state={{ from: location }} replace />
          : undefined
    )
  }
}

export default RedirectAuth
