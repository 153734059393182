import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useGetEntityInfoSirenMutation } from '../entitiesSlice'
import EntityFields from './entityFields'
import { formatEntityInfos } from '../utils/formatEntityInfos'
import { useGetActivitiesQuery } from '../../../features/activitiesSector/activitiesSlice'
import CheckBox from '../../../layouts/admin/survey/utils/checkBox'

function EntityInfo ({ onChangeEntity, entity, onChangeStep }) {
  const { t } = useTranslation()

  const [codeSiren, setCodeSiren] = React.useState()
  const [addSiretCode, setAddSiretCode] = React.useState(true)
  const [getEntityInfoSiren, { isLoading, isSuccess, isError, error }] = useGetEntityInfoSirenMutation()

  const { data: activivitySectors } = useGetActivitiesQuery()
  const handleSearchSiren = async () => {
    if (codeSiren && addSiretCode) {
      const entityInfo = await getEntityInfoSiren(codeSiren)
      const entityInfoFormated = formatEntityInfos(entityInfo)
      const activivitySector = Object.values(activivitySectors.entities).find((op) => op.reference === entityInfoFormated.activitysector)
      onChangeEntity({ ...entity, entityInfo: entityInfoFormated, name: entityInfoFormated?.entityName || '', activity_sector: activivitySector?.id || '' })
    } else {
      onChangeEntity({ ...entity, entityInfo: {}, name: '', activity_sector: '' })
    }
  }
  return (
    <section>
      <div className="flex flex-col  flex-wrap gap-2 mt-4">
      <CheckBox text="admin.organisation.create.add_siren_search" value={addSiretCode || false} onChange={(val) => setAddSiretCode(val)} disabled={false}/>
        {addSiretCode &&
          <>
            <div className="md:w-1/2 w-4/5 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">

              <label
                htmlFor="price"
                className="block text-lg font-medium text-gray-800">
                {t('admin.organisation.create.fields.organisation_siret')}
              </label>
              <input
                type="text"
                name="price"
                id="price"
                className="focus:ring-teal-500
        focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444]
        rounded-sm"
                style={{ border: '1px solid #e8e6e6' }}
                value={codeSiren || ''}
                onChange={(e) => setCodeSiren(e.target.value)}
              />
            </div>

            <div className="w-full">
              <button className="bg-teal-600 mx-2 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-left"
                onClick={() => handleSearchSiren()}>
                <h5 className="text-white  w-fit">
                  {t('admin.organisation.create.fields.recuperate_info')}
                </h5>
              </button>
            </div></>
        }
         <EntityFields entityInfos={entity?.entityInfo} addSiretCode={addSiretCode} onChangeEntity={(data) => onChangeEntity({ entity, entityInfo: data })} />
        <div className="w-3/5">
          <button className="bg-teal-600 mx-2 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1">
            <h5 className="text-white w-16" onClick={() => onChangeStep(2)}>
              {t('common.buttons.next')}
            </h5>
          </button>
        </div>
      </div>
    </section>
  )
}
EntityInfo.propTypes = {
  onChangeEntity: PropTypes.func.isRequired,
  entity: PropTypes.object,
  onChangeStep: PropTypes.func.isRequired
}

export default EntityInfo

/*

*/
