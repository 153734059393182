import React, { useState } from 'react'
import DynamicNavBar from '../../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions, transformModel } from '../../DynamicNavbarOptions'
import { useTranslation } from 'react-i18next'
import IndexAlert from '../../../../components/alerts/indexAlert'
import Input from '../utils/input'
import ClipLoader from 'react-spinners/ClipLoader'
import TextArea from '../utils/textArea'
import { useGetSurveysQuery } from '../../../../features/surveysCreated/surveysSlice'
import { useNavigate } from 'react-router-dom'
import AddParams from '../utils/addParams'
import GlobalParams from '../utils/globalParams'
import SelectInput from '../utils/selectInput'
import { v4 as uuidv4 } from 'uuid'
import { useCreateTransformMethodMutation } from '../../../../features/transformQuestion/transformMethodSlice'

function StartCreateTransformationModel () {
  const { data: surveys } = useGetSurveysQuery()
  const formulaUuidCode = uuidv4()
  const [trsfModel, setTRSFModel] = useState({
    title: '',
    description: '',
    survey: '',
    addParams: false,
    global_parameters: {
      denominator: 20,
      global_formulas:
      {
        [formulaUuidCode]: { text: 'formula : f(x)= x', value: 'f(x)= x', type: 'global', id: formulaUuidCode }
      }
    },
    disabled: false
  })

  const [createTransformMethod, { isSuccess, isLoading, isError, error }] = useCreateTransformMethodMutation()

  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleCreateTransformMethod = async () => {
    const transformMethod = await createTransformMethod(trsfModel)
    setTRSFModel({ ...transformMethod.data, disabled: false })
  }

  return (
    <div className="w-full">
      <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions} />
      <DynamicNavBar option="navbar.surveyOptions.tranformation_model" options={surveyOptions} />
      <DynamicNavBar option="navbar.transformModel.create_transform_model" options={transformModel} />
      <div className="mx-auto mt-2 w-1/3" >
        <h5 className="text-center text-2xl font-medium text-[#444444] mb-3 w-full ">{t('admin.survey.transformation_model.create.fields.model_created')}</h5>
        <div className="flex flex-col gap-1 w-full bg-gray-50 max-h-fit">
          <div className='grid justify-items-center'>
            <div className='max-w-fit '>
              {isSuccess && <IndexAlert type="success" text={t('admin.survey.transformation_model.create.fields.method_created')} />}
              {isError && <IndexAlert type="danger" text={JSON.stringify(error.data.error)} />}
            </div>
          </div>
          <div className="w-2/3 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
            <Input text='admin.survey.transformation_model.create.fields.titre' placeholder='admin.survey.transformation_model.create.fields.titre' disabled={trsfModel?.disabled}
              onChange={(val) => setTRSFModel({ ...trsfModel, title: val })} value={trsfModel?.title ? trsfModel.title : ''} />
          </div>
          <div className='w-2/3 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm '>
            <SelectInput text='admin.survey.transformation_model.create.fields.choose_survey' disabled={trsfModel?.disabled} value={trsfModel?.survey} data={surveys || []} onChange={(val) => setTRSFModel({ ...trsfModel, survey: val })} />
          </div>
          <div className='w-full px- px-3 mb-6 md:mb-0 relative rounded-sm'>
            <AddParams value={trsfModel?.addParams} disabled={trsfModel?.disabled} onChange={(val) => setTRSFModel({ ...trsfModel, addParams: val })} />
            {trsfModel?.addParams &&
              <div className="w-full px- px-3 mb-6 md:mb-0 relative rounded-sm">
                <GlobalParams params={trsfModel?.global_parameters} onChange={(val) => setTRSFModel({ ...trsfModel, global_parameters: val })}
                onBlur={(val) => setTRSFModel({ ...trsfModel, global_parameters: val })} />
              </div>
            }
          </div>
          <div className="w-full px- px-3 mb-6 md:mb-0 relative rounded-sm">
            <TextArea text='admin.survey.transformation_model.create.fields.description' disabled={trsfModel?.disabled} placeholder='admin.survey.transformation_model.create.fields.description'
              onChange={(val) => setTRSFModel({ ...trsfModel, description: val })} rows={6}
              value={trsfModel?.description ? trsfModel.description : ''} />
          </div>

        </div>
        {
        }
        {isSuccess
          ? trsfModel?.id &&
          <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
            px-2 py-0.5 mt-4 float-right w-20 h-10" style={{ cursor: 'pointer' }} onClick={() => navigate(`/admin/survey/transformation_model/create/${trsfModel.id}`)}>{t('admin.survey.buttons.next')}
          </div>
          : isLoading
            ? <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
           py-0.5 mt-4 float-right w-20 h-10" style={{ cursor: 'pointer' }} >
              <ClipLoader color="#F2F2F2" size={22} /></div>
            : <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
            px-2 py-0.5 mt-4 float-right w-20 h-10" style={{ cursor: 'pointer' }} onClick={() => handleCreateTransformMethod()}>{t('admin.survey.buttons.create')}
            </div>}
      </div>

    </div>
  )
}

export default StartCreateTransformationModel






